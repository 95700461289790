@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
:root {
  --header-height: 70px;
  --nav-width: 68px;
  --first-color: #222;
  --first-color-light: #d1d1d1;
  --white-color: #fff;
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100;
}
*,
::before,
::after {
  box-sizing: border-box;
}

body {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0 1rem;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
}
a {
  text-decoration: none;
}
.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: var(--white-color);
  z-index: var(--z-fixed);
  transition: 0.5s;
}
.footer {
  height: 50px;
  clear: both;
  margin-top: 30px;
  font-size: 13px;
  border-top: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  width: 110%;
  margin-left:-32px;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: var(--white-color);
  z-index: var(--z-fixed);
  transition: 0.5s;
}

.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer;
}
.header_img {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  border:2px solid #ddd;
}
.header_img img {
  width: 40px;
}
.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--first-color);
  padding: 0.5rem 1rem 0 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
}
.nav:not(.nav-pills, .tab-content.tab-pane, .paymentsetting.nav-tabs) {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  scrollbar-width: none;
}
.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}
.nav_logo {
  margin-bottom: 2rem;
}
.nav_logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
}
.nav_logo-name {
  color: var(--white-color);
  font-weight: 700;
}
.nav_link {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 1.5rem;
  transition: 0.3s;
}
.nav_link:hover {
  color: var(--white-color);
}
.nav_icon {
  font-size: 1.25rem;
}

.show {
  left: 0;
}

.active {
  color: var(--white-color);
}

.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color);
}
.height-100 {
  height: 100vh;
}
@media screen and (min-width: 768px) {
  body {
    margin: calc(var(--header-height) + 2rem) 0 0 0;
    padding-left: calc(var(--nav-width) + 2rem);
  }
  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
  }
  .header_img {
    width: 40px;
    height: 40px;
  }
  .header_img img {
    width: 45px;
  }
  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
  }
}
.show:not(
    .accordion-collapse,
    .tab-pane,
    .modal,
    .modal-backdrop,
    .dropdown-toggle
  ) {
  width: calc(var(--nav-width) + 156px);
}
.mainBody {
  padding-left: calc(var(--nav-width) + 188px);
}

.mainBody .footer {
  font-size: 0.8rem !important;
  left: calc(var(--nav-width) + 156px) !important;
}
@media (min-width: 768px) {
  .footer {
    left: var(--nav-width) !important;
  }
}

@media (max-width: 768px) {
  .mainBody .footer {
    font-size: 0.8rem !important;
    left: var(--nav-width) !important;
  }
}

@media (max-width: 576px) {
  .breadcrumb_main {
    font-size: small;
  }
  .footer {
    font-size: 0.6rem !important;
  }
  .footer div {
    padding: 0.5rem 0.1rem !important;
  }
}

.nav_list .accordion-item {
  border-color: transparent;
  background: transparent !important;
}
.nav_list .accordion-body {
  padding: 0px !important;
}
.paymentSetting_content b {
  color: black !important;
}

.bSqufN .btn {
  white-space: nowrap;
}

.layoutContainer {
  height: calc(100vh - 50px);
}
