.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap");
.css-equal-content,
.css-equal-heights {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.activity .activity-list,
.notifications .notification-list,
.sidebar .sidebar-right ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.css-equal-content {
  color: red;
  float: left;
}

.tableRow tbody tr:nth-of-type(odd) td {
  background-color: #f7f7f7;
}

.tableRow tbody tr:nth-of-type(even) td {
  background-color: #ffffff;
}
.bold {
  font-weight: bold !important;
}
.pass-group .toggle-password,
.pass-group .toggle-passworda,
.profile-set .profile-top .profileupload a,
.productset .productsetimg,
.productset.active .check-product,
.order-list .actionproducts > ul > li .deletebg,
.product-details,
.header-menuset ul li a,
.owl-product .owl-nav button,
.productimg .productcontet h4,
.increment-decrement .input-groups input[type="button"],
.setvaluecash ul li a,
.select-split .btn-filters,
.product-view .product-viewimg,
.productviewset .productviewscontent a,
.image-upload-new .productviewscontent a,
.add-icon span,
.add-icon a,
.slider-product-details .owl-carousel .owl-nav button,
.product-list ul li .productviews .productviewscontent a,
.login-wrapper .login-content .form-sociallink ul li a,
.login-wrapper .login-content .toggle-passwords,
.login-wrapper .login-content .toggle-password,
.dash-widget .dash-widgetimg span,
.image-upload.image-upload-new .image-uploads,
.search-set .btn-filter,
.page-header .btn-filter,
.customizer-links,
.modal .modal-footer .btn-print,
.modal .close,
.modal .btn-close,
.sidebar .nav-link,
div#global-loader,
.searchinputs .search-addon span,
.user-menu.nav > li > a .badge,
.error-page .main-wrapper,
.error-page,
.icons-items .icons-list li,
.icons-list li,
.form-group .btn-filters,
.input-group .scanner-set,
.avatar .avatar-title {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.singIn {
  color: #089fba;
  font-size: 16px;
  margin-top: 6px;
  text-decoration: underline;
  font-weight: bold;
}
.pass-group .toggle-password,
.pass-group .toggle-passworda,
.profile-set .profile-top .profileupload a,
.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top .profile-content,
.profile-set .profile-top,
.productset .productsetimg,
.productset.active .check-product,
.order-list .actionproducts > ul > li .deletebg,
.order-list .actionproducts > ul,
.order-list,
.totalitem,
.btn-scanner-set,
.product-details,
.invoice-load-btn .btn,
.header-menuset ul li a,
.header-menuset ul li,
.header-menuset ul,
.calculator-set ul,
.btn-pos ul,
.actionproduct ul,
.owl-product .owl-nav button,
.tab-set ul,
.productimg .productlinkset,
.productimg .productcontet h4,
.productimg,
.increment-decrement .input-groups input[type="button"],
.increment-decrement .input-groups,
.product-lists,
.setvalue ul li,
.setvaluecash ul li a,
.setvaluecash ul,
.btn-totallabel,
.select-split .btn-filters,
.select-split,
.product-view .product-viewimg,
.productviewset .productviewscontent a,
.image-upload-new .productviewscontent a,
.image-upload-new .productviewscontent,
.card-sales-split,
.total-order ul li,
.add-icon span,
.add-icon a,
.slider-product-details .owl-carousel .owl-nav button,
.input-checkset ul,
.bar-code-view,
.product-list ul li .productviews .productviewscontent a,
.product-list ul li .productviews .productviewscontent,
.product-list ul li .productviews,
.product-list ul,
.login-wrapper .login-content .form-sociallink ul li a,
.login-wrapper .login-content .form-sociallink ul,
.login-wrapper .login-content .toggle-passwords,
.login-wrapper .login-content .toggle-password,
.graph-sets > ul,
.graph-sets,
.dashboardBox,
.dash-widget .dash-widgetimg span,
.dash-widget,
.image-upload.image-upload-new .image-uploads,
.wordset ul,
.search-set .btn-filter,
.search-set,
.table-top,
.page-header .btn-added,
.page-header .btn-filter,
.page-header,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info,
.chat-search .input-group .input-group-prepend,
.switch-wrapper #dark-mode-toggle .light-mode,
.switch-wrapper #dark-mode-toggle .dark-mode,
.switch-wrapper #dark-mode-toggle,
.sidebar-content .sidebar-header,
.modal .modal-footer .btn-print,
.modal .close,
.modal .btn-close,
.modal-barcode ul,
.productimgname,
.sidebar .sub-menu a,
.sidebar .nav-link,
.sidebar .sidebar-menu > ul > li > a,
div#global-loader,
.sidebar.sidebar-four .sidebar-menu ul li a,
.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a,
.searchinputs .search-addon span,
.user-menu.nav > li.flag-nav a,
.user-menu.nav > li > a .badge,
.header-split .header-menu ul li a,
.header-split .header-menu ul,
.header-split,
.header-pos.header .user-menu.nav li,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .menu-drop-user .dropdown-item,
.header .userset,
.header .header-left,
.error-page,
.icons-items .icons-list li,
.icons-list li,
.form-group .btn-filters,
.input-group .scanner-set,
.btn-white,
.avatar .avatar-title {
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top,
.order-list,
.totalitem,
.invoice-load-btn .btn,
.header-menuset ul li,
.btn-pos ul,
.product-lists,
.setvalue ul li,
.btn-totallabel,
.image-upload-new .productviewscontent,
.card-sales-split,
.bar-code-view,
.product-list ul li .productviews .productviewscontent,
.dashboardBox,
.table-top,
.page-header,
.chat-cont-left .chat-scroll .media .media-body,
.sidebar-content .sidebar-header,
.sidebar .sub-menu a,
.header-split,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .header-left,
.btn-white {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.customizer-links ul li a,
.align-center {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.activity .activity-list,
.notifications .notification-list,
.sidebar .sidebar-right ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.switch-wrapper #dark-mode-toggle .light-mode,
.switch-wrapper #dark-mode-toggle .dark-mode,
.switch-wrapper #dark-mode-toggle,
.avatar-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.text-black {
  color: #333;
}

.text-white {
  color: #fff;
}

.text-primary {
  color: #18b0e9 !important;
}

.bg-primary {
  background: #18b0e9 !important;
  background-color: #18b0e9 !important;
}

.bg-red {
  background: #ff0000 !important;
  background-color: #ff0000 !important;
}

.pass-group .toggle-password,
.pass-group .toggle-passworda,
.profile-set .profile-top .profileupload a,
.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top .profile-content,
.profile-set .profile-top,
.productset .productsetimg,
.productset.active .check-product,
.order-list .actionproducts > ul > li .deletebg,
.order-list .actionproducts > ul,
.order-list,
.totalitem,
.btn-scanner-set,
.product-details,
.header-menuset ul li a,
.header-menuset ul li,
.header-menuset ul,
.calculator-set ul,
.btn-pos ul li a.btn,
.btn-pos ul,
.actionproduct ul,
.owl-product .owl-nav button,
.tab-set ul,
.productimg .productlinkset,
.productimg .productcontet h4,
.productimg,
.increment-decrement .input-groups input[type="button"],
.increment-decrement .input-groups,
.product-lists,
.setvalue ul li,
.setvaluecash ul li a,
.setvaluecash ul,
.btn-totallabel,
.select-split .btn-filters,
.select-split,
.product-view .product-viewimg,
.productviewset .productviewscontent a,
.image-upload-new .productviewscontent a,
.image-upload-new .productviewscontent,
.card-sales-split ul,
.card-sales-split,
.total-order ul li,
.add-icon span,
.add-icon a,
.slider-product-details .owl-carousel .owl-nav button,
.productdetails > ul > li,
.input-checkset ul,
.bar-code-view,
.product-list ul li .productviews .productviewscontent a,
.product-list ul li .productviews .productviewscontent,
.product-list ul li .productviews,
.product-list ul,
.login-wrapper .login-content .form-sociallink ul li a,
.login-wrapper .login-content .form-sociallink ul,
.login-wrapper .login-content .toggle-passwords,
.login-wrapper .login-content .toggle-password,
.login-wrapper,
.graph-sets > ul,
.graph-sets,
.dashboardBox,
.dash-widget .dash-widgetimg span,
.dash-widget,
.image-upload.image-upload-new .image-uploads,
.wordset ul,
.search-set .btn-filter,
.search-set,
.table-top,
.page-header .btn-added,
.page-header .btn-filter,
.page-header,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments,
.chat-cont-right ul.list-unstyled .media.sent .media-body,
.chat-cont-left .chat-scroll .media .media-body,
.chat-search .input-group .input-group-prepend,
.sidebar-content .sidebar-header,
.customizer-links,
.modal .modal-footer .btn-print,
.modal .close,
.modal .btn-close,
.modal-barcode ul,
.productimgname,
.sidebar .nav-items .nav-link,
.sidebar .sub-menu a,
.sidebar .nav-link,
.sidebar .sidebar-menu > ul > li > a,
.sidebar,
div#global-loader,
.sidebar.sidebar-four .sidebar-menu ul li a,
.sidebar.sidebar-four .sidebar-menu,
.searchinputs .search-addon span,
.user-menu.nav > li.flag-nav a,
.user-menu.nav > li > a .badge,
.profilename .profileset,
.header-split .header-menu ul li a,
.header-split .header-menu ul,
.header-split,
.header-pos.header .user-menu.nav li,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .menu-drop-user .dropdown-item,
.header .userset,
.header .header-left,
.error-page .main-wrapper,
.error-page,
.icons-items .icons-list li,
.icons-items .icons-list,
.icons-list li,
.icons-list,
.form-group .btn-filters,
.input-group,
.input-group .scanner-set,
.dropdown-menu .dropdown-item,
.btn-white,
.avatar .avatar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top,
.order-list,
.totalitem,
.invoice-load-btn .btn,
.header-menuset ul li,
.btn-pos ul,
.product-lists,
.setvalue ul li,
.btn-totallabel,
.image-upload-new .productviewscontent,
.card-sales-split,
.bar-code-view,
.product-list ul li .productviews .productviewscontent,
.dashboardBox,
.table-top,
.page-header,
.chat-cont-left .chat-scroll .media .media-body,
.sidebar-content .sidebar-header,
.sidebar .sub-menu a,
.header-split,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .header-left,
.btn-white {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.header-menuset ul,
.calculator-set ul,
.btn-pos ul,
.tab-set ul,
.productdetails > ul > li,
.input-checkset ul,
.product-list ul,
.login-wrapper .login-content .form-sociallink ul,
.login-wrapper,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info,
.customizer-links,
.modal-barcode ul,
.error-page .main-wrapper,
.icons-items .icons-list,
.icons-list {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}

.setvaluecash ul li a,
.chat-cont-right ul.list-unstyled .media.sent .media-body,
.sidebar .nav-items .nav-link {
  -ms-flex-direction: column;
  flex-direction: column;
}

/******* BASE *******/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  /* font: inherit; */
  vertical-align: baseline;
}

body {
  overflow-x: hidden;
}

/* HTML5 display-role reset for older browsers */
* {
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after {
  content: "";
  content: none;
}

q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1 {
  font-weight: normal;
  font-size: 40px;
}
@media (max-width: 767px) {
  h1 {
    font-size: 27px;
  }
}

h2 {
  font-weight: normal;
  font-size: 30px;
}
@media (max-width: 767px) {
  h2 {
    font-size: 23px;
  }
}

h3 {
  font-weight: normal;
  font-size: 24px;
}
@media (max-width: 1023px) {
  h3 {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  h3 {
    font-size: 16px;
  }
}

h4 {
  font-weight: normal;
  font-size: 21px;
}
@media (max-width: 1023px) {
  h4 {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  h4 {
    font-size: 18px;
  }
}

h5 {
  font-weight: normal;
  font-size: 19px;
}
@media (max-width: 1023px) {
  h5 {
    font-size: 18px;
  }
}

h6 {
  font-weight: normal;
  font-size: 18px;
}
@media (max-width: 1023px) {
  h6 {
    font-size: 16px;
  }
}

body {
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  color: #000;
  line-height: 1.5;
  background-color: #fafbfe;
}

.container {
  width: 100%;
  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

img {
  max-width: 100%;
  height: auto;
}

* {
  outline: none;
}

button:focus {
  box-shadow: none !important;
}

a {
  color: #ff9f43;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
a:hover {
  color: #fe820e;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
a:focus {
  outline: 0;
}

p {
  margin-bottom: 20px;
}
p:last-child {
  margin-bottom: 0;
}

strong {
  font-weight: 700;
}

/*** default values ***/
/***** custom values *****/
/***** Grid Values *****/
/** Extends **/
.css-equal-heights,
.css-equal-content {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.activity .activity-list,
.notifications .notification-list,
.sidebar .sidebar-right ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.css-equal-content {
  color: red;
  float: left;
}

.pass-group .toggle-password,
.pass-group .toggle-passworda,
.profile-set .profile-top .profileupload a,
.productset .productsetimg,
.productset.active .check-product,
.order-list .actionproducts > ul > li .deletebg,
.product-details,
.header-menuset ul li a,
.owl-product .owl-nav button,
.productimg .productcontet h4,
.increment-decrement .input-groups input[type="button"],
.setvaluecash ul li a,
.select-split .btn-filters,
.product-view .product-viewimg,
.productviewset .productviewscontent a,
.image-upload-new .productviewscontent a,
.add-icon span,
.add-icon a,
.slider-product-details .owl-carousel .owl-nav button,
.product-list ul li .productviews .productviewscontent a,
.login-wrapper .login-content .form-sociallink ul li a,
.login-wrapper .login-content .toggle-passwords,
.login-wrapper .login-content .toggle-password,
.dash-widget .dash-widgetimg span,
.image-upload.image-upload-new .image-uploads,
.search-set .btn-filter,
.page-header .btn-filter,
.customizer-links,
.modal .modal-footer .btn-print,
.modal .close,
.modal .btn-close,
.sidebar .nav-link,
div#global-loader,
.searchinputs .search-addon span,
.user-menu.nav > li > a .badge,
.error-page .main-wrapper,
.error-page,
.icons-items .icons-list li,
.icons-list li,
.form-group .btn-filters,
.input-group .scanner-set,
.avatar .avatar-title {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.pass-group .toggle-password,
.pass-group .toggle-passworda,
.profile-set .profile-top .profileupload a,
.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top .profile-content,
.profile-set .profile-top,
.productset .productsetimg,
.productset.active .check-product,
.order-list .actionproducts > ul > li .deletebg,
.order-list .actionproducts > ul,
.order-list,
.totalitem,
.btn-scanner-set,
.product-details,
.invoice-load-btn .btn,
.header-menuset ul li a,
.header-menuset ul li,
.header-menuset ul,
.calculator-set ul,
.btn-pos ul,
.actionproduct ul,
.owl-product .owl-nav button,
.tab-set ul,
.productimg .productlinkset,
.productimg .productcontet h4,
.productimg,
.increment-decrement .input-groups input[type="button"],
.increment-decrement .input-groups,
.product-lists,
.setvalue ul li,
.setvaluecash ul li a,
.setvaluecash ul,
.btn-totallabel,
.select-split .btn-filters,
.select-split,
.product-view .product-viewimg,
.productviewset .productviewscontent a,
.image-upload-new .productviewscontent a,
.image-upload-new .productviewscontent,
.card-sales-split,
.total-order ul li,
.add-icon span,
.add-icon a,
.slider-product-details .owl-carousel .owl-nav button,
.input-checkset ul,
.bar-code-view,
.product-list ul li .productviews .productviewscontent a,
.product-list ul li .productviews .productviewscontent,
.product-list ul li .productviews,
.product-list ul,
.login-wrapper .login-content .form-sociallink ul li a,
.login-wrapper .login-content .form-sociallink ul,
.login-wrapper .login-content .toggle-passwords,
.login-wrapper .login-content .toggle-password,
.graph-sets > ul,
.graph-sets,
.dashboardBox,
.dash-widget .dash-widgetimg span,
.dash-widget,
.image-upload.image-upload-new .image-uploads,
.wordset ul,
.search-set .btn-filter,
.search-set,
.table-top,
.page-header .btn-added,
.page-header .btn-filter,
.page-header,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info,
.chat-search .input-group .input-group-prepend,
.switch-wrapper #dark-mode-toggle .light-mode,
.switch-wrapper #dark-mode-toggle .dark-mode,
.switch-wrapper #dark-mode-toggle,
.sidebar-content .sidebar-header,
.modal .modal-footer .btn-print,
.modal .close,
.modal .btn-close,
.modal-barcode ul,
.productimgname,
.sidebar .sub-menu a,
.sidebar .nav-link,
.sidebar .sidebar-menu > ul > li > a,
div#global-loader,
.sidebar.sidebar-four .sidebar-menu ul li a,
.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a,
.searchinputs .search-addon span,
.user-menu.nav > li.flag-nav a,
.user-menu.nav > li > a .badge,
.header-split .header-menu ul li a,
.header-split .header-menu ul,
.header-split,
.header-pos.header .user-menu.nav li,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .menu-drop-user .dropdown-item,
.header .userset,
.header .header-left,
.error-page,
.icons-items .icons-list li,
.icons-list li,
.form-group .btn-filters,
.input-group .scanner-set,
.btn-white,
.avatar .avatar-title {
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top,
.order-list,
.totalitem,
.invoice-load-btn .btn,
.header-menuset ul li,
.btn-pos ul,
.product-lists,
.setvalue ul li,
.btn-totallabel,
.image-upload-new .productviewscontent,
.card-sales-split,
.bar-code-view,
.product-list ul li .productviews .productviewscontent,
.dashboardBox,
.table-top,
.page-header,
.chat-cont-left .chat-scroll .media .media-body,
.sidebar-content .sidebar-header,
.sidebar .sub-menu a,
.header-split,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .header-left,
.btn-white {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.customizer-links ul li a,
.align-center {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.activity .activity-list,
.notifications .notification-list,
.sidebar .sidebar-right ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.switch-wrapper #dark-mode-toggle .light-mode,
.switch-wrapper #dark-mode-toggle .dark-mode,
.switch-wrapper #dark-mode-toggle,
.avatar-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.pass-group .toggle-password,
.pass-group .toggle-passworda,
.profile-set .profile-top .profileupload a,
.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top .profile-content,
.profile-set .profile-top,
.productset .productsetimg,
.productset.active .check-product,
.order-list .actionproducts > ul > li .deletebg,
.order-list .actionproducts > ul,
.order-list,
.totalitem,
.btn-scanner-set,
.product-details,
.header-menuset ul li a,
.header-menuset ul li,
.header-menuset ul,
.calculator-set ul,
.btn-pos ul li a.btn,
.btn-pos ul,
.actionproduct ul,
.owl-product .owl-nav button,
.tab-set ul,
.productimg .productlinkset,
.productimg .productcontet h4,
.productimg,
.increment-decrement .input-groups input[type="button"],
.increment-decrement .input-groups,
.product-lists,
.setvalue ul li,
.setvaluecash ul li a,
.setvaluecash ul,
.btn-totallabel,
.select-split .btn-filters,
.select-split,
.product-view .product-viewimg,
.productviewset .productviewscontent a,
.image-upload-new .productviewscontent a,
.image-upload-new .productviewscontent,
.card-sales-split ul,
.card-sales-split,
.total-order ul li,
.add-icon span,
.add-icon a,
.slider-product-details .owl-carousel .owl-nav button,
.productdetails > ul > li,
.input-checkset ul,
.bar-code-view,
.product-list ul li .productviews .productviewscontent a,
.product-list ul li .productviews .productviewscontent,
.product-list ul li .productviews,
.product-list ul,
.login-wrapper .login-content .form-sociallink ul li a,
.login-wrapper .login-content .form-sociallink ul,
.login-wrapper .login-content .toggle-passwords,
.login-wrapper .login-content .toggle-password,
.login-wrapper,
.graph-sets > ul,
.graph-sets,
.dashboardBox,
.dash-widget .dash-widgetimg span,
.dash-widget,
.image-upload.image-upload-new .image-uploads,
.wordset ul,
.search-set .btn-filter,
.search-set,
.table-top,
.page-header .btn-added,
.page-header .btn-filter,
.page-header,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments,
.chat-cont-right ul.list-unstyled .media.sent .media-body,
.chat-cont-left .chat-scroll .media .media-body,
.chat-search .input-group .input-group-prepend,
.sidebar-content .sidebar-header,
.customizer-links,
.modal .modal-footer .btn-print,
.modal .close,
.modal .btn-close,
.modal-barcode ul,
.productimgname,
.sidebar .nav-items .nav-link,
.sidebar .sub-menu a,
.sidebar .nav-link,
.sidebar .sidebar-menu > ul > li > a,
.sidebar,
div#global-loader,
.sidebar.sidebar-four .sidebar-menu ul li a,
.sidebar.sidebar-four .sidebar-menu,
.searchinputs .search-addon span,
.user-menu.nav > li.flag-nav a,
.user-menu.nav > li > a .badge,
.profilename .profileset,
.header-split .header-menu ul li a,
.header-split .header-menu ul,
.header-split,
.header-pos.header .user-menu.nav li,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .menu-drop-user .dropdown-item,
.header .userset,
.header .header-left,
.error-page .main-wrapper,
.error-page,
.icons-items .icons-list li,
.icons-items .icons-list,
.icons-list li,
.icons-list,
.form-group .btn-filters,
.input-group,
.input-group .scanner-set,
.dropdown-menu .dropdown-item,
.btn-white,
.avatar .avatar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.profile-set .profile-top .profile-contentimg,
.profile-set .profile-top,
.order-list,
.totalitem,
.invoice-load-btn .btn,
.header-menuset ul li,
.btn-pos ul,
.product-lists,
.setvalue ul li,
.btn-totallabel,
.image-upload-new .productviewscontent,
.card-sales-split,
.bar-code-view,
.product-list ul li .productviews .productviewscontent,
.dashboardBox,
.table-top,
.page-header,
.chat-cont-left .chat-scroll .media .media-body,
.sidebar-content .sidebar-header,
.sidebar .sub-menu a,
.header-split,
.header-pos.header .topnav-dropdown-footer,
.header-pos.header .topnav-dropdown-header,
.searchpart .searchhead,
.header .header-left,
.btn-white {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.header-menuset ul,
.calculator-set ul,
.btn-pos ul,
.tab-set ul,
.productdetails > ul > li,
.input-checkset ul,
.product-list ul,
.login-wrapper .login-content .form-sociallink ul,
.login-wrapper,
.chat-cont-right ul.list-unstyled .media.received .chat-msg-info,
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info,
.customizer-links,
.modal-barcode ul,
.error-page .main-wrapper,
.icons-items .icons-list,
.icons-list {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}

.setvaluecash ul li a,
.chat-cont-right ul.list-unstyled .media.sent .media-body,
.sidebar .nav-items .nav-link {
  -ms-flex-direction: column;
  flex-direction: column;
}

.col {
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  position: relative;
  min-height: 1px;
}
@media (min-width: 320px) {
  .col.col-xs-12 {
    width: 100%;
  }
  .col.col-xs-11 {
    width: 91.66666667%;
  }
  .col.col-xs-10 {
    width: 83.33333333%;
  }
  .col.col-xs-9 {
    width: 75%;
  }
  .col.col-xs-8 {
    width: 66.66666667%;
  }
  .col.col-xs-7 {
    width: 58.33333333%;
  }
  .col.col-xs-6 {
    width: 50%;
  }
  .col.col-xs-5 {
    width: 41.66666667%;
  }
  .col.col-xs-4 {
    width: 33.33333333%;
  }
  .col.col-xs-3 {
    width: 25%;
  }
  .col.col-xs-2 {
    width: 16.66666667%;
  }
  .col.col-xs-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 568px) {
  .col.col-sm-12 {
    width: 100%;
  }
  .col.col-sm-11 {
    width: 91.66666667%;
  }
  .col.col-sm-10 {
    width: 83.33333333%;
  }
  .col.col-sm-9 {
    width: 75%;
  }
  .col.col-sm-8 {
    width: 66.66666667%;
  }
  .col.col-sm-7 {
    width: 58.33333333%;
  }
  .col.col-sm-6 {
    width: 50%;
  }
  .col.col-sm-5 {
    width: 41.66666667%;
  }
  .col.col-sm-4 {
    width: 33.33333333%;
  }
  .col.col-sm-3 {
    width: 25%;
  }
  .col.col-sm-2 {
    width: 16.66666667%;
  }
  .col.col-sm-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 768px) {
  .col.col-md-12 {
    width: 100%;
  }
  .col.col-md-11 {
    width: 91.66666667%;
  }
  .col.col-md-10 {
    width: 83.33333333%;
  }
  .col.col-md-9 {
    width: 75%;
  }
  .col.col-md-8 {
    width: 66.66666667%;
  }
  .col.col-md-7 {
    width: 58.33333333%;
  }
  .col.col-md-6 {
    width: 50%;
  }
  .col.col-md-5 {
    width: 41.66666667%;
  }
  .col.col-md-4 {
    width: 33.33333333%;
  }
  .col.col-md-3 {
    width: 25%;
  }
  .col.col-md-2 {
    width: 16.66666667%;
  }
  .col.col-md-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 1024px) {
  .col.col-lg-12 {
    width: 100%;
  }
  .col.col-lg-11 {
    width: 91.66666667%;
  }
  .col.col-lg-10 {
    width: 83.33333333%;
  }
  .col.col-lg-9 {
    width: 75%;
  }
  .col.col-lg-8 {
    width: 66.66666667%;
  }
  .col.col-lg-7 {
    width: 58.33333333%;
  }
  .col.col-lg-6 {
    width: 50%;
  }
  .col.col-lg-5 {
    width: 41.66666667%;
  }
  .col.col-lg-4 {
    width: 33.33333333%;
  }
  .col.col-lg-3 {
    width: 25%;
  }
  .col.col-lg-2 {
    width: 16.66666667%;
  }
  .col.col-lg-1 {
    width: 8.33333333%;
  }
}

/******* COMPONENTS ******/
.btn-submit {
  min-width: 120px;
  background: #ff9f43;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 14px 10px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.btn-submit:hover {
  background: #fe820e;
  color: #fff;
}
@media (max-width: 991.98px) {
  .btn-submit {
    min-width: auto;
    padding: 5px 7px;
  }
}

.btn-cancel {
  min-width: 120px;
  background: #637381;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 14px 10px;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.btn-cancel:hover {
  background: #424b52;
  color: #fff;
}
@media (max-width: 991.98px) {
  .btn-cancel {
    min-width: auto;
    padding: 5px 7px;
  }
}

.btn-primary {
  color: #fff;
  background-color: #17b0e9 !important;
  border-color: #17b0e9 !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1b98c7 !important;
  border-color: #1b98c7 !important;
}

.avatar {
  background-color: transparent;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  height: 34px;
  line-height: 38px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 34px;
  position: relative;
  white-space: nowrap;
  margin: 0 10px 0 0;
  border-radius: 50%;
}
.avatar.avatar-xs {
  width: 24px;
  height: 24px;
}
.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
.avatar .avatar-title {
  width: 100%;
  height: 100%;
  background-color: #0d6efd;
  color: #fff;
}
.avatar.avatar-online::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #28c76f;
}
.avatar.avatar-offline::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #f62d51;
}
.avatar.avatar-away::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #ff9900;
}
.avatar .border {
  border-width: 3px !important;
}
.avatar .rounded {
  border-radius: 6px !important;
}
.avatar .avatar-title {
  font-size: 18px;
}
.avatar .avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}
.avatar .avatar-xs .border {
  border-width: 2px !important;
}
.avatar .avatar-xs .rounded {
  border-radius: 4px !important;
}
.avatar .avatar-xs .avatar-title {
  font-size: 12px;
}
.avatar .avatar-xs.avatar-away::before,
.avatar .avatar-xs.avatar-offline::before,
.avatar .avatar-xs.avatar-online::before {
  border-width: 1px;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}
.avatar-sm .border {
  border-width: 3px !important;
}
.avatar-sm .rounded {
  border-radius: 4px !important;
}
.avatar-sm .avatar-title {
  font-size: 15px;
}
.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
  border-width: 2px;
}

.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}
.avatar-lg .border {
  border-width: 3px !important;
}
.avatar-lg .rounded {
  border-radius: 8px !important;
}
.avatar-lg .avatar-title {
  font-size: 24px;
}
.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
  border-width: 3px;
}

.avatar-xl {
  width: 5rem;
  height: 5rem;
}
.avatar-xl .border {
  border-width: 4px !important;
}
.avatar-xl .rounded {
  border-radius: 8px !important;
}
.avatar-xl .avatar-title {
  font-size: 28px;
}
.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
  border-width: 4px;
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
}
@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }
}
.avatar-xxl .border {
  border-width: 6px !important;
}
@media (min-width: 768px) {
  .avatar-xxl .border {
    border-width: 4px !important;
  }
}
.avatar-xxl .rounded {
  border-radius: 8px !important;
}
@media (min-width: 768px) {
  .avatar-xxl .rounded {
    border-radius: 12px !important;
  }
}
.avatar-xxl .avatar-title {
  font-size: 30px;
}
@media (min-width: 768px) {
  .avatar-xxl .avatar-title {
    font-size: 42px;
  }
}
.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
  border-width: 4px;
}
@media (min-width: 768px) {
  .avatar-xxl.avatar-away::before,
  .avatar-xxl.avatar-offline::before,
  .avatar-xxl.avatar-online::before {
    border-width: 4px;
  }
}

.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}
.avatar-group .avatar:hover {
  z-index: 1;
}
.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.40625rem;
}
.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}

.avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #fc8543;
  text-shadow: unset;
}

.bootstrap-datetimepicker-widget table td.today:before {
  border-bottom-color: #fc8543;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background: transparent;
}

.bor-b1 {
  border-bottom: 1px solid #e9ecef;
}

.calendar-events {
  border: 1px solid transparent;
  cursor: move;
  padding: 10px 15px;
}

.calendar-events:hover {
  border-color: #eeeeee;
  background-color: #fff;
}

.calendar-events i {
  margin-right: 8px;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5rem;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
  font-family: "Roboto", sans-serif;
}

.fc-day {
  background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #eeeeee;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f3f3f3;
}

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
  padding: 2px 5px;
}

.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: #f3f3f3;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #ff9f43 !important;
  color: #fff !important;
  text-shadow: none !important;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-unthemed .fc-today {
  background: #fff;
}

.fc-event {
  border-radius: 2px;
  border: none;
  color: #fff !important;
  cursor: move;
  font-size: 13px;
  margin: 1px 7px;
  padding: 5px 5px;
  text-align: center;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.event-form .input-group .form-control {
  height: 40px;
}

.submit-section {
  text-align: center;
  margin-top: 40px;
}

.submit-btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
}

.apexcharts-legend.apexcharts-align-center.position-right {
  display: none;
}

.apexcharts-toolbar {
  display: none !important;
}

#sales_charts,
.chart-set {
  overflow: hidden;
}

.apexcharts-canvas {
  width: 100% !important;
}

.page-wrapper {
  margin: 0 0 0 260px;
  padding: 60px 0 0;
  position: relative;
  left: 0;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
@media (max-width: 991.98px) {
  .page-wrapper {
    margin: 0;
    padding: 60px 0 0;
  }
}
.page-wrapper .content {
  padding: 25px;
}
@media (max-width: 991.98px) {
  .page-wrapper .content {
    padding: 15px;
  }
}

.sidebarrightmenu .page-wrapper {
  margin: 0 0 0 350px;
}
@media (max-width: 991.98px) {
  .sidebarrightmenu .page-wrapper {
    margin: 0;
  }
}

.card {
  margin: 0 0 25px;
  border: 1px solid #e8ebed;
  border-radius: 6px;
}
.card .card-header {
  border-bottom: 0;
  background: transparent;
  padding: 20px;
}
.card .card-header .dropset {
  color: #6e6b7b;
  padding: 0 10px;
}
.card .card-header .card-title {
  font-size: 18px;
  font-weight: 700;
  color: #212b36;
  margin: 0 0 20px;
}
@media (max-width: 991.98px) {
  .card .card-header .card-title {
    font-size: 14px;
  }
}
.card .card-body {
  padding: 20px;
}
@media (max-width: 991.98px) {
  .card .card-body {
    padding: 15px;
  }
}
.card .card-body .card-title {
  font-size: 18px;
  font-weight: 700;
  color: #212b36;
  margin: 0 0 10px;
}
@media (max-width: 991.98px) {
  .card .card-body .card-title {
    font-size: 14px;
  }
}

.btn-white {
  background-color: #fff;
  border: 1px solid rgba(145, 158, 171, 0.32);
  color: #212b36;
  padding: 3px 10px;
  border-radius: 5px;
  min-width: 90px;
}

.inputcheck {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  font-size: 14px;
}
.inputcheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.inputcheck input:checked ~ .checkmark:after {
  display: block;
}
.inputcheck .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #e9ecef;
  background: #f8f8f8;
  border-radius: 5px;
}
.inputcheck .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.inputcheck .checkmark:after {
  position: absolute;
  top: 3px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #ff9f43;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}
.inputcheck input:checked ~ .checkmark {
  border: 1px solid #ff9f43;
}

.animation-tada {
  animation: tada 1.5s ease infinite;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 50px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ff9f43;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.swal2-header .swal2-title {
  font-size: 18px;
}

.none {
  display: none !important;
}

.text-red {
  color: #ea5455 !important;
}

.text-green {
  color: #28c76f !important;
}

a:focus,
a:hover {
  outline: none;
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: none;
  border-color: #ff9f43;
}

.form-group input[type="text"]:focus {
  border-color: #ff9f43;
  background: #fff;
}

.dropdown-menu {
  padding: 0;
}
.dropdown-menu .dropdown-item {
  color: #212b36;
  font-size: 13px;
  padding: 8px 15px;
  font-weight: 500;
}

.input-group .scanner-set {
  background: #ff9f43;
  border-radius: 5px !important;
  width: 40px;
  color: #fff;
  font-weight: 600;
  padding: 0;
}
.input-group .scanner-set:hover {
  background: #1b2850;
}

.input-group {
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
}

#filter_inputs,
#filter_inputs1,
#filter_inputs2 {
  display: none;
}

.form-group {
  margin-bottom: 25px;
}
.form-group label {
  margin-bottom: 8px;
  color: #212b36;
  font-weight: 500;
  font-size: 14px;
  display: block;
}
.form-group label .manitory {
  color: #ff0000;
}
.form-group .input-groupicon {
  position: relative;
}
.form-group .input-groupicon .addonset {
  position: absolute;
  right: 12px;
  top: 50%;
  line-height: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
@media (max-width: 991.98px) {
  .form-group .input-groupicon .addonset {
    display: none;
  }
}
.form-group input[type="text"],
.form-group input[type="password"] {
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
}
.form-group .btn-filters {
  background: #ff9f43;
  color: #fff;
  width: 40px;
  height: 40px;
  padding: 0;
}
.form-group .btn-filters:hover {
  background: #1b2850;
}
.form-group textarea {
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 100px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  padding: 10px 15px;
  border-radius: 5px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ff9f43;
}

.section-header {
  margin-bottom: 1.875rem;
}
.section-header .section-title {
  color: #333;
  font-weight: 600;
}
.section-header .line {
  background-color: #ff9f43;
  height: 2px;
  margin: 0;
  width: 60px;
}

.comp-section .card-header {
  border: 1px solid #f0f1f5;
  background-color: #fff;
  padding: 10px;
}
.comp-section .card-header .card-title {
  margin: 0;
}
.comp-section .tab-content {
  padding-top: 20px;
}
.comp-section .bullets {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}
.comp-section .bullets li {
  margin-bottom: 5px;
}
.comp-section .bullets ul {
  padding-left: 20px;
}
.comp-section ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}
.comp-section ol li {
  margin-bottom: 5px;
}
.comp-section ol ul {
  padding-left: 20px;
}
.comp-section .list-unstyled li {
  margin-bottom: 5px;
}
.comp-section .list-unstyled ul {
  padding-left: 20px;
}
.comp-section .nav-tabs > li > a {
  margin-right: 0;
  color: #888888;
}
.comp-section .avatar-group .avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}
.comp-section .avatar-group .avatar + .avatar {
  margin-left: -1.35rem;
}
.comp-section .nav-tabs.nav-tabs-solid {
  background-color: #fafafa;
  border: 0;
}
.comp-section .nav-tabs.nav-tabs-solid.nav-tabs-rounded a {
  border-radius: 50px;
}
.comp-section .nav-tabs.nav-tabs-solid a {
  padding: 10px 30px;
}
.comp-section .nav-tabs.nav-tabs-solid a.active {
  background-color: #ff9f43;
  border-color: #ff9f43;
  color: #fff;
}
.comp-section .nav-tabs .nav-tabs-bottom {
  border-bottom: 1px solid #e6e6e6;
}
.comp-section .nav-tabs .nav-tabs-bottom li {
  margin-bottom: -1px;
}
.comp-section .nav-tabs .nav-tabs-bottom li a.active {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: #ff9f43;
  background-color: transparent;
  transition: none 0s ease 0s;
}
.comp-section .progress {
  margin-bottom: 1.5rem;
}
.comp-section .progress.progress-lg {
  height: 18px;
}
.comp-section .progress.progress-md {
  height: 8px;
}
.comp-section .progress.progress-sm {
  height: 6px;
}
.comp-section .progress.progress-xs {
  height: 4px;
}

body[data-theme="dark"] {
  background-color: #141432;
  color: #97a2d2;
}
body[data-theme="dark"] .header {
  background: #141432;
  border-bottom: 1px solid #434548;
}
body[data-theme="dark"] .header .header-left {
  border-right: 1px solid #637381;
  border-bottom: 1px solid #434548;
}
body[data-theme="dark"] .header .header-left .logo-normal {
  display: none;
}
body[data-theme="dark"] .header .header-left .logo-white {
  display: block !important;
}
body[data-theme="dark"] .sidebar {
  border-right: 1px solid #637381;
}
body[data-theme="dark"] .sidebar .slimScrollDiv {
  background: #141432;
}
body[data-theme="dark"] .sidebar.sidebar-four {
  border: 0;
}
body[data-theme="dark"] .page-wrapper {
  background: #141432;
}
body[data-theme="dark"] .page-wrapper.page-wrapper-three .content {
  background: #141432;
}
body[data-theme="dark"] #toggle_btn:before {
  border: 2px solid #97a2d2;
}
body[data-theme="dark"] .user-info .user-name {
  color: #97a2d2;
}
body[data-theme="dark"] .user-menu .nav-item-box > a {
  background: #2c2c50;
}
body[data-theme="dark"] .header-left.active #toggle_btn:after {
  border: 2px solid #97a2d2;
}
body[data-theme="dark"] .sidebar .sidebar-menu > ul > li.submenu ul {
  background: #141432;
  border-radius: 5px;
  padding: 10px 0;
}
body[data-theme="dark"] .sidebar .sidebar-menu > ul > li.submenu ul li a {
  color: #8ea0af;
}
body[data-theme="dark"] .sidebar .sidebar-menu > ul > li a img {
  filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%)
    hue-rotate(165deg) brightness(93%) contrast(88%);
}
body[data-theme="dark"] .sidebar .sidebar-menu > ul > li a span {
  color: #8ea0af;
}
body[data-theme="dark"] .sidebar .sidebar-menu > ul > li a:hover img {
  color: #8ea0af;
}
body[data-theme="dark"] .sidebar .sidebar-menu > ul > li a:hover svg {
  filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%)
    hue-rotate(165deg) brightness(93%) contrast(88%);
}
body[data-theme="dark"] .sidebar .sidebar-menu > ul > li .submenu-hdr {
  color: #8ea0af;
}
body[data-theme="dark"] .dash-widget {
  background: #1d1d42;
  border: 1px solid #353570;
}
body[data-theme="dark"] .dash-widget .dash-widgetcontent h5 {
  color: #97a2d2;
}
body[data-theme="dark"] .dash-widget .dash-widgetcontent h6 {
  color: #97a2d2;
}
body[data-theme="dark"] .page-header h4,
body[data-theme="dark"] .page-header h6 {
  color: #97a2d2;
}
body[data-theme="dark"] .form-control {
  color: #97a2d2;
  border: 1px solid rgba(145, 158, 171, 0.32);
}
body[data-theme="dark"] select.form-control:focus {
  color: #97a2d2;
}
body[data-theme="dark"] .form-group label {
  color: #97a2d2;
}
body[data-theme="dark"] .dataTables_info {
  color: #97a2d2;
}
body[data-theme="dark"] .setvaluecash ul li a {
  color: #97a2d2;
}
body[data-theme="dark"] .inbox-menu li a i {
  color: #97a2d2;
}
body[data-theme="dark"] .card {
  background-color: #1d1d42;
  border: 1px solid #353570;
}
body[data-theme="dark"] .card .card-header .card-title {
  color: #97a2d2;
}
body[data-theme="dark"] .card .card-body .card-title {
  color: #97a2d2;
}
body[data-theme="dark"] .modal-content {
  background-color: #1d1d42;
}
body[data-theme="dark"] .modal-content .modal-header {
  border-bottom: 1px solid #353570;
}
body[data-theme="dark"] .inputcheck {
  color: #97a2d2;
}
body[data-theme="dark"] .fc th.fc-widget-header {
  background-color: #1d1d42;
}
body[data-theme="dark"] .fc-day {
  background-color: #1d1d42;
}
body[data-theme="dark"]
  .chat-cont-left
  .chat-scroll
  .media
  .media-body
  .user-name,
body[data-theme="dark"]
  .chat-cont-left
  .chat-scroll
  .media
  .media-body
  .user-last-chat {
  color: #97a2d2;
}
body[data-theme="dark"] .chat-cont-left .chat-scroll .media.active,
body[data-theme="dark"] .chat-cont-left .chat-scroll .media:hover {
  background: #1b2850;
}
body[data-theme="dark"]
  .chat-cont-right
  ul.list-unstyled
  .media.sent
  .media-body
  .msg-box
  p {
  color: #212b36;
}
body[data-theme="dark"] .inbox-menu li a {
  color: #97a2d2;
}
body[data-theme="dark"] .increment-decrement .quantity-field {
  color: #97a2d2;
}
body[data-theme="dark"] .select2-results__options {
  color: #333;
}
body[data-theme="dark"] .image-upload .image-uploads h4 {
  color: #97a2d2;
}
body[data-theme="dark"] .new-header {
  background: #141432;
  border-top: 1px solid #353570;
}
body[data-theme="dark"] .new-header.side-three {
  border-color: #353570;
}
body[data-theme="dark"] .new-header .sidebar-menu > ul > li > a span {
  color: #8ea0af;
}
body[data-theme="dark"] .new-header .sidebar-menu > ul > li > a img,
body[data-theme="dark"] .new-header .sidebar-menu > ul > li > a svg {
  filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%)
    hue- rotate(165deg) brightness(93%) contrast(88%);
}
body[data-theme="dark"] .new-header .sidebar-menu > ul > li.submenu ul {
  background: #141432;
}
body[data-theme="dark"] .new-header .sidebar-menu > ul > li.submenu ul li a {
  color: #8ea0af;
}
body[data-theme="dark"] .new-header .sidebar-menu > ul > li.submenu ul li img,
body[data-theme="dark"] .new-header .sidebar-menu > ul > li.submenu ul li svg {
  filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%)
    hue-rotate(165deg) brightness(93%) contrast(88%) !important;
}
body[data-theme="dark"] .new-header .sidebar-menu > ul > li.submenu ul li > ul {
  box-shadow: none;
}
body[data-theme="dark"]
  .sidebar.side-three.new-header
  .sidebar-menu
  > ul
  > li
  ul
  li.submenu
  a
  svg {
  filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%)
    hue-rotate(165deg) brightness(93%) contrast(88%);
}
body[data-theme="dark"]
  .sidebar.side-three.new-header
  .sidebar-menu
  > ul
  > li
  ul
  li.submenu
  ul
  li
  a {
  color: #8ea0af;
}
body[data-theme="dark"] .table thead {
  background: #141432;
  border-bottom: 1px solid #353570;
}
body[data-theme="dark"] .table thead tr:hover {
  background: transparent;
}
body[data-theme="dark"] .table thead tr th {
  color: #97a2d2;
}
body[data-theme="dark"] .table tbody tr:hover {
  background: transparent;
}
body[data-theme="dark"] .table tbody tr td {
  color: #97a2d2;
  border-bottom: 1px solid #353570;
}
body[data-theme="dark"] .table tbody tr td a {
  color: #97a2d2 !important;
}
body[data-theme="dark"] .sidebar.sidebar-four .ui-aside .nav-item .nav-link {
  border-bottom: #353570;
}
body[data-theme="dark"] .sidebar.sidebar-four .sidebar-menu ul li a img {
  filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%)
    hue-rotate(165deg) brightness(93%) contrast(88%);
}
body[data-theme="dark"] .select2-results__option {
  background: #1d1d42;
  color: #97a2d2;
}
body[data-theme="dark"] .custom-file-container label {
  color: #97a2d2;
}
body[data-theme="dark"] .select2-dropdown {
  border: 2px solid #26264e;
}
body[data-theme="dark"] .swal2-popup {
  background-color: #1d1d42;
}
body[data-theme="dark"] .swal2-popup .swal2-title {
  color: #97a2d2;
}
body[data-theme="dark"] .swal2-popup .swal2-html-container {
  color: #97a2d2;
}
body[data-theme="dark"] .searchinputs input {
  color: #97a2d2;
  background: #1d1d42;
}
body[data-theme="dark"] .searchinputs input::placeholder {
  color: #8ea0af;
}
body[data-theme="dark"]
  .image-upload-new
  .productviewscontent
  .productviewsname
  h2,
body[data-theme="dark"]
  .image-upload-new
  .productviewscontent
  .productviewsname
  h3 {
  color: #8ea0af;
}
body[data-theme="dark"] .split-card::before,
body[data-theme="dark"] .split-card::after {
  background: #353570;
}
body[data-theme="dark"] input[type="email"] {
  border: 1px solid rgba(145, 158, 171, 0.32);
}
body[data-theme="dark"] .tabs-set .nav-tabs .nav-link {
  background: #141432;
  color: #97a2d2;
}
body[data-theme="dark"] .tabs-set .nav-tabs .nav-link.active {
  background: #ff9f43;
  color: #fff;
}
body[data-theme="dark"] .bor-b1 {
  border-bottom: 1px solid #353570;
}
body[data-theme="dark"] .total-order ul li {
  border: 1px solid #353570;
}
body[data-theme="dark"] .total-order ul li:first-child {
  border-top: 1px solid #353570;
}
body[data-theme="dark"] .total-order ul li h4 {
  border-right: 1px solid #353570;
}
body[data-theme="dark"] hr {
  background-color: #353570;
}
body[data-theme="dark"] .inputcheck .checkmark {
  background: transparent;
}
body[data-theme="dark"] .productset {
  background-color: #1d1d42;
  border: 1px solid #353570;
}
body[data-theme="dark"] .productset .productsetcontent h4 {
  color: #8ea0af;
}
body[data-theme="dark"] .graph-sets .btn-white {
  background-color: #141432;
  color: #8ea0af;
}
body[data-theme="dark"] .graph-sets .btn-white:hover {
  color: #8ea0af;
}
body[data-theme="dark"] .graph-sets .dropdown-menu {
  background-color: #141432;
}
body[data-theme="dark"] .graph-sets .dropdown-menu .dropdown-item {
  color: #8ea0af;
}
body[data-theme="dark"] .graph-sets .dropdown-menu .dropdown-item:hover {
  color: #8ea0af;
  background: #1d1d42;
}
body[data-theme="dark"] .header .dropdown-menu {
  background: #141432;
  color: #97a2d2;
}
body[data-theme="dark"] .order-list .orderid h4,
body[data-theme="dark"] .order-list .orderid h5 {
  color: #97a2d2;
}
body[data-theme="dark"] .productimg .productcontet h4 {
  color: #97a2d2;
}
body[data-theme="dark"] .topnav-dropdown-header .notification-title {
  color: #97a2d2;
}
body[data-theme="dark"] .topnav-dropdown-footer a {
  color: #97a2d2;
}
body[data-theme="dark"]
  .custom-file-container
  .custom-file-container__custom-file__custom-file-control__button {
  color: #97a2d2;
}
body[data-theme="dark"] .notifications .notification-list li {
  border-bottom: 1px solid #8ea0af;
}
body[data-theme="dark"] .notifications .notification-list li .noti-title {
  color: #97a2d2;
}
body[data-theme="dark"] .notifications .notification-list li .noti-details {
  color: #8ea0af;
}
body[data-theme="dark"]
  .notifications
  .notification-list
  li
  .notification-time {
  color: #8ea0af;
}
body[data-theme="dark"]
  .user-menu.nav
  li.flag-nav
  .dropdown-menu
  .dropdown-item {
  color: #8ea0af;
}
body[data-theme="dark"]
  .user-menu.nav
  li.flag-nav
  .dropdown-menu
  .dropdown-item:hover {
  color: #8ea0af;
  background: #1d1d42;
}
body[data-theme="dark"]
  .user-menu.nav.nav
  > li
  > a.dropdown-toggle.nav-link.show {
  background: #1d1d42;
}
body[data-theme="dark"] .profilename .profileset h5,
body[data-theme="dark"] .profilename .profileset h6 {
  color: #8ea0af;
}
body[data-theme="dark"] .header .menu-drop-user .dropdown-item {
  color: #8ea0af;
}
body[data-theme="dark"] .header .menu-drop-user .dropdown-item:hover {
  color: #8ea0af;
  background: #1d1d42;
}
body[data-theme="dark"] .card.bg-white {
  background-color: #1d1d42 !important;
}
body[data-theme="dark"] .card .card-header {
  background-color: #1d1d42;
}
body[data-theme="dark"] .card .card-header .dropset {
  color: #8ea0af;
}
body[data-theme="dark"] .card .card-footer {
  background-color: #1d1d42;
}
body[data-theme="dark"]
  .custom-file-container
  .custom-file-container__custom-file__custom-file-control {
  border: 1px solid #353570;
}
body[data-theme="dark"]
  .custom-file-container
  .custom-file-container__custom-file__custom-file-control
  .custom-file-container__custom-file__custom-file-control__button {
  border-left: 1px solid #353570;
}
body[data-theme="dark"] .scanner-set img {
  filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%)
    hue-rotate(165deg) brightness(93%) contrast(88%);
  width: 16px;
}
body[data-theme="dark"] .dropdown-menu {
  background: #141432;
  color: #97a2d2;
}
body[data-theme="dark"] .dropdown-menu .dropdown-item {
  color: #8ea0af;
}
body[data-theme="dark"] .dropdown-menu .dropdown-item:hover {
  color: #8ea0af;
  background: #1d1d42;
}
body[data-theme="dark"] .page-header h3 {
  color: #97a2d2;
}
body[data-theme="dark"] .search-set .search-input input[type="search"] {
  border: 1px solid #8ea0af;
}
body[data-theme="dark"]
  .search-set
  .search-input
  input[type="search"]::placeholder {
  color: #8ea0af;
}
body[data-theme="dark"] .form-group input[type="text"],
body[data-theme="dark"] .form-group input[type="password"],
body[data-theme="dark"] .select2-container--default .select2-selection--single,
body[data-theme="dark"] .select2-container--focus .select2-selection--single,
body[data-theme="dark"] .form-control,
body[data-theme="dark"] .form-select,
body[data-theme="dark"]
  .select2-container--default
  .select2-selection--multiple {
  background: #1d1d42 !important;
  color: #97a2d2;
}
body[data-theme="dark"] .form-group input[type="text"]::placeholder,
body[data-theme="dark"] .form-group input[type="password"]::placeholder,
body[data-theme="dark"]
  .select2-container--default
  .select2-selection--single::placeholder,
body[data-theme="dark"]
  .select2-container--focus
  .select2-selection--single::placeholder,
body[data-theme="dark"] .form-control::placeholder,
body[data-theme="dark"] .form-select::placeholder,
body[data-theme="dark"]
  .select2-container--default
  .select2-selection--multiple::placeholder {
  color: #97a2d2;
}
body[data-theme="dark"]
  .select2-container--default
  .select2-results__option[aria-selected="true"] {
  background: #1d1d42 !important;
  color: #97a2d2 !important;
}
body[data-theme="dark"]
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #97a2d2;
}
body[data-theme="dark"]
  .custom-file-container
  .custom-file-container__custom-file__custom-file-control {
  background: #1d1d42;
  color: #97a2d2;
}
body[data-theme="dark"] .input-group-text {
  background: #1d1d42;
  color: #97a2d2;
  border: 1.5px solid rgba(145, 158, 171, 0.32);
}
body[data-theme="dark"] .image-upload:hover {
  background: #1d1d42;
}
body[data-theme="dark"] .productdetails > ul > li h4 {
  color: #8ea0af;
}
body[data-theme="dark"] .profile-set .profile-top .profile-contentname h2 {
  color: #97a2d2;
}
body[data-theme="dark"] .total-order ul li h4 {
  background: #1d1d42;
}
body[data-theme="dark"] .total-order ul li h5 {
  color: #97a2d2;
}
body[data-theme="dark"] .product-details {
  background: #1d1d42;
}
body[data-theme="dark"] .product-details h6 {
  color: #97a2d2;
}
body[data-theme="dark"] .comp-section .card-header {
  border: 1px solid #353570;
}
body[data-theme="dark"] .comp-section .nav-tabs.nav-tabs-solid {
  background-color: #1d1d42;
}
body[data-theme="dark"] .list-group-item {
  background: #1d1d42;
  color: #97a2d2;
}
body[data-theme="dark"] .bg-white {
  background-color: #1d1d42 !important;
}
body[data-theme="dark"] .section-header .section-title {
  color: #97a2d2;
}
body[data-theme="dark"] .breadcrumb .breadcrumb-item a {
  color: #97a2d2;
}
body[data-theme="dark"]
  .product-list
  ul
  li
  .productviews
  .productviewscontent
  .productviewsname
  h2,
body[data-theme="dark"]
  .product-list
  ul
  li
  .productviews
  .productviewscontent
  .productviewsname
  h3 {
  color: #97a2d2;
}
body[data-theme="dark"]
  .select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #97a2d2;
}
body[data-theme="dark"] .alertify .ajs-header {
  background: #1d1d42;
}
body[data-theme="dark"] .alertify .ajs-dialog {
  background: #1d1d42;
}
body[data-theme="dark"] .alertify .ajs-footer {
  background: #1d1d42;
}
body[data-theme="dark"] .alertify .ajs-footer .ajs-buttons .ajs-button {
  color: #97a2d2;
}
body[data-theme="dark"]
  .bs-popover-auto[data-popper-placement^="top"]
  > .popover-arrow::after,
body[data-theme="dark"] .bs-popover-top > .popover-arrow::after {
  border-top-color: #353570;
}
body[data-theme="dark"] .popover {
  background-color: #1d1d42;
  border: 1px solid #353570;
}
body[data-theme="dark"] .popover .popover-header {
  background-color: #1d1d42;
  border-bottom: 1px solid #353570;
}
body[data-theme="dark"] .popover .popover-body {
  color: #97a2d2;
}
body[data-theme="dark"] .scroll-demo {
  border: 1px solid #353570;
}
body[data-theme="dark"] .timeline:before {
  background-color: #353570;
}
body[data-theme="dark"] .timeline > li > .timeline-panel {
  border: 1px solid #353570;
}
body[data-theme="dark"] .timeline > li > .timeline-panel::before {
  border-top: 8px solid transparent;
  border-right: 0 solid #353570;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #353570;
}
body[data-theme="dark"] .timeline > li > .timeline-panel::after {
  border-top: 7px solid transparent;
  border-right: 0 solid #353570;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #353570;
}
body[data-theme="dark"]
  .timeline
  > li.timeline-inverted
  > .timeline-panel:after {
  border-right-width: 7px;
  border-left-width: 0;
}
body[data-theme="dark"]
  .timeline
  > li.timeline-inverted
  > .timeline-panel::before {
  border-right-width: 8px;
  border-left-width: 0;
}
body[data-theme="dark"]
  .twitter-bs-wizard
  .twitter-bs-wizard-nav
  .nav-link::after {
  background-color: #353570;
}
body[data-theme="dark"] .btn-white {
  background-color: #141432;
  border: 1px solid #353570;
  color: #97a2d2;
}
body[data-theme="dark"] .calendar-events:hover {
  background-color: #353570;
  border: 1px solid #353570;
  color: #97a2d2;
}
body[data-theme="dark"] .fc-button {
  color: #97a2d2;
}
body[data-theme="dark"]
  .sidebar.side-three.new-header
  .sidebar-menu
  > ul
  > li
  > a
  span {
  color: #8ea0af !important;
}
body[data-theme="dark"]
  .sidebar.side-three.new-header
  .sidebar-menu
  > ul
  > li
  > a
  img,
body[data-theme="dark"]
  .sidebar.side-three.new-header
  .sidebar-menu
  > ul
  > li
  > a
  svg {
  filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%)
    hue-rotate(165deg) brightness(93%) contrast(88%);
}
body[data-theme="dark"] .cardhead .card-header {
  border-bottom: 1px solid #353570;
}
body[data-theme="dark"] .sidebar-content {
  background: #1d1d42;
}
body[data-theme="dark"] .sidebar-content .sidebar-header {
  border: 1px solid #353570;
}
body[data-theme="dark"] .sidebar-content .sidebar-header h5 {
  color: #97a2d2;
}
body[data-theme="dark"] .sidebar-content .sidebar-header a img {
  filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%)
    hue-rotate(165deg) brightness(93%) contrast(88%);
}
body[data-theme="dark"] .sidebar-content .switch-wrapper {
  border-bottom: 1px solid #353570;
}
body[data-theme="dark"] .sidebar-content .layout-wrap .status-text {
  color: #97a2d2;
}
body[data-theme="dark"]
  .sidebar-content
  .layout-wrap
  .status-toggle
  .checktoggle {
  background: #97a2d2;
}
body[data-theme="dark"] .login-logo.logo-normal {
  display: none;
}
body[data-theme="dark"] .login-logo.logo-white {
  display: block !important;
}
body[data-theme="dark"] .login-wrapper .login-content .login-userheading h3,
body[data-theme="dark"] .login-wrapper .login-content .login-userheading h4 {
  color: #97a2d2;
}
body[data-theme="dark"] .login-wrapper .login-content .form-login label {
  color: #97a2d2;
}
body[data-theme="dark"] .login-wrapper .login-content .signinform h4 {
  color: #97a2d2;
}
body[data-theme="dark"] .login-wrapper .login-content .signinform h4 a {
  color: #97a2d2;
}
body[data-theme="dark"] .login-wrapper .login-content .form-sociallink ul li a {
  color: #97a2d2;
}
body[data-theme="dark"]
  .login-wrapper
  .login-content
  .form-sociallink
  ul
  li
  a:hover {
  color: #fff;
}
body[data-theme="dark"] .login-wrapper .login-content .form-login input {
  background: #1d1d42 !important;
  color: #97a2d2;
}
body[data-theme="dark"]
  .login-wrapper
  .login-content
  .form-login
  input::placeholder {
  color: #97a2d2;
}

.btn-outline-primary {
  color: #ff9f43;
  border-color: #ff9f43;
}
.btn-outline-primary:hover,
.btn-outline-primary.active {
  border-color: #ff9f43;
  background: #ff9f43;
  color: #fff;
}

.btn-outline-info:hover,
.btn-outline-warning:hover {
  color: #fff;
}

.cardhead .card-header {
  border-bottom: 1px solid #e8ebed;
  background: transparent;
  padding: 15px;
}
.cardhead .card-header .card-title {
  margin: 0;
}

.breadcrumb {
  margin: 0;
}
.breadcrumb .breadcrumb-item a {
  color: #000;
}

.ribbon {
  height: 30px;
  line-height: 30px;
  clear: left;
  color: #fff;
  z-index: 1;
  padding: 0 20px;
  position: absolute;
  top: 12px;
  left: 0;
}
.ribbon.ribbon-primary {
  background: #ff9f43;
}
.ribbon.ribbon-secondary {
  background: #f62d51;
}
.ribbon.ribbon-success {
  background: #26af48;
}
.ribbon.ribbon-right {
  left: auto;
  right: -2px;
}
.ribbon.ribbon-vertical-left {
  clear: none;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  position: absolute;
  top: -2px;
  left: 12px;
  padding: 0 5px;
}
.ribbon.ribbon-vertical-right {
  clear: none;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  position: absolute;
  top: -2px;
  right: 12px;
  left: auto;
  padding: 0 5px;
}

.ribbon-wrapper {
  position: relative;
  padding-top: 40px;
}

.ribbon-vertical-left-wrapper {
  padding-left: 40px;
  position: relative;
}

.ribbon-vertical-right-wrapper {
  padding-right: 40px;
  position: relative;
}

.list-group .list-group-item .handle {
  padding: 0 5px;
  margin-right: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: move;
  font-size: 16px;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar,
.irs--flat .irs-handle > i:first-child,
.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
  background-color: #ff9f43;
}

/*-----------------
 Alert
-----------------------*/
.alertify .ajs-commands {
  right: 4px;
  left: auto;
  margin: -14px 0 0 24px;
}
.alertify .ajs-body .ajs-content .ajs-input:focus-visible {
  outline: 0;
}
.alertify .ajs-footer .ajs-buttons .ajs-button {
  font-weight: 500;
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
  color: #ff9f43;
}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-primary {
  text-align: right;
}
.alertify .ajs-header {
  border-bottom: 1px solid #eeeeee;
}
.alertify .ajs-footer {
  border-top: 1px solid #eeeeee;
}

.alertify-notifier .ajs-message {
  background-color: #ff9f43;
  border-color: #ff9f43;
  color: #fff;
  text-shadow: none !important;
}
.alertify-notifier .ajs-message.ajs-success {
  background-color: #26af48;
  border-color: #26af48;
}
.alertify-notifier .ajs-message.ajs-error {
  background-color: #f62d51;
  border-color: #f62d51;
}
.alertify-notifier .ajs-message.ajs-warning {
  background-color: #ff9f43;
  border-color: #ff9f43;
}
.alertify-notifier.ajs-right {
  right: 10px;
  left: auto;
}
.alertify-notifier.ajs-right .ajs-message {
  right: -320px;
  left: auto;
}
.alertify-notifier.ajs-right .ajs-message.ajs-visible {
  right: 290px;
  left: auto;
}
.alertify-notifier.ajs-left {
  left: 10px;
  right: auto;
}
.alertify-notifier.ajs-left .ajs-message {
  left: -300px;
  right: auto;
}
.alertify-notifier.ajs-left .ajs-message.ajs-visible {
  left: 0;
  right: auto;
}

body[data-layout-mode="dark"] .alertify .ajs-dialog {
  background-color: #333;
}
body[data-layout-mode="dark"] .alertify .ajs-footer {
  color: #b8bcc9;
  background-color: #333;
  border-color: #333;
}
body[data-layout-mode="dark"] .alertify .ajs-footer .ajs-buttons .ajs-button {
  color: #b8bcc9;
}
body[data-layout-mode="dark"]
  .alertify
  .ajs-footer
  .ajs-buttons
  .ajs-button.ajs-ok {
  color: #ff9f43;
}
body[data-layout-mode="dark"] .alertify .ajs-header {
  color: #b8bcc9;
  background-color: #333;
  border-color: #333;
}
body[data-layout-mode="dark"] .alertify .ajs-body {
  color: #b8bcc9;
}
body[data-layout-mode="dark"] .alertify .ajs-body .ajs-content .ajs-input {
  color: #b8bcc9;
  background-color: #333;
  border: 1px solid #333;
}
body[data-layout-mode="dark"] .alertify .ajs-content .ajs-input {
  display: block;
  width: 100%;
  border-radius: 2px;
  border: 1px solid #cccccc;
  margin: 4px;
  padding: 8px;
}

.alertify .ajs-footer .ajs-buttons .ajs-button {
  background-color: transparent;
  color: #000;
  border: 0;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.swal2-actions button {
  margin-right: 10px;
}

.swal2-title {
  font-size: 22px !important;
}

.timeline {
  position: relative;
  list-style: none;
  max-width: 1200px;
  float: left;
  margin: 0 auto;
  padding: 20px 0;
}
.timeline:before {
  content: " ";
  width: 3px;
  margin-left: -1.5px;
  background-color: #e9ecef;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
}
@media (max-width: 991.98px) {
  .timeline:before {
    left: 40px;
  }
}
.timeline li {
  position: relative;
  margin-bottom: 20px;
  float: left;
  width: 100%;
}
.timeline li:before,
.timeline li:after {
  content: " ";
  display: table;
}
.timeline li .timeline-badge {
  z-index: 1;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  text-align: center;
  font-size: 1.4em;
  line-height: 50px;
  color: #fff;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 50%;
}
@media (max-width: 991.98px) {
  .timeline li .timeline-badge {
    top: 16px;
    left: 15px;
    margin-left: 0;
  }
}
.timeline li .timeline-badge.success {
  background-color: #26af48;
}
.timeline li .timeline-badge.warning {
  background-color: #ff9900;
}
.timeline li .timeline-badge.danger {
  background-color: #f62d51;
}
.timeline li .timeline-badge.info {
  background-color: #0d6efd;
}
.timeline li .timeline-panel {
  float: left;
  position: relative;
  width: 46%;
  padding: 20px;
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}
@media (max-width: 991.98px) {
  .timeline li .timeline-panel {
    float: right;
    width: calc(100% - 90px);
  }
}
.timeline li.timeline-inverted .timeline-panel {
  float: right;
}

/*-----------------
 Timeline
-----------------------*/
.timeline {
  position: relative;
  list-style: none;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0 20px;
}

.timeline:before {
  content: " ";
  position: absolute;
  width: 3px;
  margin-left: -1.5px;
  background-color: #e9ecef;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
}

.timeline > li {
  position: relative;
  margin-bottom: 20px;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  float: left;
  position: relative;
  width: 46%;
  padding: 20px;
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}

.timeline > li > .timeline-panel:before {
  content: " ";
  display: inline-block;
  position: absolute;
  border-top: 8px solid transparent;
  border-right: 0 solid #e9ecef;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #e9ecef;
  position: absolute;
  top: 26px;
  right: -8px;
}

.timeline > li > .timeline-panel:after {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 27px;
  right: -7px;
  border-top: 7px solid transparent;
  border-right: 0 solid #fff;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #fff;
}

.timeline > li > .timeline-badge {
  z-index: 1;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  text-align: center;
  font-size: 1.4em;
  line-height: 50px;
  color: #fff;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 50%;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -8px;
  border-right-width: 8px;
  border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -7px;
  border-right-width: 7px;
  border-left-width: 0;
}

.timeline-badge.primary {
  background-color: #ff9f43;
}

.timeline-badge.success {
  background-color: #26af48;
}

.timeline-badge.warning {
  background-color: #ff9900;
}

.timeline-badge.danger {
  background-color: #f62d51;
}

.timeline-badge.info {
  background-color: #0d6efd;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
  font-weight: 400;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

.cd-horizontal-timeline .events a {
  padding-bottom: 6px;
  color: #ff9f43;
}

.cd-horizontal-timeline .filling-line,
.cd-horizontal-timeline .events a.selected::after {
  background: #ff9f43;
}

.cd-horizontal-timeline .events a.selected::after {
  border-color: #ff9f43;
}

.cd-horizontal-timeline .m-t-40 {
  margin-top: 25px !important;
}

.cd-horizontal-timeline ol,
.cd-horizontal-timeline ul {
  list-style: none;
}

.cd-timeline-navigation a:hover,
.cd-timeline-navigation a:focus {
  border-color: #ff9f43;
}

.cd-horizontal-timeline a,
.cd-horizontal-timeline a:hover,
.cd-horizontal-timeline a:focus {
  color: #ff9f43;
}

.cd-horizontal-timeline blockquote,
.cd-horizontal-timeline q {
  quotes: none;
}

.cd-horizontal-timeline blockquote:before,
.cd-horizontal-timeline blockquote:after,
.cd-horizontal-timeline q:before,
.cd-horizontal-timeline q:after {
  content: "";
  content: none;
}

.cd-horizontal-timeline table {
  border-collapse: collapse;
  border-spacing: 0;
}

.cd-horizontal-timeline {
  opacity: 0;
  margin: 2em auto;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.cd-horizontal-timeline::before {
  content: "mobile";
  display: none;
}

.cd-horizontal-timeline.loaded {
  opacity: 1;
}

.cd-horizontal-timeline .timeline {
  position: relative;
  height: 100px;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}

.cd-horizontal-timeline .events-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  margin: 0 40px;
}

.cd-horizontal-timeline .events-wrapper::after,
.cd-horizontal-timeline .events-wrapper::before {
  content: "";
  z-index: 2;
  height: 100%;
  width: 20px;
  position: absolute;
  top: 0;
}

.cd-horizontal-timeline .events-wrapper::before {
  left: 0;
}

.cd-horizontal-timeline .events-wrapper::after {
  right: 0;
}

.cd-horizontal-timeline .events {
  z-index: 1;
  height: 2px;
  background: #dfdfdf;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: absolute;
  top: 30px;
  left: 0;
}

.cd-horizontal-timeline .filling-line {
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: #ff9f43;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform-origin: left center;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
}

.cd-horizontal-timeline .events a {
  z-index: 2;
  text-align: center;
  font-size: 16px;
  padding-bottom: 15px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  position: absolute;
  bottom: 0;
}

.cd-horizontal-timeline .events a::after {
  content: "";
  height: 12px;
  width: 12px;
  border: 2px solid #dfdfdf;
  background-color: #f8f8f8;
  transition: background-color 0.3s, border-color 0.3s;
  border-radius: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  position: absolute;
  right: auto;
  bottom: -5px;
  left: 50%;
}

.no-touch .cd-horizontal-timeline .events a:hover::after {
  background-color: #ff9f43;
  border-color: #ff9f43;
}

.cd-horizontal-timeline .events a.selected {
  pointer-events: none;
}

.cd-horizontal-timeline .events a.selected::after {
  background-color: #ff9f43;
  border-color: #ff9f43;
}

.cd-horizontal-timeline .events a.older-event::after {
  border-color: #ff9f43;
}

.cd-timeline-navigation a {
  z-index: 1;
  height: 34px;
  width: 34px;
  border: 2px solid #dfdfdf;
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  transition: border-color 0.3s;
  border-radius: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  bottom: auto;
}

.cd-timeline-navigation a::after {
  content: "";
  height: 16px;
  width: 16px;
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  /* background: url(../img/arrow.svg) no-repeat 0 0; */
}

.cd-timeline-navigation a.prev {
  transform: translateY(-50%) rotate(180deg);
  -webkit-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
}

.cd-timeline-navigation a.next {
  right: 0;
}

.no-touch .cd-timeline-navigation a:hover {
  border-color: #7b9d6f;
}

.cd-timeline-navigation a.inactive {
  cursor: not-allowed;
}

.cd-timeline-navigation a.inactive::after {
  background-position: 0 -16px;
}

.no-touch .cd-timeline-navigation a.inactive:hover {
  border-color: #dfdfdf;
}

.cd-horizontal-timeline .events-content {
  position: relative;
  width: 100%;
  margin: 2em 0;
  overflow: hidden;
  transition: height 0.4s;
}

.cd-horizontal-timeline .events-content li {
  z-index: 1;
  width: 100%;
  padding: 0 5%;
  opacity: 0;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
}

.cd-horizontal-timeline .events-content li.selected {
  position: relative;
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
}

.cd-horizontal-timeline .events-content li.enter-right,
.cd-horizontal-timeline .events-content li.leave-right {
  -webkit-animation-name: cd-enter-right;
  -moz-animation-name: cd-enter-right;
  animation-name: cd-enter-right;
}

.cd-horizontal-timeline .events-content li.enter-left,
.cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-name: cd-enter-left;
  -moz-animation-name: cd-enter-left;
  animation-name: cd-enter-left;
}

.cd-horizontal-timeline .events-content li.leave-right,
.cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  animation-direction: reverse;
}

.cd-horizontal-timeline .events-content li > * {
  max-width: 800px;
  margin: 0 auto;
}

.cd-horizontal-timeline .events-content h2 {
  font-weight: 600;
  margin-bottom: 0px;
}

.cd-horizontal-timeline .events-content em {
  display: block;
  font-style: italic;
  margin: 10px auto;
}

.cd-horizontal-timeline .events-content em::before {
  content: "- ";
}

.cd-horizontal-timeline .events-content p {
  font-size: 16px;
}

.cd-horizontal-timeline .timeline:before {
  content: " ";
  display: none;
  bottom: 0;
  left: 0%;
  width: 0px;
  margin-left: -1.5px;
  background-color: #eeeeee;
}

.cd-horizontal-timeline .events-content em,
.cd-horizontal-timeline .events-content p {
  line-height: 30px;
}

.h-250 {
  height: 250px;
}

.icons-list {
  padding: 0;
}

.icons-list li {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 18px;
  border-radius: 50px;
  border: 1px solid #e6ebf1;
  color: #ff9f43;
  margin: 5px;
  -webkit-box-shadow: 0 2px 3px rgb(215, 197, 255);
  -moz-box-shadow: 0 2px 3px rgb(215, 197, 255);
  box-shadow: 0 2px 3px rgb(215, 197, 255);
}

.cardhead .page-title {
  font-weight: 600;
  font-size: 22px;
}

.email-header .btn-white {
  min-width: auto;
}
.email-header .btn-white i {
  margin-left: 5px;
}

.icons-items .icons-list {
  padding: 0;
}
.icons-items .icons-list li {
  width: 40px;
  height: 40px;
  font-size: 18px;
  border-radius: 50px;
  border: 1px solid #e6ebf1;
  color: #ff9f43;
  margin: 5px;
  -webkit-box-shadow: 0 2px 3px rgb(215, 197, 255);
  -moz-box-shadow: 0 2px 3px rgb(215, 197, 255);
  box-shadow: 0 2px 3px rgb(215, 197, 255);
}

.custom-file-container {
  box-sizing: border-box;
  position: relative;
  display: block;
}
.custom-file-container label {
  color: #4361ee;
}
.custom-file-container .custom-file-container__custom-file {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
  margin-top: 5px;
}
.custom-file-container input[type="file"] {
  height: auto;
  min-height: calc(1.5em + 0.75rem + 2px);
}
.custom-file-container .custom-file-container__custom-file__custom-file-input {
  box-sizing: border-box;
  min-width: 14rem;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-container
  .custom-file-container__custom-file__custom-file-control {
  box-sizing: border-box;
  z-index: 1;
  height: auto;
  overflow: hidden;
  line-height: 1.5;
  user-select: none;
  background-clip: padding-box;
  border-radius: 0.25rem;
  height: auto;
  border: 1px solid #f3f2f7;
  color: #212b36;
  font-size: 15px;
  padding: 8px 10px;
  letter-spacing: 1px;
  background-color: #f3f2f7;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.custom-file-container
  .custom-file-container__custom-file__custom-file-control__button {
  box-sizing: border-box;
  z-index: 6;
  display: block;
  height: auto;
  padding: 10px 16px;
  line-height: 1.25;
  background-color: rgba(27, 85, 226, 0.23);
  color: #4361ee;
  border-left: 1px solid #e0e6ed;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
}
.custom-file-container .custom-file-container__image-preview {
  box-sizing: border-box;
  transition: all 0.2s ease;
  margin-top: 50px;
  margin-bottom: 40px;
  height: 250px;
  width: 100%;
  border-radius: 4px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #fff;
  overflow: auto;
  padding: 15px;
}

.error-page {
  color: #1f1f1f;
  height: 100vh;
}
.error-page .main-wrapper {
  height: auto;
  width: 100%;
}
.error-page .main-wrapper .error-box {
  max-width: 480px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 1.875rem 0;
}
.error-page .main-wrapper .error-box h1 {
  color: #ff9f43;
  font-size: 10em;
}
.error-page .main-wrapper .error-box h3 {
  font-size: 26px;
}
.error-page .main-wrapper .error-box p {
  margin-bottom: 25px;
  font-size: 20px;
}
.error-page .main-wrapper .error-box .btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
}
.error-page .main-wrapper .error-box .btn:hover {
  opacity: 0.8;
}

table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  top: 14px;
  color: #babfc7;
  font-size: 12px;
  opacity: 1;
}

table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:before {
  right: 0.5em;
  content: "\f106";
  font-family: "Font Awesome 5 Free";
  top: 5px;
  color: #babfc7;
  font-size: 12px;
  opacity: 1;
}

.paging_numbers {
  float: right;
}

.dataTables_info {
  float: right;
  padding-right: 15px;
  font-size: 12px;
  color: #5e5873;
  font-weight: 600;
}

.custom-select {
  min-width: 80px;
}

.pagination li {
  margin: 0 5px;
}

.paginationItem {
  border-radius: 3px;
  width: 34px;
  height: 34px;
  background-color: #444;
  border: 1px solid #ccc;
  color: #fff;
  font-weight: bold;
}

.paginationItemActive {
  background-color: #18b0e9;
}

.paginationItem:hover {
  background: #777;
}

.dataTables_length,
.dataTables_paginate {
  margin-top: 15px !important;
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 23px;
}

.header {
  background: #ff9f43;
  background: #fff;
  border-bottom: 1px solid #dee2e6;
  height: 60px;
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}
.header:hover .top-nav-search form .form-control {
  border: 1px solid #f1f1f1;
}
.header .searchinputs {
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: #67748e;
}
.header .searchinputs.show {
  position: absolute;
  top: 0px;
}
.header .dropdown-menu {
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transform: translate(0px, 100px);
  -webkit-transform: translate(0px, 100px);
  -ms-transform: translate(0px, 100px);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  border: 0;
  margin-top: 10px !important;
}
.header .dropdown-menu.menu-drop-user {
  margin-top: 7px !important;
}
@media (max-width: 991.98px) {
  .header .dropdown-menu {
    transition: none;
  }
}
.header .header-left {
  float: left;
  height: 60px;
  position: relative;
  text-align: center;
  width: 260px;
  z-index: 1;
  padding: 0 20px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.header .header-left .logo {
  display: inline-block;
  line-height: 0;
}
.header .header-left .logo img {
  width: 140px;
}
@media (max-width: 991.98px) {
  .header .header-left .logo {
    text-align: center;
    width: 100%;
  }
  .header .header-left .logo img {
    width: 140px;
  }
}
.header .header-left .logo-small {
  display: none;
}
@media (max-width: 991.98px) {
  .header .header-left {
    position: absolute;
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .header .header-left #toggle_btn {
    display: none;
  }
}
@media (min-width: 991.98px) {
  .header #toggle_btn {
    float: left;
    font-size: 26px;
    line-height: 50px;
  }
  .header #toggle_btn .bar-icon {
    display: inline-block;
    width: 21px;
  }
  .header #toggle_btn .bar-icon span {
    background-color: #fff;
    display: block;
    float: left;
    height: 2px;
    width: 21px;
    margin: 0 0 5px;
    border-radius: 50px;
  }
  .header #toggle_btn .bar-icon span:nth-child(2) {
    width: 15px;
  }
}
.header .page-title-box {
  float: left;
  height: 60px;
  margin-bottom: 0;
  padding: 17px 20px;
  border-radius: 0;
}
@media (max-width: 991.98px) {
  .header .page-title-box {
    display: none;
  }
}
.header .page-title-box a {
  color: #ff9f43;
  font-size: 11px;
  font-weight: 700;
  border: 1px solid #ff9f43;
  margin: 0;
  padding: 5px;
  border-radius: 5px;
}
.header .page-title-box a:hover {
  background: #ff9f43;
  color: #fff;
}
.header .mobile_btn {
  display: none;
  float: left;
}
@media (max-width: 991.98px) {
  .header .mobile_btn {
    color: #ff9f43;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    width: 60px;
    z-index: 10;
    padding: 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
}
.header .responsive-search {
  display: none;
  color: #fff;
  font-size: 20px;
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
}
@media (max-width: 991.98px) {
  .header .responsive-search {
    display: block;
  }
}
.header .top-nav-search form {
  position: relative;
  width: 230px;
}
@media (max-width: 991.98px) {
  .header .top-nav-search form {
    display: none;
  }
}
.header .top-nav-search form .form-control {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.15);
  height: 40px;
  padding: 10px 50px 10px 15px;
  border-radius: 50px;
}
.header .top-nav-search form .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.header .top-nav-search form .btn {
  background-color: transparent;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.7);
  min-height: 40px;
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 991.98px) {
  .header .top-nav-search.active form {
    display: block;
    position: absolute;
    left: 0;
  }
  .header .top-nav-search.active form .form-control {
    background-color: #fff;
  }
  .header .top-nav-search.active form .btn {
    background-color: transparent;
    border-color: transparent;
    min-height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 7px 15px;
  }
}
.header .userset .usernames {
  line-height: initial;
  text-align: right;
  padding-right: 10px;
}
.header .userset .usernames span {
  display: block;
  line-height: initial;
}
.header .menu-drop-user .dropdown-item {
  color: #637381;
  padding: 7px 10px !important;
}
.header .menu-drop-user .dropdown-item svg {
  stroke-width: 1px;
  margin-right: 10px;
  width: 18px;
}
.header .menu-drop-user .logout {
  color: #f62d51;
}
@media (max-width: 991.98px) {
  .header .has-arrow .dropdown-toggle > span:nth-child(2) {
    display: none;
  }
  .header .has-arrow .dropdown-toggle:after {
    display: none !important;
  }
}
.header .profilename .user-img .status {
  bottom: 10px;
}
.header .user-img {
  display: inline-block;
  position: relative;
}
.header .user-img img {
  width: 38px;
  border-radius: 50%;
}
.header .user-img .status {
  border: 2px solid #fff;
  height: 10px;
  width: 10px;
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 30px;
  border-radius: 50%;
  display: inline-block;
  background: #28c76f;
}
.header .mobile-user-menu {
  color: #fff;
  display: none;
  float: right;
  font-size: 24px;
  height: 60px;
  line-height: 60px;
  text-align: right;
  width: 60px;
  z-index: 10;
  padding: 0 20px;
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 991.98px) {
  .header .mobile-user-menu {
    display: block;
  }
}
.header .mobile-user-menu a {
  color: #ff9f43;
  padding: 0;
}
.header .mobile-user-menu a::after {
  display: none;
}
.header .mobile-user-menu .dropdown-menu {
  padding: 0;
}
.header .mobile-user-menu .dropdown-menu a {
  color: #333;
  line-height: 40px;
  padding: 0 10px;
}

.logo-small img {
  width: 30px;
}

@media (min-width: 992px) {
  .mini-sidebar.expand-menu .header-left {
    flex-direction: row;
    justify-content: space-between;
  }
  .mini-sidebar .header-left {
    width: 80px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .mini-sidebar .header-left .logo-small {
    display: block;
    padding: 20px 0 0;
  }
  .mini-sidebar .header-left .logo {
    display: none;
  }
  .mini-sidebar .menu-arrow {
    display: none !important;
  }
  .mini-sidebar .chat-user {
    display: none !important;
  }
  .mini-sidebar .badge {
    display: none !important;
  }
  .mini-sidebar .user-menu .badge {
    display: flex !important;
  }
  .mini-sidebar.expand-menu .menu-arrow {
    display: block !important;
  }
  .mini-sidebar.expand-menu .chat-user {
    display: block !important;
  }
  .mini-sidebar.expand-menu .logo-small {
    display: none;
  }
  .mini-sidebar.expand-menu .logo {
    display: block;
  }
  .mini-sidebar.expand-menu #toggle_btn {
    opacity: 1;
  }
  .mini-sidebar.expand-menu .header-left {
    padding: 0 20px;
    display: flex;
  }
  .mini-sidebar .noti-dot:before {
    display: none;
  }
}
.header-pos.header {
  position: relative;
}

.searchpart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background: #fff;
  z-index: 100000;
  width: 350px;
  margin-right: -350px;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-box-shadow: 0 0px 7px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0px 7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0px 7px rgba(0, 0, 0, 0.2);
}
.searchpart.active {
  margin-right: 0;
}
.searchpart .searchcontent {
  padding: 20px;
  display: block;
  height: 100%;
}
.searchpart .searchhead {
  border-bottom: 1px solid #b8bcc9;
  color: #333;
}
.searchpart .searchcontents {
  padding: 25px 0;
}
.searchpart .searchparts {
  position: relative;
}
.searchpart .searchparts input[type="text"] {
  width: 100%;
  height: 50px;
  border: 1px solid #b8bcc9;
  padding: 0 15px;
  border-radius: 50px;
}
.searchpart .searchparts .btn-searchs {
  position: absolute;
  top: 5px;
  right: 10px;
  background: #ff9f43;
  border-radius: 50px;
  color: #fff;
}
.searchpart .recentsearch {
  margin-top: 15px;
  border-top: 1px solid #9f9f9f;
  padding-top: 15px;
}
.searchpart .recentsearch h2 {
  font-size: 16px;
  margin-bottom: 25px;
  color: #333;
  font-weight: 700;
}
.searchpart .recentsearch li {
  border-bottom: 1px solid #9f9f9f;
  padding: 10px;
}
.searchpart .recentsearch li h6 {
  font-size: 14px;
}
.searchpart .searchhead h3 {
  font-weight: 600;
  font-size: 20px;
}

.header-pos {
  padding: 10px;
  background: #fff;
  margin-bottom: 20px;
  border-bottom: 1px solid #e8ebed;
  height: 80px;
}
@media (max-width: 991.98px) {
  .header-pos {
    padding: 10px;
    height: auto;
  }
}
.header-pos #mobile_btn {
  display: none;
  float: left;
}
@media (max-width: 991.98px) {
  .header-pos #mobile_btn {
    color: #ff9f43;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    width: 60px;
    z-index: 10;
    padding: 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
}

.header-pos.header .topnav-dropdown-header {
  height: 40px;
  padding: 10px;
}
.header-pos.header .topnav-dropdown-header .clear-noti {
  color: #ea5455 !important;
  float: right;
  font-size: 11px !important;
  text-transform: uppercase;
}
.header-pos.header .topnav-dropdown-footer {
  border-top: 1px solid #eeeeee;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
}
.header-pos.header .topnav-dropdown-footer a {
  display: block;
  text-align: center;
  color: #333;
  padding: 5px 12px;
  font-size: 12px !important;
}
.header-pos.header .user-menu.nav li {
  height: 40px;
}
.header-pos.header .user-menu.nav li a {
  color: #212b36;
  line-height: normal;
  height: auto;
  font-size: 15px;
  padding: 0 8px;
}
.header-pos.header .user-menu.nav li a.dropdown-toggle.nav-link.show {
  background: transparent;
}
.header-pos.header .user-menu.nav li.notification-message {
  height: auto;
}
.header-pos.header .user-img img {
  margin-top: 0;
}
.header-pos.header .user-img .status {
  border: 2px solid #fff;
  height: 10px;
  width: 10px;
  margin: 0;
  border-radius: 50%;
  display: inline-block;
  background: #28c76f;
  position: absolute;
  bottom: 6px;
  left: 0;
}

@media (max-width: 991.98px) {
  .header-pos.header .user-menu {
    display: none;
  }
}
.header-pos.header .user-menu .noti-content .notification-list a {
  line-height: normal;
  height: auto;
  padding: 8px;
}

@media (max-width: 991.98px) {
  .header-split {
    justify-content: center;
  }
}
@media (max-width: 991.98px) {
  .header-split .header-menu {
    display: none;
  }
}
.header-split .header-menu ul li {
  margin-right: 10px;
}
.header-split .header-menu ul li a {
  border: 1px solid #e0e0e0;
  color: #000;
  border-radius: 5px;
  padding: 5px 8px;
}
.header-split .header-menu ul li a.links {
  border: 0;
}
.header-split .header-menu ul li a:hover {
  background: #ff9f43;
  color: #fff;
}
.header-split .header-menu ul li a:hover img {
  filter: brightness(0) invert(1);
}
.header-split .header-menu ul li.mobile-show {
  display: block;
}
@media (max-width: 991.98px) {
  .header-split .header-menu ul li.mobile-show {
    display: block;
  }
}

.mobile-show {
  display: none;
}
@media (max-width: 991.98px) {
  .mobile-show {
    display: block;
  }
}

.header-menus {
  position: absolute;
  right: 30px;
  display: none;
}
@media (max-width: 991.98px) {
  .header-menus {
    display: block;
  }
}

.bar-icon {
  display: inline-block;
  width: 31px;
  margin-top: 20px;
}
.bar-icon span {
  background-color: #ff9f43;
  display: block;
  float: left;
  height: 3px;
  margin-bottom: 7px;
  width: 31px;
  border-radius: 2px;
  transition: all 0.5s;
}
.bar-icon span:nth-child(2) {
  width: 16px;
}

.menu-opened .slide-nav .bar-icon {
  display: inline-block;
  width: 31px;
  margin-top: 20px;
}
.menu-opened .slide-nav .bar-icon span:first-child {
  position: relative;
  top: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}
.menu-opened .slide-nav .bar-icon span:nth-child(2) {
  display: none;
}
.menu-opened .slide-nav .bar-icon span:last-child {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
}

hr {
  background-color: #e8ebed;
  opacity: 1;
}

.profilename {
  padding: 0px;
}
.profilename .profileset {
  padding: 10px 10px 3px;
}
.profilename .profileset h6 {
  font-size: 14px;
  color: #212b36;
  font-weight: 500;
}
.profilename .profileset h5 {
  font-size: 14px;
  color: #637381;
}
.profilename .profileset .profilesets {
  margin-left: 10px;
}
.profilename .profileset .user-img img {
  margin-top: 0;
}
.profilename .dropdown-item {
  padding: 8px 5px !important;
}

.show .user-info::after {
  transform: rotate(-140deg);
  top: 10px;
}

.feather-14 {
  width: 14px;
  height: 14px;
}

.feather-16 {
  width: 16px;
}

.user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 30px 0 0;
}
.user-info::after {
  content: "";
  width: 8px;
  height: 8px;
  border-top: 0;
  border-left: 0;
  border-right: 2px solid #1b2950;
  border-bottom: 2px solid #1b2950;
  border-radius: 1px;
  transform: rotate(45deg);
  transition: all 0.5s;
  top: 8px;
  position: absolute;
  right: 12px;
}
.user-info .user-role {
  display: block;
  color: #67748e;
  font-size: 12px;
}
.user-info .user-letter {
  background: #1b2950;
  color: #fff;
  width: 42px;
  height: 42px;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  margin-right: 10px;
}
.user-info .user-name {
  font-size: 15px;
  font-weight: 600;
  color: #1b2950;
  display: flex;
  align-items: center;
}
.user-info .user-name img {
  margin-left: 10px;
}

.user-menu {
  margin: 0;
  position: relative;
  padding-right: 15px;
  align-items: center;
  justify-content: center;
  height: 100%;
}
@media (max-width: 991.98px) {
  .user-menu {
    display: none;
  }
}
.user-menu > li {
  margin-left: 18px;
}
.user-menu > li svg {
  width: 18px;
  color: #67748e;
}
.user-menu > li a:hover svg {
  color: #ff9f43;
}
.user-menu .dropdown-toggle::after {
  border: 0;
  display: none;
}
.user-menu .nav-searchinputs {
  margin-right: auto;
}
.user-menu .nav-item-box > a {
  background: #f6f7f8;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.user-menu .nav-item-box > a:hover {
  background: #eef3f5;
}
.user-menu.nav > li > a {
  padding: 0;
  display: flex;
  line-height: normal;
  color: #212b36;
}
.user-menu.nav > li > a.dropdown-toggle.nav-link.show {
  background: #eeeeee;
}
.user-menu.nav > li > a .badge {
  color: #fff;
  font-weight: 700;
  position: absolute;
  top: -7px;
  right: -7px;
  background: #ff9f43;
  width: 20px;
  height: 20px;
  font-size: 10px;
  border: 2px solid #fff;
}
.user-menu.nav > li > a i {
  font-size: 20px;
  line-height: 60px;
}
.user-menu.nav > li.flag-nav a .dropdown-menu {
  max-height: 150px;
  min-width: 120px;
  overflow-y: auto;
}
.user-menu.nav > li.flag-nav a .dropdown-menu .dropdown-item {
  padding: 5px 15px;
}
.user-menu.nav > li.flag-nav .dropdown-menu {
  padding: 15px;
  min-width: 210px;
}
.user-menu.nav > li.flag-nav .dropdown-menu .dropdown-item {
  padding: 10px 10px;
  color: #67748e;
  border-radius: 10px;
  transition: none;
}
.user-menu.nav > li.flag-nav .dropdown-menu .dropdown-item.active,
.user-menu.nav > li.flag-nav .dropdown-menu .dropdown-item:hover {
  /* background: #F7F7F7 url(../img/icons/check.svg) no-repeat calc(100% - 12px) center; */
}
.user-menu.nav > li.flag-nav .dropdown-menu a img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.searchinputs {
  padding: 0 15px;
  position: relative;
}
.searchinputs input {
  width: 230px;
  height: 35px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: #f6f6f7;
  padding: 0 8px 0 40px;
  border-radius: 50px;
}
.searchinputs .search-addon span {
  width: 27px;
  height: 27px;
  z-index: 9;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 4px;
  left: 23px;
}

.page-wrapper-one {
  margin-left: 0px;
  padding-top: 130px;
}

.header-one .header-left {
  border-right: 0 !important;
  padding: 25px;
}

.logo-white {
  display: none !important;
}

.sidebar.new-header {
  position: fixed;
  width: 100%;
  height: 68px;
  background: #fff;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
  z-index: 9;
}
.sidebar.new-header .sidebar-menu {
  padding: 0 15px;
}
.sidebar.new-header .sidebar-menu > ul > li {
  margin-bottom: 0;
  margin-right: 20px;
}
.sidebar.new-header .sidebar-menu > ul > li > a {
  padding: 22px 15px;
}
.sidebar.new-header .sidebar-menu > ul > li > a:hover {
  background: transparent;
}
.sidebar.new-header .sidebar-menu > ul > li > a:hover span {
  color: #ff9f43;
}
.sidebar.new-header .sidebar-menu > ul > li > a:hover img,
.sidebar.new-header .sidebar-menu > ul > li > a:hover svg {
  filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%)
    hue-rotate(326deg) brightness(105%) contrast(112%);
}
.sidebar.new-header .sidebar-menu > ul > li > a img {
  top: 0 !important;
  width: auto;
}
.sidebar.new-header .sidebar-menu > ul > li a {
  font-weight: 500;
  font-size: 15px;
  color: #637381;
}
.sidebar.new-header .sidebar-menu > ul > li a img {
  position: relative;
  top: 3px;
  width: 18px;
  margin-right: 7px;
}
.sidebar.new-header .sidebar-menu > ul > li a svg {
  width: 18px;
  margin-right: 7px;
}
.sidebar.new-header .sidebar-menu > ul > li .menu-arrow {
  position: initial;
  transform: rotate(90deg);
  margin-left: 15px;
}
.sidebar.new-header .sidebar-menu > ul > li ul {
  position: absolute;
  width: 250px;
  height: auto;
}
.sidebar.new-header .sidebar-menu > ul > li ul li.submenu a {
  padding: 8px 15px !important;
}
.sidebar.new-header .sidebar-menu > ul > li ul li.submenu a .menu-arrow {
  float: right;
  margin-right: 15px;
}
.sidebar.new-header .sidebar-menu > ul > li ul li.submenu a i {
  margin-right: 7px;
}
.sidebar.new-header .sidebar-menu > ul > li ul li.submenu a img {
  position: relative;
  top: 3px;
  width: 18px;
  margin-right: 7px;
}
.sidebar.new-header .sidebar-menu > ul > li ul li.submenu a svg {
  width: 18px;
  margin-right: 7px;
}
.sidebar.new-header .sidebar-menu > ul > li ul li.submenu ul {
  box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
}
.sidebar.new-header .sidebar-menu > ul > li ul li.submenu ul {
  position: relative;
  padding: 0;
}
.sidebar.new-header .sidebar-menu > ul > li ul li.submenu ul li a {
  padding: 8px 10px 8px 40px !important;
}
.sidebar.new-header .sidebar-menu > ul > li ul li a {
  padding: 8px 15px !important;
}
.sidebar.new-header .sidebar-menu > ul > li ul li a::after {
  content: none !important;
}
@media (max-width: 1199.98px) {
  .sidebar.new-header .sidebar-menu > ul > li {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .hide-sidebar {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .new-header {
    display: none;
  }
  .page-wrapper-one {
    padding-top: 60px;
  }
}
.header-two .header-left {
  border: 0 !important;
  padding: 0 10px;
}
.header-two .top-nav-search {
  float: left;
  margin-left: 10px;
}
.header-two .top-nav-search .btn {
  left: 0;
  right: auto !important;
}
.header-two .top-nav-search .searchinputs input {
  z-index: 1;
}

.sidebar.sidebar-two {
  left: auto;
  top: 90px;
  border: 0 !important;
}
.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a {
  display: flex !important;
}
.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a img {
  width: 18px;
}
.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a span {
  margin-left: 10px;
}
.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a::after {
  content: none;
}
.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a .menu-arrow {
  top: 10px;
}
.sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li ul li a {
  padding: 8px 8px 8px 64px;
}
.sidebar.sidebar-two .sidebar-menu .menu-arrow {
  position: absolute;
  top: 12px;
  right: 15px;
}

.page-wrapper-two .dash-widget .dash-widgetcontent {
  margin-left: 10px;
}

@media (max-width: 991.98px) {
  .sidebar.sidebar-two {
    left: 0;
    top: 60px;
  }
}
@media (max-width: 991.98px) {
  .header-block {
    display: block !important;
  }
}

.page-wrapper-three {
  margin-left: 0;
  padding-top: 0;
}
.page-wrapper-three .content {
  background: #fafbfe;
  border-radius: 20px;
  margin-top: -82px;
}

.header-three {
  position: relative;
}
.header-three .header-left {
  border-right: 0 !important;
  padding: 25px;
}

.logo-white {
  display: none !important;
}

.sidebar.side-three.new-header {
  position: relative;
  top: 0;
  width: 100%;
  height: 150px;
  background: #1b2950;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
  z-index: unset;
}
.sidebar.side-three.new-header .sidebar-menu {
  padding: 0 15px;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li {
  margin-bottom: 0;
  margin-right: 20px;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li > a {
  padding: 22px 15px;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li > a img,
.sidebar.side-three.new-header .sidebar-menu > ul > li > a svg {
  filter: brightness(0) invert(1);
  margin-right: 0;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li > a:hover {
  background: transparent;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li > a:hover span {
  color: #ff9f43 !important;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li > a:hover img,
.sidebar.side-three.new-header .sidebar-menu > ul > li > a:hover svg {
  filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%)
    hue-rotate(326deg) brightness(105%) contrast(112%);
}
.sidebar.side-three.new-header .sidebar-menu > ul > li > a img {
  top: 0 !important;
  width: auto;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li > a::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  width: 1px;
  height: 15px;
  background: #6b7a99;
  transform: translateY(-50%);
}
.sidebar.side-three.new-header .sidebar-menu > ul > li > a span {
  color: #fff !important;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li a {
  font-weight: 500;
  font-size: 15px;
  color: #fff;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li a img {
  position: relative;
  top: 3px;
  width: 18px;
  margin-right: 7px;
  filter: brightness(0) invert(1);
}
.sidebar.side-three.new-header .sidebar-menu > ul > li a svg {
  width: 18px;
  margin-right: 7px;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li a:hover img,
.sidebar.side-three.new-header .sidebar-menu > ul > li a:hover svg {
  filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%)
    hue-rotate(326deg) brightness(105%) contrast(112%);
}
.sidebar.side-three.new-header .sidebar-menu > ul > li .menu-arrow {
  position: initial;
  transform: rotate(90deg);
  margin-left: 15px;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li ul {
  position: absolute;
  width: 250px;
  height: auto;
  z-index: 1;
  background: #1b2950;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a {
  padding: 8px 15px !important;
  color: #fff;
}
.sidebar.side-three.new-header
  .sidebar-menu
  > ul
  > li
  ul
  li.submenu
  a
  .menu-arrow {
  float: right;
  margin-right: 15px;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a i {
  margin-right: 7px;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a img {
  position: relative;
  top: 3px;
  width: 18px;
  margin-right: 7px;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a svg {
  width: 18px;
  margin-right: 7px;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a:hover {
  color: #ff9f43;
}
.sidebar.side-three.new-header
  .sidebar-menu
  > ul
  > li
  ul
  li.submenu
  a:hover
  img {
  filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%)
    hue-rotate(326deg) brightness(105%) contrast(112%);
}
.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu ul {
  box-shadow: none;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu ul {
  position: relative;
  padding: 0;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu ul li a {
  padding: 8px 10px 8px 40px !important;
  color: #fff;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li ul li a {
  padding: 8px 15px !important;
}
.sidebar.side-three.new-header .sidebar-menu > ul > li ul li a::after {
  content: none !important;
}
@media (max-width: 1199.98px) {
  .sidebar.side-three.new-header .sidebar-menu > ul > li {
    margin-right: 0;
  }
}
.sidebar.side-three.new-header .sidebar-menu > ul > li:last-child > a::before {
  content: none;
}

@media (min-width: 992px) {
  .hide-sidebar {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .header-three {
    position: fixed;
  }
  .new-header {
    display: none;
  }
  .page-wrapper-three {
    padding-top: 60px;
  }
  .page-wrapper-three .content {
    margin-top: 0;
  }
}
.sidebar.sidebar-four {
  border: 0;
  box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
}
.sidebar.sidebar-four .sidebar-menu {
  border-radius: 0 20px 20px 0;
}
.sidebar.sidebar-four .sidebar-menu .ui-aside {
  float: left;
  width: 100%;
  max-width: 66px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.299s;
  -ms-transition: all 0.299s;
  transition: all 0.299s;
  margin: 0;
  padding: 0;
  box-shadow: 0px 0 14px rgba(198, 198, 198, 0.25);
  min-height: 100vh;
}
.sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs {
  padding: 0 19px;
  border: 0;
}
.sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link {
  padding: 20px 5px;
  border-bottom: 1px solid #d9d9d9;
  border-width: 0 0 1px;
}
.sidebar.sidebar-four
  .sidebar-menu
  .ui-aside
  .nav-tabs
  .nav-item
  .nav-link:hover {
  border-width: 0 0 1px;
  border-bottom: 1px solid #d9d9d9;
}
.sidebar.sidebar-four
  .sidebar-menu
  .ui-aside
  .nav-tabs
  .nav-item
  .nav-link:active,
.sidebar.sidebar-four
  .sidebar-menu
  .ui-aside
  .nav-tabs
  .nav-item
  .nav-link.active {
  border-color: #fff #fff #d9d9d9;
}
.sidebar.sidebar-four
  .sidebar-menu
  .ui-aside
  .nav-tabs
  .nav-item
  .nav-link:active
  img,
.sidebar.sidebar-four
  .sidebar-menu
  .ui-aside
  .nav-tabs
  .nav-item
  .nav-link.active
  img {
  filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%)
    hue-rotate(326deg) brightness(105%) contrast(112%);
}
.sidebar.sidebar-four
  .sidebar-menu
  .ui-aside
  .nav-tabs
  .nav-item
  .nav-link:active::after,
.sidebar.sidebar-four
  .sidebar-menu
  .ui-aside
  .nav-tabs
  .nav-item
  .nav-link.active::after {
  content: none;
}
.sidebar.sidebar-four
  .sidebar-menu
  .ui-aside
  .nav-tabs
  .nav-item
  .nav-link::before {
  content: none;
}
.sidebar.sidebar-four
  .sidebar-menu
  .ui-aside
  .nav-tabs
  .nav-item:last-child
  .nav-link {
  border-bottom: 0;
}
.sidebar.sidebar-four .tab-content-four {
  padding-top: 20px;
  margin-left: 10px;
  width: 100%;
}
.sidebar.sidebar-four ul ul {
  display: none;
}
.sidebar.sidebar-four .sidebar-menu {
  padding: 0 20px 0 0;
}
.sidebar.sidebar-four .sidebar-menu ul li a {
  padding: 10px 15px 10px 25px;
  position: relative;
  color: #637381;
}
.sidebar.sidebar-four .sidebar-menu ul li a svg {
  width: 18px;
  color: #637381;
}
.sidebar.sidebar-four .sidebar-menu ul li a img {
  width: 18px;
  color: #637381;
}
.sidebar.sidebar-four .sidebar-menu ul li a:hover {
  color: #ff9f43;
}
.sidebar.sidebar-four .sidebar-menu ul li a:hover img {
  filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%)
    hue-rotate(326deg) brightness(105%) contrast(112%);
}
.sidebar.sidebar-four .sidebar-menu ul li a:hover span {
  color: #ff9f43;
}
.sidebar.sidebar-four .sidebar-menu ul li a:hover svg {
  color: #fff;
}
.sidebar.sidebar-four .sidebar-menu ul li a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background: #c8c8c8;
  width: 5px;
  height: 5px;
}
.sidebar.sidebar-four .sidebar-menu ul li a.active {
  color: #ff9f43;
}
.sidebar.sidebar-four .sidebar-menu ul li a.active svg {
  color: #fff;
}
.sidebar.sidebar-four .sidebar-menu ul li a.active img {
  filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%)
    hue-rotate(326deg) brightness(105%) contrast(112%);
}
.sidebar.sidebar-four .sidebar-menu ul li a.active span {
  color: #fff;
}
.sidebar.sidebar-four .sidebar-menu ul li a span {
  margin-left: 0;
  font-size: 15px;
  font-weight: 500;
  color: #637381;
}
.sidebar.sidebar-four .sidebar-menu ul li a .menu-arrow {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  text-rendering: auto;
  line-height: 40px;
  font-size: 18px;
  line-height: 18px;
  position: absolute;
  top: 12px;
  right: 15px;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.sidebar.sidebar-four .sidebar-menu ul li a.subdrop .menu-arrow {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}
.sidebar.sidebar-four .sidebar-menu ul li ul li a {
  padding: 10px 15px 10px 25px;
  left: 14px;
}

.header-four .header-left {
  border-right: 0 !important;
}

.page-wrapper-four {
  margin-left: 250px;
}

@media (max-width: 992px) {
  .page-wrapper-four {
    margin-left: 0;
  }
}
div#global-loader {
  background: #fff;
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100vh;
}
div#global-loader .whirly-loader:not(:required) {
  animation: whirly-loader 1.25s linear infinite;
  background: transparent;
  box-shadow: 0 26px 0 6px #ff9f43, 0.90971px 26.05079px 0 5.93333px #ff9f43,
    1.82297px 26.06967px 0 5.86667px #ff9f43,
    2.73865px 26.05647px 0 5.8px #ff9f43,
    3.65561px 26.01104px 0 5.73333px #ff9f43,
    4.57274px 25.93327px 0 5.66667px #ff9f43,
    5.48887px 25.8231px 0 5.6px #ff9f43,
    6.40287px 25.68049px 0 5.53333px #ff9f43,
    7.31358px 25.50548px 0 5.46667px #ff9f43,
    8.21985px 25.2981px 0 5.4px #ff9f43,
    9.12054px 25.05847px 0 5.33333px #ff9f43,
    10.01448px 24.78672px 0 5.26667px #ff9f43,
    10.90054px 24.48302px 0 5.2px #ff9f43,
    11.77757px 24.1476px 0 5.13333px #ff9f43,
    12.64443px 23.78072px 0 5.06667px #ff9f43, 13.5px 23.38269px 0 5px #ff9f43,
    14.34315px 22.95384px 0 4.93333px #ff9f43,
    15.17277px 22.49455px 0 4.86667px #ff9f43,
    15.98776px 22.00526px 0 4.8px #ff9f43,
    16.78704px 21.48643px 0 4.73333px #ff9f43,
    17.56953px 20.93855px 0 4.66667px #ff9f43,
    18.33418px 20.36217px 0 4.6px #ff9f43,
    19.07995px 19.75787px 0 4.53333px #ff9f43,
    19.80582px 19.12626px 0 4.46667px #ff9f43,
    20.5108px 18.468px 0 4.4px #ff9f43, 21.1939px 17.78379px 0 4.33333px #ff9f43,
    21.85416px 17.07434px 0 4.26667px #ff9f43,
    22.49067px 16.34043px 0 4.2px #ff9f43,
    23.10251px 15.58284px 0 4.13333px #ff9f43,
    23.68881px 14.80241px 0 4.06667px #ff9f43, 24.24871px 14px 0 4px #ff9f43,
    24.7814px 13.1765px 0 3.93333px #ff9f43,
    25.28607px 12.33284px 0 3.86667px #ff9f43,
    25.76198px 11.46997px 0 3.8px #ff9f43,
    26.2084px 10.58888px 0 3.73333px #ff9f43,
    26.62462px 9.69057px 0 3.66667px #ff9f43,
    27.01001px 8.77608px 0 3.6px #ff9f43,
    27.36392px 7.84648px 0 3.53333px #ff9f43,
    27.68577px 6.90284px 0 3.46667px #ff9f43,
    27.97502px 5.94627px 0 3.4px #ff9f43,
    28.23116px 4.97791px 0 3.33333px #ff9f43,
    28.4537px 3.99891px 0 3.26667px #ff9f43,
    28.64223px 3.01042px 0 3.2px #ff9f43,
    28.79635px 2.01364px 0 3.13333px #ff9f43,
    28.91571px 1.00976px 0 3.06667px #ff9f43, 29px 0 0 3px #ff9f43,
    29.04896px -1.01441px 0 2.93333px #ff9f43,
    29.06237px -2.03224px 0 2.86667px #ff9f43,
    29.04004px -3.05223px 0 2.8px #ff9f43,
    28.98185px -4.07313px 0 2.73333px #ff9f43,
    28.88769px -5.09368px 0 2.66667px #ff9f43,
    28.75754px -6.1126px 0 2.6px #ff9f43,
    28.59138px -7.12863px 0 2.53333px #ff9f43,
    28.38926px -8.14049px 0 2.46667px #ff9f43,
    28.15127px -9.1469px 0 2.4px #ff9f43,
    27.87755px -10.1466px 0 2.33333px #ff9f43,
    27.56827px -11.1383px 0 2.26667px #ff9f43,
    27.22365px -12.12075px 0 2.2px #ff9f43,
    26.84398px -13.09268px 0 2.13333px #ff9f43,
    26.42956px -14.05285px 0 2.06667px #ff9f43, 25.98076px -15px 0 2px #ff9f43,
    25.49798px -15.93291px 0 1.93333px #ff9f43,
    24.98167px -16.85035px 0 1.86667px #ff9f43,
    24.43231px -17.75111px 0 1.8px #ff9f43,
    23.85046px -18.63402px 0 1.73333px #ff9f43,
    23.23668px -19.49789px 0 1.66667px #ff9f43,
    22.5916px -20.34157px 0 1.6px #ff9f43,
    21.91589px -21.16393px 0 1.53333px #ff9f43,
    21.21024px -21.96384px 0 1.46667px #ff9f43,
    20.4754px -22.74023px 0 1.4px #ff9f43,
    19.71215px -23.49203px 0 1.33333px #ff9f43,
    18.92133px -24.2182px 0 1.26667px #ff9f43,
    18.10379px -24.91772px 0 1.2px #ff9f43,
    17.26042px -25.58963px 0 1.13333px #ff9f43,
    16.39217px -26.23295px 0 1.06667px #ff9f43, 15.5px -26.84679px 0 1px #ff9f43,
    14.58492px -27.43024px 0 0.93333px #ff9f43,
    13.64796px -27.98245px 0 0.86667px #ff9f43,
    12.69018px -28.50262px 0 0.8px #ff9f43,
    11.7127px -28.98995px 0 0.73333px #ff9f43,
    10.71663px -29.4437px 0 0.66667px #ff9f43,
    9.70313px -29.86317px 0 0.6px #ff9f43,
    8.67339px -30.2477px 0 0.53333px #ff9f43,
    7.6286px -30.59666px 0 0.46667px #ff9f43,
    6.57001px -30.90946px 0 0.4px #ff9f43,
    5.49886px -31.18558px 0 0.33333px #ff9f43,
    4.41643px -31.42451px 0 0.26667px #ff9f43,
    3.32401px -31.6258px 0 0.2px #ff9f43,
    2.22291px -31.78904px 0 0.13333px #ff9f43,
    1.11446px -31.91388px 0 0.06667px #ff9f43, 0 -32px 0 0 #ff9f43,
    -1.11911px -32.04713px 0 -0.06667px #ff9f43,
    -2.24151px -32.05506px 0 -0.13333px #ff9f43,
    -3.36582px -32.02361px 0 -0.2px #ff9f43,
    -4.49065px -31.95265px 0 -0.26667px #ff9f43,
    -5.61462px -31.84212px 0 -0.33333px #ff9f43,
    -6.73634px -31.69198px 0 -0.4px #ff9f43,
    -7.8544px -31.50227px 0 -0.46667px #ff9f43,
    -8.9674px -31.27305px 0 -0.53333px #ff9f43,
    -10.07395px -31.00444px 0 -0.6px #ff9f43,
    -11.17266px -30.69663px 0 -0.66667px #ff9f43,
    -12.26212px -30.34982px 0 -0.73333px #ff9f43,
    -13.34096px -29.96429px 0 -0.8px #ff9f43,
    -14.4078px -29.54036px 0 -0.86667px #ff9f43,
    -15.46126px -29.07841px 0 -0.93333px #ff9f43,
    -16.5px -28.57884px 0 -1px #ff9f43,
    -17.52266px -28.04212px 0 -1.06667px #ff9f43,
    -18.52792px -27.46878px 0 -1.13333px #ff9f43,
    -19.51447px -26.85936px 0 -1.2px #ff9f43,
    -20.48101px -26.21449px 0 -1.26667px #ff9f43,
    -21.42625px -25.53481px 0 -1.33333px #ff9f43,
    -22.34896px -24.82104px 0 -1.4px #ff9f43,
    -23.2479px -24.07391px 0 -1.46667px #ff9f43,
    -24.12186px -23.29421px 0 -1.53333px #ff9f43,
    -24.96967px -22.48279px 0 -1.6px #ff9f43,
    -25.79016px -21.64052px 0 -1.66667px #ff9f43,
    -26.58223px -20.76831px 0 -1.73333px #ff9f43,
    -27.34477px -19.86714px 0 -1.8px #ff9f43,
    -28.07674px -18.938px 0 -1.86667px #ff9f43,
    -28.7771px -17.98193px 0 -1.93333px #ff9f43,
    -29.44486px -17px 0 -2px #ff9f43,
    -30.07908px -15.99333px 0 -2.06667px #ff9f43,
    -30.67884px -14.96307px 0 -2.13333px #ff9f43,
    -31.24325px -13.91039px 0 -2.2px #ff9f43,
    -31.7715px -12.83652px 0 -2.26667px #ff9f43,
    -32.26278px -11.74269px 0 -2.33333px #ff9f43,
    -32.71634px -10.63018px 0 -2.4px #ff9f43,
    -33.13149px -9.5003px 0 -2.46667px #ff9f43,
    -33.50755px -8.35437px 0 -2.53333px #ff9f43,
    -33.84391px -7.19374px 0 -2.6px #ff9f43,
    -34.14px -6.0198px 0 -2.66667px #ff9f43,
    -34.39531px -4.83395px 0 -2.73333px #ff9f43,
    -34.60936px -3.63759px 0 -2.8px #ff9f43,
    -34.78173px -2.43218px 0 -2.86667px #ff9f43,
    -34.91205px -1.21916px 0 -2.93333px #ff9f43, -35px 0 0 -3px #ff9f43,
    -35.04531px 1.22381px 0 -3.06667px #ff9f43,
    -35.04775px 2.45078px 0 -3.13333px #ff9f43,
    -35.00717px 3.6794px 0 -3.2px #ff9f43,
    -34.92345px 4.90817px 0 -3.26667px #ff9f43,
    -34.79654px 6.13557px 0 -3.33333px #ff9f43,
    -34.62643px 7.36007px 0 -3.4px #ff9f43,
    -34.41316px 8.58016px 0 -3.46667px #ff9f43,
    -34.15683px 9.79431px 0 -3.53333px #ff9f43,
    -33.85761px 11.001px 0 -3.6px #ff9f43,
    -33.5157px 12.19872px 0 -3.66667px #ff9f43,
    -33.13137px 13.38594px 0 -3.73333px #ff9f43,
    -32.70493px 14.56117px 0 -3.8px #ff9f43,
    -32.23675px 15.72291px 0 -3.86667px #ff9f43,
    -31.72725px 16.86968px 0 -3.93333px #ff9f43, -31.17691px 18px 0 -4px #ff9f43,
    -30.58627px 19.11242px 0 -4.06667px #ff9f43,
    -29.95589px 20.2055px 0 -4.13333px #ff9f43,
    -29.28642px 21.27783px 0 -4.2px #ff9f43,
    -28.57852px 22.32799px 0 -4.26667px #ff9f43,
    -27.83295px 23.35462px 0 -4.33333px #ff9f43,
    -27.05047px 24.35635px 0 -4.4px #ff9f43,
    -26.23192px 25.33188px 0 -4.46667px #ff9f43,
    -25.37819px 26.27988px 0 -4.53333px #ff9f43,
    -24.49018px 27.1991px 0 -4.6px #ff9f43,
    -23.56888px 28.0883px 0 -4.66667px #ff9f43,
    -22.6153px 28.94626px 0 -4.73333px #ff9f43,
    -21.6305px 29.77183px 0 -4.8px #ff9f43,
    -20.61558px 30.56385px 0 -4.86667px #ff9f43,
    -19.57168px 31.32124px 0 -4.93333px #ff9f43,
    -18.5px 32.04294px 0 -5px #ff9f43,
    -17.40175px 32.72792px 0 -5.06667px #ff9f43,
    -16.27818px 33.37522px 0 -5.13333px #ff9f43,
    -15.1306px 33.98389px 0 -5.2px #ff9f43,
    -13.96034px 34.55305px 0 -5.26667px #ff9f43,
    -12.76875px 35.08186px 0 -5.33333px #ff9f43,
    -11.55724px 35.56951px 0 -5.4px #ff9f43,
    -10.32721px 36.01527px 0 -5.46667px #ff9f43,
    -9.08014px 36.41843px 0 -5.53333px #ff9f43,
    -7.81748px 36.77835px 0 -5.6px #ff9f43,
    -6.54075px 37.09443px 0 -5.66667px #ff9f43,
    -5.25147px 37.36612px 0 -5.73333px #ff9f43,
    -3.95118px 37.59293px 0 -5.8px #ff9f43,
    -2.64145px 37.77443px 0 -5.86667px #ff9f43,
    -1.32385px 37.91023px 0 -5.93333px #ff9f43;
  display: inline-block;
  height: 8px;
  overflow: hidden;
  position: relative;
  text-indent: -9999px;
  width: 8px;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  border-radius: 100%;
}

@keyframes loader {
  to {
    left: -100vw;
  }
}
@-webkit-keyframes whirly-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes whirly-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.select2-container {
  min-width: 100% !important;
  width: auto !important;
  z-index: 99;
}
.select2-container .select2-selection--single {
  height: 40px;
}

.select2-container--default .select2-selection--single {
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 5px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #637381;
  line-height: 40px;
  border-radius: 5px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 40px;
  right: 6px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  position: absolute;
  top: 50%;
  left: 50%;
  border-color: #637381;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  height: 0;
  margin-left: -10px;
  margin-top: -3px;
  width: 0;
  transform: rotate(45deg) translateY(-50%);
  -webkit-transform: rotate(45deg) translateY(-50%);
  -ms-transform: rotate(45deg) translateY(-50%);
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #000;
  border-width: 2px 0 0 2px;
  margin-top: 3px;
  padding: 3px;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: #ff9f43;
  color: #fff;
}

.select2-container--focus .select2-selection--single {
  background: #fff !important;
  border-color: #ff9f43;
}

span.select2-container.select2-container--default.select2-container--open {
  z-index: 9999;
}

.sidebar {
  background-color: transparent;
  border-right: 1px solid transparent;
  margin-top: 0;
  z-index: 1001;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-right: 1px solid #e8ebed;
  width: 260px;
}
@media (max-width: 991.98px) {
  .sidebar {
    margin-left: -575px;
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1041;
    background: #fff;
  }
}
@media (max-width: 575px) {
  .sidebar {
    width: 100%;
  }
}
.sidebar .slimScrollDiv {
  width: 260px !important;
  overflow: visible !important;
  background: #fff;
}
@media (max-width: 575px) {
  .sidebar .slimScrollDiv {
    width: 100% !important;
  }
}
.sidebar .sidebar-menu {
  padding: 20px;
}
.sidebar .sidebar-menu > ul > li {
  margin-bottom: 10px;
}
.sidebar .sidebar-menu > ul > li.active a {
  color: #fff;
}
.sidebar .sidebar-menu > ul > li.active svg {
  color: #fe9f43;
}
.sidebar .sidebar-menu > ul > li svg {
  width: 18px;
  margin-right: 10px;
}
.sidebar .sidebar-menu > ul > li > a {
  padding: 10px 15px;
  position: relative;
  color: #212b36;
}
.sidebar .sidebar-menu > ul > li > a svg {
  color: #637381;
}
.sidebar .sidebar-menu > ul > li > a img {
  width: 18px;
  color: #637381;
}
.sidebar .sidebar-menu > ul > li > a:hover {
  background: rgba(254, 159, 67, 0.08);
  color: #fe9f43;
  border-radius: 5px;
}
.sidebar .sidebar-menu > ul > li > a:hover img {
  filter: invert(72%) sepia(76%) saturate(1430%) hue-rotate(327deg)
    brightness(103%) contrast(101%);
}
.sidebar .sidebar-menu > ul > li > a:hover span {
  color: #fe9f43;
}
.sidebar .sidebar-menu > ul > li > a:hover svg {
  color: #fe9f43;
}
.sidebar .sidebar-menu > ul > li > a.active {
  background: rgba(254, 159, 67, 0.08);
  color: #fe9f43;
  border-radius: 5px;
}
.sidebar .sidebar-menu > ul > li > a.active svg {
  color: #fff;
}
.sidebar .sidebar-menu > ul > li > a.active img {
  filter: invert(72%) sepia(76%) saturate(1430%) hue-rotate(327deg)
    brightness(103%) contrast(101%);
}
.sidebar .sidebar-menu > ul > li > a.active span {
  color: #fe9f43;
}
.sidebar .sidebar-menu > ul > li > a.active .menu-arrow {
  background: #ffeddc;
}
.sidebar .sidebar-menu > ul > li > a.active .menu-arrow::before {
  border-color: #fe9f43;
}
.sidebar .sidebar-menu > ul > li > a span {
  font-size: 15px;
  font-weight: 500;
  color: #637381;
}
.sidebar .sidebar-menu > ul > li.active a {
  background: rgba(254, 159, 67, 0.08);
  border-radius: 5px;
}
.sidebar .sidebar-menu > ul > li.active a img {
  filter: invert(72%) sepia(76%) saturate(1430%) hue-rotate(327deg)
    brightness(103%) contrast(101%);
}
.sidebar .sidebar-menu > ul > li.active a span {
  color: #fe9f43;
}
.sidebar .sidebar-menu > ul > li.submenu ul {
  border-bottom: 1px solid rgba(222, 226, 230, 0.5);
  padding: 10px 0;
  display: none;
}
.sidebar .sidebar-menu > ul > li.submenu ul li a {
  font-weight: 500;
  font-size: 14px;
  color: #212b36;
  position: relative;
  display: block;
  padding: 8px 8px 8px 40px;
}
.sidebar .sidebar-menu > ul > li.submenu ul li a.active {
  color: #092c4c;
}
.sidebar .sidebar-menu > ul > li.submenu ul li a.active:after {
  background: #fe9f43;
  border: 2px solid #fdb;
}
.sidebar .sidebar-menu > ul > li.submenu ul li a::after {
  content: "";
  background: rgba(50, 71, 92, 0.38);
  width: 8px;
  height: 8px;
  border: 2px solid #fff;
  border-radius: 50px;
  position: absolute;
  top: 16px;
  left: 16px;
}
.sidebar .sidebar-menu > ul > li.submenu ul li a:hover {
  color: #ff9f43;
}
.sidebar .sidebar-menu > ul > li.submenu ul li a:hover:after {
  background: #fe9f43;
  border: 2px solid #fdb;
}
.sidebar .sidebar-menu > ul > li.submenu-open ul {
  border-bottom: 1px solid rgba(222, 226, 230, 0.5);
  padding: 10px 0;
  display: block;
}
.sidebar .sidebar-menu > ul > li.submenu-open ul li a {
  font-weight: 500;
  font-size: 16px;
  color: #67748e;
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
}
.sidebar .sidebar-menu > ul > li.submenu-open ul li a.active {
  color: #ff9f43;
}
.sidebar .sidebar-menu > ul > li.submenu-open ul li a.active:after {
  background: #fe9f43;
  border: 2px solid #fdb;
}
.sidebar .sidebar-menu > ul > li.submenu-open ul li a:hover {
  color: #ff9f43;
}
.sidebar .sidebar-menu > ul > li.submenu-open ul li a:hover:after {
  background: #fe9f43;
  border: 2px solid #fdb;
}
.sidebar .sidebar-menu > ul > li.submenu-open ul > li.active a {
  background: rgba(254, 159, 67, 0.08);
  border-radius: 5px;
  color: #fe9f43;
}
.sidebar .sidebar-menu > ul > li.submenu-open .submenu-hdr {
  font-weight: 500;
  font-size: 14px;
  color: #1b2950;
  margin: 15px 0 0;
}
.sidebar .sidebar-menu > ul > li.submenu-open:first-child h6 {
  margin-top: 0;
}
.sidebar .sidebar-menu > ul > li.submenu-open .submenu ul {
  display: none;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.sidebar .sidebar-menu > ul li.active a {
  color: #fe9f43;
}
.sidebar .sidebar-menu > ul li.active svg {
  color: #fe9f43;
}
.sidebar .sidebar-menu > ul li .submenu > a.active {
  background: rgba(254, 159, 67, 0.08);
  color: #fe9f43;
  border-radius: 5px;
}
.sidebar .sidebar-menu > ul li .submenu > a.active span {
  color: #fe9f43;
}
.sidebar .sidebar-menu > ul li .submenu > a.active .menu-arrow {
  background: #ffeddc;
}
.sidebar .sidebar-menu > ul li .submenu > a.active .menu-arrow::before {
  border-color: #fe9f43;
}
.sidebar .sidebar-menu .submenu-open .submenu ul li a {
  margin-left: 25px;
}
.sidebar .sidebar-menu .submenu-open .submenu ul li a::after {
  content: "";
  background: rgba(50, 71, 92, 0.38);
  width: 8px;
  height: 8px;
  border: 2px solid #fff;
  border-radius: 50px;
  position: absolute;
  top: 16px;
  left: 0;
}
.sidebar .sidebar-menu .submenu-open .submenu ul li a:hover {
  color: #ff9f43;
}
.sidebar .sidebar-menu .submenu-open .submenu ul li a:hover:after {
  background: #fe9f43;
  border: 2px solid #fdb;
}
.sidebar .sidebar-menu .menu-arrow {
  display: flex;
  text-rendering: auto;
  line-height: 40px;
  font-size: 18px;
  line-height: 18px;
  background: rgba(27, 41, 80, 0.04);
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 15px;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.sidebar .sidebar-menu .menu-arrow::before {
  height: 5px;
  width: 5px;
  border-top: 0;
  content: "";
  border-left: 0;
  border-right: 1px solid #67748e;
  border-bottom: 1px solid #67748e;
  transform: rotate(-45deg);
  position: absolute;
  left: 5px;
  top: 5px;
}
.sidebar .sidebar-menu.subdrop .menu-arrow {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  background: #ffeddc;
}
.sidebar .nav-link {
  padding: 10px;
  color: #212b36;
  position: relative;
}
.sidebar .nav-link.active {
  color: #ff9f43;
  background: transparent;
  position: relative;
}
.sidebar .nav-link.active:after {
  content: "";
  border-width: 10px;
  border-color: transparent #ff9f43 transparent transparent;
  border-style: solid;
  position: absolute;
  top: 36px;
  right: 0;
}
.sidebar .sidebar-left {
  width: 100px;
  -webkit-box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
  box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
}
.sidebar .sidebar-right {
  width: 250px;
  height: 100%;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: none;
}
@media (max-width: 991.98px) {
  .sidebar .sidebar-right {
    width: calc(100% - 70px);
  }
}
.sidebar .sidebar-right .slimScrollDiv {
  width: 250px !important;
  overflow: visible !important;
}
@media (max-width: 991.98px) {
  .sidebar .sidebar-right .slimScrollDiv {
    width: 100% !important;
  }
}
.sidebar .sidebar-right .tab-content {
  padding: 20px 20px 40px;
}
@media (max-width: 991.98px) {
  .sidebar .sidebar-right .tab-content {
    padding: 10px;
  }
}
.sidebar .sidebar-right p {
  font-size: 10px;
  color: #9e9e9e;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
}
.sidebar .sidebar-right ul li.active a {
  background: linear-gradient(46.62deg, #ff9f43 0%, #423b93 93.64%);
  color: #fff;
}
.sidebar .sidebar-right ul li a {
  font-weight: 400;
  font-size: 14px;
  color: #212b36;
}
.sidebar .sidebar-right ul li a.active {
  color: #ff9f43;
}
.sidebar .sidebar-right .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  text-rendering: auto;
  line-height: 40px;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 18px;
  top: 0px;
  position: relative;
  font-weight: 700;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
}
.sidebar .sidebar-right .menu-arrow:before {
  content: "\f105";
}
.sidebar li a.subdrop .menu-arrow {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}
.sidebar .nav-items .nav-link {
  border-radius: 0;
  border-bottom: 1px solid #d8d8d8;
  padding: 20px 0;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.sidebar .nav-items .nav-link:hover {
  box-shadow: 0 0 0 150px #423b93 inset;
  color: #fff;
}
.sidebar .nav-items .nav-link span {
  margin-top: 15px;
  font-weight: 500;
}
.sidebar .sidemenu a {
  width: 100%;
  font-weight: 500;
  display: block;
  border-radius: 5px;
  margin: 0 0 10px;
  padding: 10px;
}
.sidebar .sidemenu a:hover {
  background: linear-gradient(46.62deg, #ff9f43 0%, #423b93 93.64%);
  color: #fff;
}
.sidebar .sidemenu a:hover svg {
  color: #fff;
}
.sidebar .sidemenu a svg {
  stroke-width: 1px;
}

.header-left #toggle_btn {
  position: absolute;
  opacity: 1;
  background: #ff9f43;
  right: -13px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  color: #fff;
  cursor: pointer;
}
.header-left #toggle_btn svg {
  transform: rotate(180deg);
}
.header-left #toggle_btn:hover {
  background: #fe820e;
}
.header-left.active svg {
  transform: rotate(0) !important;
}

@media (min-width: 991.98px) {
  .mini-sidebar .page-wrapper {
    margin-left: 80px;
  }
  .mini-sidebar .header-left #toggle_btn {
    opacity: 0;
  }
  .mini-sidebar .header-left #toggle_btn:after {
    border: 0;
  }
  .mini-sidebar.expand-menu .header-left #toggle_btn {
    opacity: 1;
  }
  .mini-sidebar .sidebar-right {
    display: none;
  }
  .mini-sidebar .sidebar .sidebar-menu ul li a span {
    display: none;
  }
  .mini-sidebar .sidebar .sidebar-menu ul li .submenu-hdr {
    display: none;
  }
  .mini-sidebar .sidebar .sidebar-menu ul li svg {
    margin-right: 0;
  }
  .mini-sidebar .sidebar {
    width: 80px;
    overflow: hidden;
  }
  .expand-menu .sidebar-right {
    display: block;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .expand-menu .sidebar {
    width: 260px;
  }
  .expand-menu .sidebar .sidebar-menu ul > li > a {
    padding: 10px 15px;
  }
  .expand-menu .sidebar .sidebar-menu ul > li > a span {
    display: inline-block;
    line-height: 0;
  }
  .expand-menu .sidebar .sidebar-menu ul > li svg {
    margin-right: 10px;
  }
  .expand-menu .sidebar .sidebar-menu .submenu-hdr {
    display: block;
  }
  .expand-menu .header-left {
    width: 260px;
  }
  #toggle_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    margin-left: 15px;
  }
}
.sidebarrightmenu .sidebar-right {
  display: block;
}

.slide-nav .sidebar {
  margin-left: 0;
}

.sidebar-overlay {
  display: none;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 60px;
  left: 0;
}
.sidebar-overlay.opened {
  display: block;
}

.nosidebar .sidebar {
  left: -350px;
}
.nosidebar .page-wrapper {
  margin: 0;
}
.nosidebar #toggle_btn svg {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.mini-sidebar .active.subdrop ~ ul {
  display: none !important;
}

.mini-sidebar.expand-menu .active.subdrop ~ ul {
  display: block !important;
}

.active.subdrop ~ ul {
  display: block !important;
}

.sidebar.new-header.sidebar-one .submenu ul {
  background: #fafbfe;
}

.tabs-set .nav-tabs {
  border-bottom: 0;
  margin-bottom: 20px;
}
.tabs-set .nav-tabs .nav-link {
  color: #212b36;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 700;
  border: 0;
  min-width: 120px;
  margin-right: 10px;
  background: #f8f8f8;
  border-radius: 5px !important;
}
.tabs-set .nav-tabs .nav-link:focus {
  border-color: transparent;
}
.tabs-set .nav-tabs .nav-link:hover {
  border-color: transparent;
}
.tabs-set .nav-tabs .nav-link.active {
  border: 0;
  border-bottom: 2px solid #ff9f43;
  background: #ff9f43;
  color: #fff;
}

.tabs-sets .nav-tabs {
  border-bottom: 0;
  margin: 0 0 30px;
}
.tabs-sets .nav-tabs .nav-link {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  border: 0;
  background: #e9ecef;
  min-width: 112px;
  margin-right: 15px;
  padding: 15px;
  border-radius: 5px;
}
.tabs-sets .nav-tabs .nav-link:focus {
  border-color: transparent;
}
.tabs-sets .nav-tabs .nav-link:hover {
  border-color: transparent;
}
.tabs-sets .nav-tabs .nav-link.active {
  background: #ff9f43;
  color: #fff;
}

.topnav-dropdown-header {
  border-bottom: 1px solid #eeeeee;
  text-align: center;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
}
.topnav-dropdown-header .notification-title {
  color: #333;
  display: block;
  float: left;
  font-size: 14px;
}
.topnav-dropdown-header .clear-noti {
  color: #ea5455;
  float: right;
  font-size: 11px;
  text-transform: uppercase;
}

.notifications .noti-content {
  height: 290px;
  width: 350px;
  overflow-y: auto;
  position: relative;
}
.notifications .notification-list li {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
  font-size: 14px;
}
.notifications .notification-list li a {
  display: block;
  padding: 12px;
  border-radius: 2px;
}
.notifications .notification-list li .noti-title {
  color: #333;
  font-weight: 500;
}
.notifications .notification-list li .noti-details {
  color: #989c9e;
  margin: 0;
}
.notifications .notification-list li p.noti-time {
  margin: 0;
}
.notifications .notification-list li .notification-time {
  font-size: 12px;
  line-height: 1.35;
  color: #637381;
}
.notifications .notification-list li .list-item {
  border: 0;
  padding: 0;
  position: relative;
}
.notifications .notification-list li .list-item .list-left {
  height: 48px;
  position: absolute;
  width: 48px;
}
.notifications .notification-list li .list-item .list-body {
  padding: 0 0 0 50px;
}
.notifications .notification-list li .list-item .list-body .message-author {
  color: #333;
  float: left;
  font-weight: 500;
  width: 175px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notifications .notification-list li .list-item .list-body .message-time {
  color: #888888;
  float: right;
  font-size: 11px;
}
.notifications .notification-list li .list-item .list-body .message-content {
  color: #333;
  font-size: 13px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topnav-dropdown-footer {
  border-top: 1px solid #eeeeee;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
}
.topnav-dropdown-footer a {
  display: block;
  text-align: center;
  color: #333;
}

.list-left {
  height: 48px;
  position: absolute;
  width: 48px;
}

.list-body {
  padding: 0 0 0 50px;
}
.list-body .message-author {
  color: #333;
  float: left;
  font-weight: 500;
  width: 175px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list-body .message-time {
  color: #888888;
  float: right;
  font-size: 11px;
}
.list-body .message-content {
  color: #333;
  font-size: 13px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.activity {
  width: 100%;
}
.activity .activity-box {
  position: relative;
}
.activity .activity-list {
  position: relative;
}
.activity .activity-list li {
  background-color: #fff;
  position: relative;
  border: 1px solid #e7e7e7;
  margin: 0 0 10px;
  padding: 20px 10px 10px;
  font-size: 15px;
}
.activity .activity-list li .activity-user {
  height: 50px;
  margin: 0;
  padding: 0;
  width: 50px;
  position: absolute;
  top: 12px;
  left: 8px;
}
.activity .activity-list li .activity-content {
  background-color: #fff;
  position: relative;
  margin: 0 0 0 60px;
  padding: 0;
}
.activity .activity-list li .time {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 1.35;
}
.activity .activity-list li a.name {
  color: #000;
}
.activity .activity-list li a {
  color: #212b36;
  font-weight: 600;
  font-size: 15px;
}
.activity .activity-list li a img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.table {
  margin: 0;
}
.table thead {
  background: #fafbfe;
  border-bottom: 1px solid #e9ecef;
}
.table thead th {
  font-weight: 600;
  color: #212b36;
  padding: 10px;
  white-space: nowrap;
}
.table tbody {
  border: 0 !important;
}
.table tbody tr:hover {
  background: #f5f5f5;
}
.table tbody tr td {
  padding: 10px;
  color: #637381;
  font-weight: 500;
  border-bottom: 1px solid #e9ecef;
  vertical-align: middle;
  white-space: nowrap;
}
.table tbody tr td .product-img img {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border: 0;
}
.table tbody tr td a {
  color: #212b36;
}
.table tbody tr td a:hover {
  color: #ff9f43;
}
.table tbody tr td .product-imgs {
  width: 40px;
  height: 40px;
  background: #ffebd8;
  font-size: 14px;
  color: #ff9f43 !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.table tbody tr.active {
  border-bottom: 0;
}
.table tbody tr.active td {
  font-size: 18px;
  color: #5e5873;
  font-weight: 600;
  border-bottom: 0;
}

.table-height {
  min-height: 300px;
}
@media (max-width: 991.98px) {
  .table-height {
    min-height: auto;
    margin-bottom: 15px;
  }
}

.max-widthauto {
  max-width: 100% !important;
}

.dataTables_filter + .dataTables_filter {
  display: none;
}

.dataview .dataTables_length,
.dataview .dataTables_info,
.dataview .dataTables_paginate {
  display: none !important;
}

.dataTables_length label:before {
  content: "Show  per page :";
  margin-right: 5px;
}

.dataTables_length {
  float: left;
  font-size: 12px;
}

.productimgname img {
  margin-right: 10px;
}
.productimgname a {
  color: #212b36;
  font-weight: 500;
}

.form-group .calc-no {
  width: 50px !important;
  text-align: center;
}

.table .form-group input[type="text"] {
  height: 40px;
}
.table .scanner-set {
  width: 40px;
  height: 40px;
}

.modal-barcode ul li {
  border-radius: 7px;
  width: 25%;
  margin-bottom: 10px;
  padding: 0 5px;
}
@media (max-width: 991.98px) {
  .modal-barcode ul li {
    width: 50%;
  }
}
.modal-barcode ul li a {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  display: block;
  padding: 10px;
  text-align: center;
}

.modal .modal-title {
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .modal .modal-title {
    font-size: 16px;
  }
}
.modal .close,
.modal .btn-close {
  color: #ea5455;
  opacity: 1;
  font-size: 20px;
  width: 22px;
  height: 22px;
  border: 0;
  font-weight: 700;
  background: transparent;
  border-radius: 50px;
}
.modal .close:hover,
.modal .btn-close:hover {
  background: #ea5455;
  color: #fff;
}
.modal .modal-footer {
  border: 0;
  justify-content: flex-start;
}
.modal .modal-footer .btn {
  min-width: 130px;
  padding: 12px;
}
@media (max-width: 991.98px) {
  .modal .modal-footer .btn {
    min-width: auto;
    padding: 7px 12px;
  }
}
.modal .modal-footer .btn-print {
  border: 1px solid #ff9f43;
  color: #ff9f43;
}
.modal .modal-footer .btn-print img {
  margin-left: 10px;
}
.modal .modal-footer .btn-print:hover {
  background: #ff9f43;
  color: #fff;
}
.modal .modal-footer .btn-print:hover img {
  filter: brightness(0) invert(1);
}
.modal .modal-footer .btn-secondary {
  background: #212b36;
}
.modal .modal-footer .btn-secondary:hover {
  -webkit-box-shadow: inset 0 0px 0px 100px #fff;
  -moz-box-shadow: inset 0 0px 0px 100px #fff;
  box-shadow: inset 0 0px 0px 100px #fff;
  color: #212b36;
}

.account-page .customizer-links {
  box-shadow: 0px 4px 18px rgba(77, 76, 76, 0.25);
}

.customizer-links {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  background: #fe9f43;
  border-radius: 5px 0px 0px 5px;
  z-index: 3;
  width: 47px;
  min-height: 170px;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.customizer-links ul {
  width: 100%;
}
.customizer-links ul li {
  margin: 10px 5px 0;
  padding: 0 0 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.customizer-links ul li:last-child {
  border-bottom: 0;
}
.customizer-links ul li a {
  padding: 10px 10px;
  text-align: center;
  border-radius: 5px;
}
.customizer-links ul li a:hover {
  background: #1b2850;
}
.customizer-links ul li a img {
  height: 18px;
}

.sidebar-settings {
  width: 450px;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: -450px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  -webkit-transition: ease all 0.8s;
  -ms-transition: ease all 0.8s;
  transition: ease all 0.8s;
}
.sidebar-settings.show-settings {
  right: 0;
  -webkit-transition: ease all 0.8s;
  -ms-transition: ease all 0.8s;
  transition: ease all 0.8s;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  width: 100%;
}

.sidebar-content {
  max-width: 450px;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  height: 100vh;
  overflow-x: hidden;
}
.sidebar-content .sidebar-header {
  padding: 20px;
  border: 1px solid #d9d9d9;
}
.sidebar-content .sidebar-header h5 {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: uppercase;
}
@media (max-width: 991.98px) {
  .sidebar-content .sidebar-header h5 {
    font-size: 18px;
  }
}
.sidebar-content .sidebar-header a {
  font-size: 24px;
}
.sidebar-content .sidebar-header a img {
  width: 15px;
}
.sidebar-content .sidebar-body {
  padding: 20px;
}
.sidebar-content .sidebar-body .theme-title {
  font-size: 18px;
  font-weight: 700;
  color: #6b7a99;
  margin-bottom: 10px;
}

.switch-wrapper {
  border-bottom: 1px solid #d9d9d9;
  margin: 0 0 20px;
  padding: 0 0 20px;
}
.switch-wrapper #dark-mode-toggle .light-mode {
  border-radius: 5px 0 0 5px;
}
.switch-wrapper #dark-mode-toggle .dark-mode {
  border-radius: 0 5px 5px 0;
}
.switch-wrapper #dark-mode-toggle .light-mode,
.switch-wrapper #dark-mode-toggle .dark-mode {
  background: #d9d9d9;
  font-size: 16px;
  color: #000;
  padding: 11px 18px;
  cursor: pointer;
}
.switch-wrapper #dark-mode-toggle .light-mode img,
.switch-wrapper #dark-mode-toggle .dark-mode img {
  filter: brightness(0) saturate(100%);
}
.switch-wrapper #dark-mode-toggle .light-mode.active,
.switch-wrapper #dark-mode-toggle .dark-mode.active {
  background: #fe9f43;
  color: #fff;
}
.switch-wrapper #dark-mode-toggle .light-mode.active img,
.switch-wrapper #dark-mode-toggle .dark-mode.active img {
  filter: brightness(0) invert(1);
}

.layout-wrap {
  margin-bottom: 20px;
}
.layout-wrap .status-toggle .checktoggle {
  background: #000;
  width: 25px;
  height: 15px;
}
.layout-wrap .status-toggle .checktoggle :after {
  width: 12px;
  height: 12px;
  right: 4px;
}
.layout-wrap .layout-img img {
  max-width: 120px;
}
.layout-wrap .status-text {
  font-size: 16px;
  color: #000;
}

.layout-link {
  position: relative;
  z-index: 9;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.chat-window .card .card-header {
  background-color: #fff;
  border-bottom: 1px solid #e8ebed;
}
.chat-window .badge-success {
  background-color: #26af48 !important;
}

.chat-page .footer {
  display: none;
}

.chat-search .input-group .form-control {
  margin: 0;
  padding: 0 0 0 36px;
  height: 46px;
}
.chat-search .input-group .input-group-prepend {
  color: #5e5873;
  pointer-events: none;
  z-index: 4;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
}

.chat-cont-left .chat-scroll {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding: 0;
}
.chat-cont-left .chat-scroll .media {
  border-bottom: 1px solid #e8ebed;
  margin: 0;
  padding: 10px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.chat-cont-left .chat-scroll .media.active {
  background-color: #f5f5f5;
}
.chat-cont-left .chat-scroll .media:hover {
  background-color: #f5f5f5;
}
.chat-cont-left .chat-scroll .media .media-img-wrap {
  margin: 0 15px 0 0;
  position: relative;
}
.chat-cont-left .chat-scroll .media .media-img-wrap .avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chat-cont-left .chat-scroll .media .media-body .user-name {
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #212b36;
  font-size: 16px;
  font-weight: 700;
}
.chat-cont-left .chat-scroll .media .media-body .user-last-chat {
  color: #333;
  font-size: 14px;
  line-height: 24px;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-cont-left .chat-scroll .media .media-body .last-chat-time {
  color: #637381;
  font-size: 13px;
}
.chat-cont-left .chat-scroll .media .media-body div:last-child {
  text-align: right;
}

.chat-cont-right {
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
}
.chat-cont-right .chat-scroll {
  max-height: calc(100vh - 293px);
  overflow-y: auto;
}
.chat-cont-right .msg_head .back-user-list {
  display: none;
  margin: 0 0 0 -10px;
  padding: 0 10px;
}
.chat-cont-right .img_cont {
  position: relative;
  height: 45px;
  width: 45px;
}
.chat-cont-right .img_cont .user_img {
  height: 45px;
  min-width: 45px;
  width: 45px;
  border: 1.5px solid #ff9f43;
}
.chat-cont-right .user_info {
  margin: 0 auto 0 15px;
  padding: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-cont-right ul.list-unstyled {
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.chat-cont-right ul.list-unstyled .media.sent {
  margin-bottom: 20px;
}
.chat-cont-right ul.list-unstyled .media.sent .avatar {
  order: 3;
  height: 30px;
  width: 30px;
}
.chat-cont-right ul.list-unstyled .media.sent .media-body {
  margin: 0 20px 0 0;
  padding: 0;
}
.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box {
  margin: 0;
  padding: 0 0 0 50px;
  position: relative;
}
.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box + .msg-box {
  margin: 5px 0 0;
  padding: 0;
}
.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box p {
  margin: 0;
  padding: 0;
}
.chat-cont-right
  ul.list-unstyled
  .media.sent
  .media-body
  .msg-box:first-child::before {
  border-bottom: 6px solid transparent;
  border-left: 6px solid #e3e3e3;
  border-top: 6px solid #e3e3e3;
  border-right: 6px solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  right: -12px;
  left: auto;
}
.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box > div {
  margin: 0;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #e3e3e3;
  display: inline-block;
  position: relative;
}
.chat-cont-right
  ul.list-unstyled
  .media.sent
  .media-body
  .msg-box:first-child
  > div {
  border-radius: 10px 0 10px 10px;
}
.chat-cont-right
  ul.list-unstyled
  .media.sent
  .media-body
  .chat-msg-attachments {
  margin: 0 -1px;
  padding: 4px 0;
  width: 100%;
}
.chat-cont-right
  ul.list-unstyled
  .media.sent
  .media-body
  .chat-msg-attachments
  .chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden;
}
.chat-cont-right
  ul.list-unstyled
  .media.sent
  .media-body
  .chat-msg-attachments
  .chat-attachment:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  content: "";
  opacity: 0.4;
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
}
.chat-cont-right
  ul.list-unstyled
  .media.sent
  .media-body
  .chat-msg-attachments
  .chat-attachment:hover
  .chat-attach-download {
  opacity: 1;
}
.chat-cont-right
  ul.list-unstyled
  .media.sent
  .media-body
  .chat-msg-attachments
  > div {
  margin: 0 1px;
}
.chat-cont-right
  ul.list-unstyled
  .media.sent
  .media-body
  .chat-msg-attachments
  img {
  max-width: 100%;
}
.chat-cont-right
  ul.list-unstyled
  .media.sent
  .media-body
  .chat-msg-attachments
  .chat-attach-download {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
  color: #fff;
  width: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
}
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info {
  margin: 5px 0 0;
  padding: 0;
  clear: both;
  flex-direction: row-reverse;
  list-style: none;
}
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info li {
  margin: 0;
  padding: 0 0 0 16px;
  position: relative;
}
.chat-cont-right
  ul.list-unstyled
  .media.sent
  .media-body
  .chat-msg-info
  li:not(:last-child)::after {
  position: absolute;
  top: 50%;
  right: auto;
  left: 8px;
  transform: translate(-50%, -50%);
  background: #ff9f43;
  content: "";
  height: 4px;
  width: 4px;
}
.chat-cont-right
  ul.list-unstyled
  .media.sent
  .media-body
  .chat-msg-info
  .chat-time {
  color: rgba(50, 65, 72, 0.4);
}
.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info a {
  color: #333;
}
.chat-cont-right ul.list-unstyled .media.received {
  position: relative;
  margin-bottom: 20px;
}
.chat-cont-right ul.list-unstyled .media.received .media-body {
  position: relative;
  margin: 0 0 0 20px;
  padding: 0;
}
.chat-cont-right ul.list-unstyled .media.received .media-body::before {
  border-bottom: 6px solid transparent;
  border-right: 6px solid #ff9f43;
  border-top: 6px solid #ff9f43;
  border-left: 6px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  top: 0px;
  right: auto;
  left: -6px;
  width: 0;
}
.chat-cont-right ul.list-unstyled .media.received .media-body > div {
  margin: 0;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #ff9f43;
  display: table;
  position: relative;
}
.chat-cont-right ul.list-unstyled .media.received .media-body > div p {
  color: #fff;
  margin: 0;
  padding: 0;
}
.chat-cont-right ul.list-unstyled .media.received .msg-box {
  display: block;
}
.chat-cont-right ul.list-unstyled .media.received .msg-box + .msg-box {
  margin-top: 5px;
}
.chat-cont-right ul.list-unstyled .media.received .chat-msg-info {
  margin: 5px 0 0;
  padding: 0;
  clear: both;
  list-style: none;
}
.chat-cont-right ul.list-unstyled .media.received .chat-msg-info .chat-time {
  color: rgba(255, 255, 255, 0.8);
}
.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments {
  margin: 0 -1px;
  padding: 4px 0;
  width: 100%;
}
.chat-cont-right
  ul.list-unstyled
  .media.received
  .chat-msg-attachments
  .chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden;
}
.chat-cont-right
  ul.list-unstyled
  .media.received
  .chat-msg-attachments
  .chat-attachment:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  content: "";
  opacity: 0.4;
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
}
.chat-cont-right
  ul.list-unstyled
  .media.received
  .chat-msg-attachments
  .chat-attachment:hover
  .chat-attach-download {
  opacity: 1;
}
.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments > div {
  margin: 0 1px;
}
.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments img {
  max-width: 100%;
}
.chat-cont-right
  ul.list-unstyled
  .media.received
  .chat-msg-attachments
  .chat-attach-download {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
  opacity: 0;
  color: #fff;
  width: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
}
.chat-cont-right ul.list-unstyled .chat-date {
  font-size: 14px;
  margin: 1.875rem 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: capitalize;
}
.chat-cont-right ul.list-unstyled .chat-date:before {
  background-color: #e0e3e4;
  content: "";
  height: 1px;
  margin-right: 28px;
  position: absolute;
  top: 50%;
  right: 50%;
  width: 100%;
}
.chat-cont-right ul.list-unstyled .chat-date:after {
  background-color: #e0e3e4;
  content: "";
  height: 1px;
  margin: 0 0 0 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
}

.card-footer {
  background-color: #fff;
  border-top: 1px solid #e8ebed;
  margin: 0;
  padding: 1rem 1.5rem;
}
.card-footer .type_msg {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border: 0 !important;
  overflow-y: auto;
}

@media (max-width: 992px) {
  .chat-window.chat-slide .chat-cont-right {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
  .chat-cont-right {
    position: absolute;
    right: -100px;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
  }
  .chat-cont-right .msg_head .back-user-list {
    display: flex;
    align-items: center;
  }
}
.msg-typing {
  width: auto;
  height: 24px;
  padding-top: 8px;
}
.msg-typing span {
  height: 8px;
  width: 8px;
  float: left;
  background-color: #fff;
  display: block;
  opacity: 0.4;
  margin: 0 1px;
  border-radius: 50%;
}
.msg-typing span:nth-of-type(1) {
  animation: 1s blink infinite 0.33333s;
}
.msg-typing span:nth-of-type(2) {
  animation: 1s blink infinite 0.66666s;
}
.msg-typing span :nth-of-type(3) {
  animation: 1s blink infinite 0.99999s;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}
.page-header {
  margin-bottom: 25px;
}
@media (max-width: 575px) {
  .page-header {
    margin-bottom: 20px;
    flex-direction: column;
  }
  .page-header .page-title {
    text-align: center;
  }
  .page-header .page-btn {
    margin-top: 15px;
  }
}
.page-header h3 {
  font-weight: 700;
  color: #212b36;
  font-size: 18px;
}
@media (max-width: 991.98px) {
  .page-header h3 {
    font-size: 14px;
  }
}
.page-header h4 {
  font-weight: 700;
  color: #212b36;
  font-size: 18px;
}
@media (max-width: 991.98px) {
  .page-header h4 {
    font-size: 14px;
  }
}
.page-header h6 {
  font-size: 14px;
  color: #555;
  font-weight: 400;
}
@media (max-width: 991.98px) {
  .page-header h6 {
    font-size: 12px;
  }
}
.page-header .btn-filter {
  background: #ff9f43;
  width: 34px;
  height: 34px;
  padding: 0;
}
.page-header .btn-filter:hover {
  background: linear-gradient(46.62deg, #ff9f43 0%, #423b93 93.64%);
}
.page-header .btn-added {
  background: #ff9f43;
  padding: 7px 15px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}
.page-header .btn-added img {
  filter: brightness(0) invert(1);
}
@media (max-width: 991.98px) {
  .page-header .btn-added {
    padding: 3px 8px;
  }
}
.page-header .btn-added:hover {
  background: #1b2850;
}

.table-top {
  margin-bottom: 25px;
}
@media (max-width: 575px) {
  .table-top {
    flex-direction: column;
  }
  .table-top .wordset {
    margin-top: 25px;
  }
}

.search-set .btn-filter {
  background: #ff9f43;
  width: 34px;
  height: 34px;
  padding: 0;
  margin-right: 10px;
}
.search-set .btn-filter span {
  display: none;
}
.search-set .btn-filter:hover {
  background: #1b2850;
}
.search-set .btn-filter.setclose {
  background: #ea5455;
}
.search-set .btn-filter.setclose > img {
  display: none;
}
.search-set .btn-filter.setclose span {
  display: block;
}
.search-set .btn-filter.setclose span img {
  display: block;
}
.search-set .btn-filter span {
  display: none;
}
.search-set .btn-filter > img {
  display: block;
}
.search-set .search-input {
  position: relative;
}
.search-set .search-input input[type="search"] {
  background: transparent;
  height: 40px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  width: 200px;
  border-radius: 5px;
  padding: 0 15px 0 30px;
}
.search-set .search-input .btn-searchset {
  padding: 2px;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  left: 6px;
}

.swal2-actions .swal2-confirm {
  margin-right: 10px;
}

.wordset ul li {
  margin: 0 10px;
}
.wordset ul li a {
  display: block;
}

.checkboxs {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
}
.checkboxs input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkboxs input ~ .checkmarks {
  border: 1px solid #b9b9c3;
}
.checkboxs input:checked ~ .checkmarks {
  background-color: #ff9f43;
}
.checkboxs input:checked ~ .checkmarks:after {
  display: block;
}
.checkboxs .checkmarks {
  height: 15px;
  width: 15px;
  position: absolute;
  top: 0;
  left: 0;
}
.checkboxs .checkmarks:after {
  content: "";
  position: absolute;
  display: none;
}
.checkboxs .checkmarks:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.image-upload {
  border: 1px solid rgba(145, 158, 171, 0.32);
  position: relative;
  margin-bottom: 35px;
  border-radius: 5px;
}
.image-upload:hover {
  background: #f5f5f5;
}
.image-upload input[type="file"] {
  width: 100%;
  opacity: 0;
  height: 100px;
  position: relative;
  z-index: 9;
  cursor: pointer;
}
.image-upload .image-uploads {
  text-align: center;
  width: 100%;
  padding: 20px 0;
  border-radius: 5px;
  position: absolute;
  top: 0;
}
.image-upload .image-uploads h4 {
  font-size: 14px;
  font-weight: 500;
  color: #212b36;
}
.image-upload.image-upload-new input[type="file"] {
  height: 258px;
}
.image-upload.image-upload-new .image-uploads {
  height: 100%;
  flex-direction: column;
}

.checktoggle {
  background-color: rgba(115, 103, 240, 0.2);
  border-radius: 12px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 13px;
  margin-bottom: 0;
  position: relative;
  width: 25px;
}
.checktoggle:after {
  content: " ";
  display: block;
  transform: translate(2px, -50%);
  width: 10px;
  height: 10px;
  background-color: #fff;
  transition: left 300ms ease, transform 300ms ease;
  border-radius: 50%;
  transform: translate(2px, -50%);
  -webkit-transform: translate(2px, -50%);
  -ms-transform: translate(2px, -50%);
  position: absolute;
  top: 50%;
  left: 0;
}

.check:checked + .checktoggle:after {
  background-color: #fff;
  left: 100%;
  transform: translate(calc(-90% - 3px), -45%);
}

.check {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.check:checked + .checktoggle {
  background-color: #28c76f;
}

#filter_inputs {
  border: 0;
  margin: 0;
}
#filter_inputs .card-body {
  padding: 0;
}

.dash-widget {
  background: #fff;
  margin-bottom: 25px;
  border-radius: 6px;
  padding: 20px;
  border: 1px solid #e8ebed;
}
.dash-widget.dash1 .dash-widgetimg span {
  background: rgba(40, 199, 111, 0.12);
}
.dash-widget.dash2 .dash-widgetimg span {
  background: rgba(0, 207, 232, 0.12);
}
.dash-widget.dash3 .dash-widgetimg span {
  background: rgba(234, 84, 85, 0.12);
}
.dash-widget .dash-widgetcontent {
  margin-left: 20px;
}
.dash-widget .dash-widgetcontent h5 {
  color: #212b36;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 5px;
}
.dash-widget .dash-widgetcontent h6 {
  font-weight: 400;
  font-size: 14px;
  color: #212b36;
}
.dash-widget .dash-widgetimg span {
  width: 48px;
  height: 48px;
  background: rgba(249, 110, 111, 0.12);
  border-radius: 50px;
}

.dashboardActivities {
  background: #f1f1f1;
  color: #444;
  font-size: 16px;
  width: 100%;
  height: 54px;
  font-weight: bold;
  padding: 14px;
}

.dashboardBox {
  background: #fff;
  color: #333;
  min-height: 118px;
  width: 100%;
  border-radius: 6px;
  margin: 0 0 25px;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.dashboardSection {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.dashboardBox h4 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.dashboardBox h5 {
  font-size: 14px;
}
.dashboardBox .dash-imgs {
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.dashboardBox .dash-imgs img {
  width: 40px;
  height: 40px;
}
.dashboardBox .dash-imgs i {
  font-size: 36px;
  color: #fff;
}
.dashboardBox .dash-imgs svg {
  width: 40px;
  height: 40px;
}
.dashboardBox:hover .dash-imgs {
  transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
}
.dashboardIcon {
  color: #555;
  font-size: 12px !important;
}
.dashboardTitle {
  font-size: 17px !important;
}
.btn-white.dropdown-toggle:after {
  display: none;
}

@media (max-width: 991.98px) {
  .graph-sets > ul {
    display: none;
  }
}
.graph-sets > ul li {
  margin-right: 35px;
}
.graph-sets > ul li span {
  color: #637381;
  font-weight: 400;
  position: relative;
}
.graph-sets > ul li span:after {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #28c76f;
  border-radius: 50px;
  position: absolute;
  top: 3px;
  left: -20px;
}
.graph-sets > ul li:last-child span:after {
  background-color: #ea5455;
}

.inbox-menu {
  display: inline-block;
  width: 100%;
  margin: 0 0 20px;
  padding: 0;
}
.inbox-menu li {
  display: inline-block;
  width: 100%;
}
.inbox-menu li a {
  color: #333;
  display: inline-block;
  padding: 10px 15px;
  width: 100%;
  font-weight: 600;
  text-transform: capitalize;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
}
.inbox-menu li a:hover {
  background: rgba(33, 33, 33, 0.05);
}
.inbox-menu li a i {
  font-size: 16px;
  padding-right: 10px;
  color: #878787;
}

.compose-btn {
  margin-bottom: 25px;
}

.top-action-left .dropdown-toggle {
  height: 40px;
}
.top-action-left .dropdown-toggle::after {
  display: none;
}

.table-inbox .starred.fas.fa-star {
  color: #ffd200;
}
.table-inbox .checked {
  background-color: #ffffcc;
}
.table-inbox tbody tr td {
  font-size: 14px;
  color: #212b36;
}

.unread .name,
.unread .subject,
.unread .mail-date {
  color: #000;
  font-weight: 700;
}

.login-wrapper {
  width: 100%;
  overflow: hidden;
  height: 100vh;
}
.login-wrapper .login-content {
  width: 40%;
  height: 100vh;
  padding: 30px;
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 991.98px) {
  .login-wrapper .login-content {
    width: 100%;
  }
}
.login-wrapper .login-content .login-logo {
  display: block;
  margin-bottom: 25px;
  max-width: 150px;
}
.login-wrapper .login-content .login-userset {
  width: 100%;
}
@media (max-width: 991.98px) {
  .login-wrapper .login-content .login-userset {
    margin-top: 30px;
  }
}
.login-wrapper .login-content .login-userheading {
  margin: 0 0 30px;
}
.login-wrapper .login-content .login-userheading h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 15px;
  color: #000;
}
.login-wrapper .login-content .login-userheading h4 {
  font-size: 14px;
  font-weight: 400;
  color: #212b36;
  line-height: 1.4;
}
.login-wrapper .login-content .form-login {
  margin-bottom: 15px;
}
.login-wrapper .login-content .form-login label {
  width: 100%;
  color: #212b36;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
}
.login-wrapper .login-content .form-login .form-addons {
  position: relative;
}
.login-wrapper .login-content .form-login .form-addons img {
  position: absolute;
  top: 16px;
  right: 20px;
}
.login-wrapper .login-content .form-login input {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 5px;
  padding: 0 15px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.login-wrapper .login-content .form-login input:focus {
  border: 1px solid #ff9f43;
}
.login-wrapper .login-content .form-login .btn-login {
  width: 100%;
  font-weight: 700;
  background: #ff9f43;
  border-color: #ff9f43;
  padding: 12px;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-top: 15px;
  color: #fff;
}
.login-wrapper .login-content .form-login .btn-login:hover {
  box-shadow: 0 50px #fff inset !important;
  color: #ff9f43;
}
.login-wrapper .login-content .form-login .alreadyuser {
  margin-bottom: 15px;
}
.login-wrapper .login-content .form-login .alreadyuser h4 {
  font-size: 14px;
  font-weight: 400;
  color: #b8bcc9;
}
.login-wrapper .login-content .form-login .alreadyuser h4 a {
  color: #ff9f43;
  font-weight: 600;
}
.login-wrapper .login-content .signinform {
  margin-bottom: 25px;
}
.login-wrapper .login-content .signinform h4 {
  font-size: 14px;
  font-weight: 400;
  color: #212b36;
}
.login-wrapper .login-content .signinform h4 a {
  color: #212b36;
  font-weight: 600;
}
.login-wrapper .login-content .form-setlogin {
  text-align: center;
  position: relative;
  color: #b8bcc9;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 40px;
}
.login-wrapper .login-content .form-setlogin h4 {
  position: relative;
  font-size: 14px;
}
.login-wrapper .login-content .form-setlogin h4:after {
  content: "";
  background: #cccccc;
  width: 175px;
  height: 1px;
  position: absolute;
  top: 10px;
  left: 0;
}
@media (max-width: 991.98px) {
  .login-wrapper .login-content .form-setlogin h4:after {
    height: 0px;
  }
}
.login-wrapper .login-content .form-setlogin h4:before {
  content: "";
  background: #cccccc;
  width: 175px;
  height: 1px;
  position: absolute;
  top: 10px;
  right: 0;
}
@media (max-width: 991.98px) {
  .login-wrapper .login-content .form-setlogin h4:before {
    height: 0px;
  }
}
.login-wrapper .login-content .toggle-password {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #cccccc;
  font-size: 18px;
  border-radius: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 10px;
}
.login-wrapper .login-content .toggle-password:hover {
  color: #ff9f43;
}
.login-wrapper .login-content .toggle-passwords {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #cccccc;
  font-size: 18px;
  border-radius: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 10px;
}
.login-wrapper .login-content .toggle-passwords:hover {
  color: #ff9f43;
}
.login-wrapper .login-content .pass-group {
  position: relative;
  font-size: 14px;
}
.login-wrapper .login-content .pass-group .fas {
  font-size: 13px;
  color: #939ea8;
}
.login-wrapper .login-content .form-sociallink {
  margin-top: 25px;
}
.login-wrapper .login-content .form-sociallink ul li {
  width: 50%;
  text-align: center;
}
@media (max-width: 575px) {
  .login-wrapper .login-content .form-sociallink ul li {
    width: 100%;
  }
}
.login-wrapper .login-content .form-sociallink ul li:first-child {
  padding-right: 15px;
}
@media (max-width: 575px) {
  .login-wrapper .login-content .form-sociallink ul li:first-child {
    padding-right: 0;
  }
}
.login-wrapper .login-content .form-sociallink ul li a {
  color: #212b36;
  width: 100%;
  border: 1px solid rgba(145, 158, 171, 0.23);
  border-radius: 5px;
  margin: 0 0 10px;
  padding: 9px 4px;
  font-weight: 500;
}
.login-wrapper .login-content .form-sociallink ul li a:hover {
  background: #ff9f43;
  color: #fff;
}
.login-wrapper .login-img {
  width: 60%;
}
@media (max-width: 991.98px) {
  .login-wrapper .login-img {
    display: none;
  }
}
.login-wrapper .login-img img {
  width: 100%;
  height: 100vh;
}

.hover-a {
  position: relative;
}
.hover-a:after {
  content: "";
  height: 2px;
  width: 100%;
  background: #ff9f43;
  transform: scale(0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  bottom: -3px;
  left: 0;
}
.hover-a:hover:after {
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
}
.hover-a:hover {
  color: #ff9f43 !important;
}

.product-list ul {
  margin-bottom: 15px;
}
.product-list ul li {
  width: 25%;
  padding: 0 15px;
}
@media (max-width: 991.98px) {
  .product-list ul li {
    width: 50%;
    padding: 0 5px;
  }
}
@media (max-width: 575px) {
  .product-list ul li {
    width: 100%;
    padding: 0;
  }
}
.product-list ul li .productviews {
  border: 1px solid rgba(145, 158, 171, 0.32);
  margin: 0 0 10px;
  padding: 15px 15px 10px;
  border-radius: 5px;
}
.product-list ul li .productviews .productviewsimg img {
  max-width: 48px;
  max-height: 48px;
}
.product-list ul li .productviews .productviewscontent {
  margin-left: 10px;
  width: calc(100% - 48px);
}
.product-list ul li .productviews .productviewscontent .productviewsname h2 {
  font-weight: 500;
  font-size: 14px;
  color: #000;
}
.product-list ul li .productviews .productviewscontent .productviewsname h3 {
  color: #212b36;
  font-size: 13px;
  font-weight: 400;
}
.product-list ul li .productviews .productviewscontent a {
  color: #ea5455;
  font-weight: 700;
  border-radius: 50px;
  font-size: 20px;
  width: 22px;
  height: 22px;
}
.product-list ul li .productviews .productviewscontent a:hover {
  background: #ea5455;
  color: #fff;
}

.bar-code-view {
  width: 282px;
  border: 1px solid #d8d8d8;
  padding: 15px;
  margin-bottom: 20px;
  max-width: 100%;
}

.input-checkset ul li {
  margin-right: 30px;
}
@media (max-width: 991.98px) {
  .input-checkset ul li {
    margin-right: 10px;
  }
}

@media (max-width: 991.98px) {
  .productdetails .product-bar li {
    border: 1px solid #d8d8d8;
  }
  .productdetails .product-bar li:first-child {
    border: 1px solid #d8d8d8;
  }
  .productdetails .product-bar h4 {
    width: 50%;
    border-right: 1px solid #d8d8d8;
  }
  .productdetails .product-bar h6 {
    width: 50%;
  }
}
.productdetails.productdetailnew {
  margin-bottom: 25px;
}
.productdetails.productdetailnew ul li h4 {
  width: 50%;
}
.productdetails.productdetailnew ul li h6 {
  width: 50%;
}
.productdetails.productdetailnew ul li h6.manitorygreen {
  color: #0f9d58;
}
.productdetails.productdetailnew ul li h6.manitoryblue {
  color: #2aa4f4;
}
.productdetails > ul > li {
  border: 1px solid #d8d8d8;
  border-top: 0;
}
@media (max-width: 991.98px) {
  .productdetails > ul > li {
    border: 0;
  }
}
.productdetails > ul > li h4 {
  color: #212b36;
  font-size: 14px;
  font-weight: 500;
  width: 30%;
  border-right: 1px solid #d8d8d8;
  padding: 10px;
}
@media (max-width: 991.98px) {
  .productdetails > ul > li h4 {
    width: 100%;
    border-right: 0;
  }
}
.productdetails > ul > li h6 {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  width: 70%;
  padding: 10px;
}
@media (max-width: 991.98px) {
  .productdetails > ul > li h6 {
    width: 100%;
  }
}
.productdetails > ul > li .input-checkset {
  padding: 10px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  width: 70%;
}
.productdetails > ul > li:first-child {
  border-top: 1px solid #d8d8d8;
}
@media (max-width: 991.98px) {
  .productdetails > ul > li:first-child {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .productdetails.product-respon {
    overflow: hidden;
  }
  .productdetails.product-respon ul {
    overflow: auto;
  }
  .productdetails.product-respon ul .input-checkset li {
    margin-bottom: 15px;
  }
}

.slider-product {
  text-align: center;
  width: 100%;
}
.slider-product img {
  width: auto !important;
  margin: 0 0 10px;
}
@media (max-width: 991.98px) {
  .slider-product img {
    height: auto;
  }
}
.slider-product h4 {
  font-size: 14px;
  color: #212b36;
  font-weight: 500;
}
.slider-product h6 {
  font-size: 13px;
  font-weight: 400;
  color: #212b36;
}

.slider-product-details .owl-carousel .owl-nav {
  position: relative;
}
.slider-product-details .owl-carousel .owl-nav button {
  width: 21px;
  height: 21px;
  color: #292d32;
  border: 1px solid #292d32;
  position: absolute;
  top: -30px;
  border-radius: 50px;
}
.slider-product-details .owl-carousel .owl-nav button:hover {
  background: #292d32;
  color: #fff;
}
.slider-product-details .owl-carousel .owl-nav button span {
  font-size: 16px;
}
.slider-product-details .owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: -30px;
  right: 0;
}

.requiredfield {
  margin-bottom: 15px;
}
@media (max-width: 991.98px) {
  .requiredfield {
    margin-bottom: 10px;
  }
}
.requiredfield h4 {
  font-size: 14px;
  color: #637381;
}

.action-set {
  width: 18px;
  height: 18px;
  display: inline-block;
  font-size: 11px;
  border-radius: 50px;
}

.badges {
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  min-width: 80px;
}
.badges.bg-lightgreen {
  background: #28c76f;
}
.badges.bg-lightred {
  background: #ea5455;
}
.badges.bg-lightyellow {
  background: #ff9900;
}
.badges.bg-lightpurple {
  background: rgb(115, 103, 240);
}
.badges.bg-lightgrey {
  background: #637381;
}

.add-icon span,
.add-icon a {
  width: 40px;
  height: 40px;
  background: #ff9f43;
  border-radius: 5px;
}
@media (max-width: 575px) {
  .add-icon span,
  .add-icon a {
    position: relative;
    left: -5px;
    z-index: 10000;
  }
}
.add-icon a:hover {
  background: #1b2850;
}

.cal-icon {
  /* background-image: url(../img/icons/calendars.svg); */
  background-repeat: no-repeat;
  background-position: 95% 50%;
}

.calc-no {
  background: #f8f8f8;
  border: 0 !important;
}

.total-order {
  max-width: 430px;
  width: 100%;
  margin: 30px 30px 30px auto;
}
.total-order ul {
  margin: 0;
  padding: 0;
}
.total-order ul li {
  border: 1px solid #f8f8f8;
  border-top: 0;
}
.total-order ul li h4 {
  width: 50%;
  color: #637381;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  border-right: 1px solid #f8f8f8;
  background: #fafbfe;
}
.total-order ul li h5 {
  color: #212b36;
  font-size: 14px;
  padding: 10px;
  font-weight: 700;
  text-align: right;
  width: 50%;
}
.total-order ul li:first-child {
  border-top: 1px solid #f8f8f8;
}
.total-order ul li.total h5 {
  color: #212b36;
  font-weight: 700;
}
.total-order ul li.total h4 {
  color: #5e5873;
  font-weight: 700;
}

.card-sales-split {
  padding-bottom: 25px;
  border-bottom: 1px solid #e9ecef;
}
@media (max-width: 991.98px) {
  .card-sales-split {
    padding-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .card-sales-split {
    flex-direction: column;
  }
  .card-sales-split ul {
    margin-top: 15px;
  }
}
.card-sales-split h2 {
  font-size: 14px;
  font-weight: 600;
}
.card-sales-split li {
  margin: 0 15px;
}

.image-upload-new {
  min-height: 258px;
}
.image-upload-new .productviewscontent {
  text-align: left;
  margin-top: 15px;
}
.image-upload-new .productviewscontent .productviewsname h2 {
  font-weight: 500;
  font-size: 14px;
  color: #000;
}
.image-upload-new .productviewscontent .productviewsname h3 {
  color: #212b36;
  font-size: 13px;
  font-weight: 400;
}
.image-upload-new .productviewscontent a {
  width: 22px;
  height: 22px;
  color: #ea5455;
  font-weight: 700;
  border-radius: 50px;
  font-size: 18px;
}
.image-upload-new .productviewscontent a:hover {
  background: #ea5455;
  color: #fff;
}
.image-upload-new .productviews {
  width: 100%;
  padding: 0 10px;
}

.uploadedimage .image-uploads {
  max-width: 100%;
}

.productviewset {
  padding-left: 18px;
  position: relative;
}
.productviewset .productviewsimg {
  max-width: 94px;
}
.productviewset .productviewscontent a {
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  position: absolute;
  top: 4px;
  left: 82px;
}

.main-wrappers {
  background-color: #fafbfe;
}

.product-view {
  width: 100%;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
}
.product-view:hover {
  border: 1px solid #ff9f43;
}
.product-view .product-viewimg {
  position: relative;
  max-height: 120px;
  overflow: hidden;
}
.product-view .product-viewimg h6 {
  background: #0f9d58;
  color: #fff;
  font-size: 8px;
  font-weight: 600;
  padding: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.product-view .product-viewcontent {
  padding: 13px;
}
.product-view .product-viewcontent h3 {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.product-view .product-viewcontent h4 {
  font-size: 12px;
  color: #6e6b7b;
  font-weight: 500;
  margin-bottom: 5px;
}
.product-view .product-viewcontent h5 {
  font-size: 14px;
  color: #6e6b7b;
  font-weight: 500;
}

.main-set {
  padding: 0 30px;
}
@media (max-width: 991.98px) {
  .main-set {
    padding: 0 10px;
  }
}

.text-underline {
  position: relative;
}
.text-underline::after {
  content: "";
  background: #637381;
  width: 10px;
  height: 2px;
  position: absolute;
  bottom: 12px;
  left: 10px;
}

.select-split {
  margin-bottom: 20px;
}
.select-split .select-group {
  width: calc(100% - 55px);
}
.select-split .btn-filters {
  width: 40px;
  height: 40px;
  margin: 0 0 0 15px;
  padding: 0;
}
.select-split .select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #e9ecef;
  height: 40px;
  border-radius: 4px;
}
.select-split
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 40px;
}
.select-split
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 40px;
}

.table tr:hover .productimg .productcontet h4 img {
  opacity: 1;
}

.table-product tr th {
  font-weight: 600;
  color: #6e6b7b;
  font-size: 14px;
}
.table-product tr td {
  font-weight: 600;
  color: #6e6b7b;
  font-size: 14px;
}

.btn-totallabel {
  color: #fff;
  background: #7367f0;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 12px;
}
.btn-totallabel h5 {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
}
.btn-totallabel h6 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.setvaluecash {
  margin: 0 0 15px 0;
}
.setvaluecash ul li {
  width: 33%;
  margin-right: 10px;
}
.setvaluecash ul li:last-child {
  margin: 0;
}
.setvaluecash ul li a {
  border: 1px solid #e9ecef;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  min-height: 95px;
  border-radius: 5px;
  padding: 10px 20px;
}
.setvaluecash ul li a:hover {
  border: 1px solid #7367f0;
  color: #7367f0;
}
.setvaluecash ul li a img {
  margin-bottom: 10px;
}

.setvalue {
  padding: 15px 0;
}
.setvalue ul li {
  margin-bottom: 10px;
}
.setvalue ul li h5 {
  font-size: 13px;
  font-weight: 500;
}
.setvalue ul li h6 {
  font-size: 13px;
  font-weight: 500;
}
.setvalue ul li.total-value h5 {
  font-size: 16px;
  font-weight: 600;
  color: #7367f0;
}
.setvalue ul li.total-value h6 {
  font-size: 16px;
  font-weight: 600;
  color: #7367f0;
}

.product-lists {
  border: 1px solid #fafbfe;
  box-shadow: 0px 4px 4px 0px rgba(237, 237, 237, 0.2509803922);
  margin: 0 0 15px;
  padding: 15px;
}
@media (max-width: 568px) {
  .product-lists {
    flex-direction: column;
  }
  .product-lists li {
    margin: 10px 0;
  }
}

.increment-decrement .input-groups {
  margin-top: 10px;
}
.increment-decrement .input-groups input[type="button"] {
  background-color: transparent;
  font-size: 20px;
  border: 1px solid #b9b9c3;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  margin-top: 0px;
  line-height: 0;
  padding: 0;
  color: #b9b9c3;
}
.increment-decrement .quantity-field {
  width: 30px;
  text-align: center;
  background-color: transparent;
  float: left;
  border: 0;
}

.productimg .productimgs {
  width: 82px;
  margin-right: 13px;
}
.productimg .productimgs img {
  width: 82px;
  height: 82px;
  object-fit: cover;
}
.productimg .productcontet h4 {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin-bottom: 5px;
}
.productimg .productcontet h4 img {
  opacity: 0;
  margin-top: 5px;
  display: block;
}
.productimg .productlinkset h5 {
  background: #ff9f43;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  margin-right: 10px;
  padding: 2px 10px;
}
.productimg .productlinkset a {
  position: relative;
  top: 2px;
}

.product-table {
  height: 34vh;
  overflow: auto;
}
@media (max-width: 991.98px) {
  .product-table {
    height: auto;
  }
}

.btn-set {
  margin: 15px 0 25px;
}
.btn-set h4 {
  font-weight: 700;
}

.product-detils {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}
@media (max-width: 991.98px) {
  .product-detils {
    height: auto;
  }
}

.tab-set {
  margin-bottom: 20px;
}
.tab-set ul li {
  width: 48%;
  text-align: center;
  margin-right: 5px;
}
@media (max-width: 575px) {
  .tab-set ul li {
    width: 100%;
    margin-right: 0;
  }
}
.tab-set ul li:last-child {
  margin: 0;
  width: 50%;
}
@media (max-width: 575px) {
  .tab-set ul li:last-child {
    width: 100%;
  }
}
.tab-set ul li a {
  background: #5e5873;
  width: 100%;
  display: block;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  padding: 10px;
}
@media (max-width: 575px) {
  .tab-set ul li a {
    border-radius: 0;
  }
}
.tab-set ul li.active a {
  background: #ff9f43;
}

#delete .modal-body .btn {
  min-width: 100px;
  padding: 10px;
}

.owl-product {
  padding-top: 35px;
}
.owl-product .owl-nav {
  margin-top: 10px;
  position: absolute;
  top: -10px;
  right: 0;
}
.owl-product .owl-nav button {
  border-radius: 50px !important;
  width: 20px;
  height: 20px;
  width: 19px;
  position: relative;
  height: 19px;
  background: #fff !important;
  color: #7367f0 !important;
}
.owl-product .owl-nav button span {
  font-size: 0;
}
.owl-product .owl-nav button.owl-next span:before {
  position: absolute;
  top: 2px;
  right: 5px;
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  color: #1a54c7;
}
.owl-product .owl-nav button.owl-prev span:before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  color: #1a54c7;
  position: absolute;
  top: 2px;
  right: 7px;
}
.owl-product .owl-nav button:hover {
  background: #1a54c7 !important;
}
.owl-product .owl-nav button:hover span:before {
  color: #fff;
}

.actionproduct {
  text-align: right;
  margin-bottom: 15px;
}
.actionproduct ul {
  justify-content: end;
}
.actionproduct ul li {
  margin-left: 10px;
}

.btn-pos ul li {
  margin-right: 1px;
  margin-bottom: 10px;
}
@media (max-width: 991.98px) {
  .btn-pos ul li {
    width: 48%;
  }
}
@media (max-width: 575px) {
  .btn-pos ul li {
    width: 100%;
    margin-right: 10px;
  }
}
.btn-pos ul li a.btn {
  color: #888b94;
  font-size: 10px;
  width: 100%;
  padding: 5px 12px;
  background: #e9ecef;
  border-radius: 50px;
  border: 1px solid #e9ecef;
  padding: 5px 12px;
}
@media (max-width: 991.98px) {
  .btn-pos ul li a.btn {
    min-width: auto;
    padding: 6px 15px;
  }
}
.btn-pos ul li a.btn:hover {
  border: 1px solid #ea5455;
  color: #ea5455;
  background: rgba(234, 84, 85, 0.06);
}
.btn-pos ul li a.btn-hold {
  background: #ff9900;
}
.btn-pos ul li a.btn-quation {
  background: #6e6b7b;
}
.btn-pos ul li a.btn-void {
  background: #d0512e;
}
.btn-pos ul li a.btn-payment {
  background: #0f9d58;
}
.btn-pos ul li a.btn-recent {
  background: #ff9f43;
}

.calculator-set .calculatortotal {
  margin-bottom: 15px;
}
.calculator-set .calculatortotal h4 {
  width: 100%;
  background: #ff9f43;
  text-align: center;
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  border-radius: 5px;
}
.calculator-set ul li {
  width: 33.3%;
  text-align: center;
}
.calculator-set ul li a {
  width: 100%;
  border: 1px solid #b9b9c3;
  font-size: 24px;
  font-weight: 500;
  display: block;
  color: #000;
  border-radius: 0;
  padding: 10px;
}
.calculator-set ul li a.btn-closes {
  background: #ea5455;
}
.calculator-set ul li a.btn-reverse {
  background: #2e7d32;
}
.calculator-set ul li a:hover {
  background: #f5f5f5;
}

.hold-order {
  text-align: center;
  margin: 15px 0;
}
.hold-order h2 {
  padding: 0 0 30px;
  color: #ff9f43;
  font-size: 36px;
  font-weight: 700;
}

.para-set {
  margin-bottom: 30px;
}
.para-set p {
  color: #5e5873;
  font-size: 13px;
  font-weight: 500;
}

.delete-order {
  text-align: center;
}

.btn-set h4 {
  background: #ff9f43;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
}

.header-menuset {
  padding: 15px;
}
.header-menuset ul li {
  width: 50%;
  margin-bottom: 10px;
}
@media (max-width: 991.98px) {
  .header-menuset ul li {
    width: 100%;
  }
}
.header-menuset ul li .win-maximize {
  display: none;
}
.header-menuset ul li a {
  border: 1px solid #ff9f43;
  width: 95%;
  text-align: center;
  background: #ff9f43;
  color: #fff;
  padding: 10px;
}
.header-menuset ul li a img {
  filter: brightness(0) invert(1);
}

.invoice-load-btn {
  text-align: center;
  margin-top: 20px;
}
.invoice-load-btn .btn {
  font-weight: 700;
  font-size: 16px;
  color: #ff9f43;
  min-width: 150px;
  background: #fff;
  border: 2px solid #ff9f43;
  display: inline-flex;
  border-radius: 6px;
  padding: 12px 15px;
}
.invoice-load-btn .btn span {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 8px;
  vertical-align: 0;
  border: 3px solid rgba(252, 136, 3, 0.4901960784);
  border-right-color: #ff9f43;
  animation: 0.75s linear infinite spinner-border;
  border-radius: 50%;
}
.invoice-load-btn .btn:hover {
  background: #ff9f43;
  color: #fff;
}
.invoice-load-btn .btn:hover span {
  border: 3px solid #fff;
  border-right-color: #fff;
}

.form-new .form-group input {
  height: 50px;
  background: #f8f8f8;
}
.form-new .form-group .scanner-set {
  min-width: 50px;
  position: relative;
  left: -2px;
}

.product-details {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 4px 4px 0px rgba(237, 237, 237, 0.2509803922);
  border: 1px solid #f8f8f8 !important;
  height: 105px;
  flex-direction: column;
}
.product-details:hover {
  background: #7367f0;
}
.product-details:hover h6 {
  color: #fff;
}
.product-details.active {
  background-color: #7367f0 !important;
}
.product-details.active h6 {
  color: #fff;
}
.product-details img {
  width: auto !important;
}
.product-details h6 {
  font-size: 13px;
  color: #000;
  margin-top: 10px;
}

.btn-scanner-set {
  background: #7367f0;
  max-width: 160px;
  margin-left: auto;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}
.btn-scanner-set:hover {
  background: #ff9f43;
  color: #fff;
}

.split-card {
  margin: 0 0 15px;
  border: 1px dashed #fafbfe;
  position: relative;
}
.split-card::before {
  position: absolute;
  content: "";
  background: #fafbfe;
  width: 22px;
  height: 22px;
  border-radius: 50px;
  top: -10px;
  left: -10px;
}
.split-card::after {
  position: absolute;
  content: "";
  background: #fafbfe;
  width: 22px;
  height: 22px;
  border-radius: 50px;
  top: -10px;
  right: -10px;
}

.totalitem {
  margin-bottom: 15px;
}
.totalitem h4 {
  padding: 7px 13px;
  background: #f8f8f8;
  color: #7367f0;
  font-size: 12px;
  font-weight: 700;
  border-radius: 50px;
}
.totalitem a {
  font-size: 14px;
  color: #ea5455;
  font-weight: 700;
}

.card.card-order {
  border: 0;
}

.btn-adds {
  border: 2px solid #28c76f;
  color: #28c76f;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 20px;
}
.btn-adds:hover {
  background: #28c76f;
  color: #fff;
}

.order-list {
  margin-bottom: 25px;
}
.order-list .orderid h4 {
  font-size: 18px;
  font-weight: 700;
  color: #5e5873;
  margin-bottom: 0;
}
.order-list .orderid h5 {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.order-list .actionproducts > ul > li {
  margin-left: 15px;
}
.order-list .actionproducts > ul > li .deletebg {
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(199, 199, 199, 0.2509803922);
  width: 30px;
  height: 30px;
  border-radius: 5px;
}

.tabs_wrapper .owl-product {
  margin-bottom: 25px;
}

.productset {
  border: 1px solid #f8f8f8;
  box-shadow: 0px 4px 4px 0px rgba(237, 237, 237, 0.2509803922);
  background: #fff;
  margin: 0 0 25px;
  border-radius: 5px;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.productset:hover {
  border: 1px solid #7367f0;
}
.productset .check-product {
  display: none;
  width: 34px;
  height: 23px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  background: #7367f0;
  border-radius: 4px 0;
}
.productset.active {
  border: 1px solid #7367f0;
}
.productset:hover .productsetimg img {
  transform: scale(1.2);
}
.productset:hover .productsetimg h6 {
  transform: translatey(0);
}
.productset .productsetimg {
  position: relative;
  overflow: hidden;
}
.productset .productsetimg img {
  transition: all 0.5s;
  border-radius: 5px 5px 0px 0px;
}
@media (max-width: 991.98px) {
  .productset .productsetimg img {
    max-height: 150px;
  }
}
.productset .productsetimg h6 {
  position: absolute;
  background: #ff9f43;
  color: #fff;
  font-size: 10px;
  padding: 5px;
  border-radius: 5px;
  top: 20px;
  right: 20px;
  transform: translatey(-60px);
  transition: all 0.5s;
}
.productset .productsetcontent {
  padding: 13px;
  text-align: center;
}
.productset .productsetcontent h4 {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-bottom: 13px;
}
.productset .productsetcontent h5 {
  color: #6e6b7b;
  font-size: 12px;
}
.productset .productsetcontent h6 {
  color: #ea5455;
  margin: 0 0 0 auto;
  font-weight: 600;
  font-size: 14px;
}

.tabs_wrapper ul.tabs {
  display: inline-block;
  width: 100%;
  padding-left: 0;
}
.tabs_wrapper ul.tabs li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  border-radius: 5px;
  color: white;
  font-family: sans-serif;
  width: 100%;
}
.tabs_wrapper ul.tabs li.active {
  background-color: #7367f0;
}
.tabs_wrapper ul.tabs li.active .product-details {
  background: transparent;
  border: 0 !important;
}
.tabs_wrapper ul.tabs li.active .product-details h6 {
  color: #fff;
}
.tabs_wrapper .tabs_container {
  padding-top: 0px;
  display: inline-block;
  width: 100%;
}
.tabs_wrapper .tabs_container .tab_content {
  display: none;
  width: 100%;
}
.tabs_wrapper .tabs_container .tab_content.active {
  display: inline-block;
}

.profile-set {
  margin-bottom: 30px;
}
.profile-set .profile-head {
  height: 109px;
  background: linear-gradient(90deg, #ea5455 0%, #ff9f43 100%);
  border-radius: 0 10px 0 0;
}
@media (max-width: 991.98px) {
  .profile-set .profile-head {
    border-radius: 10px;
  }
}
@media (max-width: 767px) {
  .profile-set .profile-top {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .profile-set .profile-top .profile-content {
    flex-direction: column;
  }
}
.profile-set .profile-top .profile-contentimg {
  margin-top: -40px;
  width: 130px;
  position: relative;
  border: 10px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
.profile-set .profile-top .profile-contentimg img {
  border-radius: 50px;
}
.profile-set .profile-top .profileupload input[type="file"] {
  opacity: 0;
  width: 34px;
  height: 34px;
  display: block;
  z-index: 9;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  left: 42px;
}
.profile-set .profile-top .profileupload a {
  background: #ff9f43;
  width: 34px;
  height: 34px;
  z-index: 1;
  border-radius: 50px;
  position: absolute;
  bottom: 10px;
  left: 41px;
}
.profile-set .profile-top .profile-contentname {
  padding-left: 20px;
}
@media (max-width: 767px) {
  .profile-set .profile-top .profile-contentname {
    text-align: center;
    padding: 20px 0 20px 0;
  }
}
.profile-set .profile-top .profile-contentname h2 {
  font-size: 18px;
  color: #212b36;
  font-weight: 700;
}
.profile-set .profile-top .profile-contentname h4 {
  color: #777;
  font-size: 14px;
}

.pass-group {
  position: relative;
}
.pass-group .toggle-password,
.pass-group .toggle-passworda {
  transform: translateY(-50%);
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #637381;
  font-size: 14px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 10px;
}

.notAuth {
  color: red;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  width: 100%;
}

.progressContainer {
  display: flex;
  gap: 12px;
  font-weight: bold;
}

.progressBarContainer {
  background: #f1f1f1;
  width: 200px;
  border: 1px solid #ccc;
  height: 22px;
  overflow: hidden;
  border-radius: 3px;
}

.progressBarPercentage {
  background: #444;
  color: #fff;
  border: 1px solid #ccc;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.progressBar {
  background: #18b0e9;
  width: 0%;
  transition: width 0.3s;
  height: 28px;
}

.progressBarContainerSm {
  background: #fff;
  width: 100px;
  border: 1px solid #ccc;
  overflow: hidden;
  text-align: center;
  height: 22px;
}

.progressBarSm {
  background: #18b0e9;
  width: 0%;
  text-align: right;
  font-size: 12px;
  font-weight: bold;
  transition: width 0.3s;
  display: flex;
  height: 23px;
  color: #fff;
  align-items: center;
  justify-content: flex-end;
  line-height: 1px;
}

.btnTask {
  gap: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.userImg {
  width: 120px;
}

.tooltip {
  width: fit-content !important;
}

.dashboardDescription {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px 0;
  text-align: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.dashboardBar {
  width: 100%;
  color: #333;
  text-align: left;
  background: none;
  font-weight: bold;
  padding-bottom: 14px;
  border: 0;
}

.dashboardImg {
  width: 70% !important;
  margin: 0 auto;
  margin-top: 50px;
}

.dashboardLogo {
  width: 55% !important;
  margin: 0 auto;
}
.dashboardLogo2 {
  width: 100% !important;
  max-width: 120px;
  margin: 0 auto;
}
.dashboardText1 {
  font-size: 26px;
}

.dashboardText2 {
  font-size: 17px;
  margin-top: 8px;
}

.dashboardText3 {
  font-size: 22px;
  color: #ff0000;
}

.dashboardText4 {
  font-size: 19px;
  margin-top: 10px;
}

.dashboardCol {
  border-right: 1px solid #ddd;
}

@media (max-width: 767px) {
  .dashboardCol {
    margin-top: 40px;
    width: 100%;
    margin-bottom: 40px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 80px;
    border-right: none;
  }
}

.dashboardCol:last-child {
  border-right: none !important;
}

.githubContainer {
  display: flex;
  width: fit-content;
  justify-content: center;
  width: 60%;
  position: relative;
  margin: 0 auto;
  padding: 0;
  max-width: 140px;
}

.githubTitle {
  font-size: 18px;
  font-weight: bold;
  margin-top: 50px;
}

.githubLink {
  color: #089fba;
  background: #f1f1f1;
  padding: 3px 10px;
}

.dbLogo {
  width: 40%;
  margin: 0 auto;
  max-width: 180px;
}

.logoSidebar {
  width: 100px;
  margin: 0 auto;
}

.logoSidebarClosed {
  width: 34px;
  margin: 0 auto;
}

.sidebarHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #555;
  padding-bottom: 30px;
  padding-top: 10px;
  margin-bottom: 40px;
  margin-left: 10px;
}

.addButton {
  display: flex;
  flex-direction: row;
  background: #24a50b;
  color: #fff;
  gap: 12px;
  align-items: center;
  width: fit-content;
  justify-content: start;
  min-height: 40px;
  max-height: 54px;
  border-radius: 4px;
  padding: 14px;
  margin-bottom: 20px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.addButton:hover {
  background: #3bb722;
}

.addButtonIcon {
  font-size: 25px;
}

.addButtonSm {
  display: flex;
  flex-direction: row;
  background: #24a50b !important;
  border-color: #24a50b !important;
  color: #fff;
  gap: 12px;
  align-items: center;
  width: fit-content;
  justify-content: start;
  min-height: 40px;
  max-height: 54px;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.addButtonSm:hover {
  background: #3bb722;
}

.addButtonSmIcon {
  font-size: 20px;
}

.backButton {
  display: flex;
  flex-direction: row;
  background: #18b0e9 !important;
  border-color: #18b0e9 !important;
  color: #fff;
  gap: 12px;
  align-items: center;
  width: fit-content;
  justify-content: start;
  min-height: 40px;
  max-height: 54px;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  height: 22px !important;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.backButton:hover {
  background: #3bb722;
}

.backButtonIcon {
  font-size: 20px;
}

.projectRow {
  padding: 0 12px;
}

.porjectCard {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid #ccc;
  border-radius: 6px;
  overflow: hidden;
  transform: translateY(0);
  transition: all 0.2s ease;
  display: flex !important;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 30px;
}

.porjectCard:hover {
  transform: translateY(-10px);
}

.projectCardCover {
  width: 100%;
  height: 290px;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: end;
  padding: 10px;
}

.projectCardText {
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.projectCardTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  padding: 18px;
  height: 150px;
  flex-direction: column;
  text-align: center;
}

.projectCardLastUpdate {
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
}

.projectUserImg {
  width: 44px;
  border-radius: 50%;
  border: 3px solid #fff;
  z-index: 5;
  position: relative;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
}

.projectUserImg2 {
  margin-left: -20px;
  z-index: 4;
}

.projectCardButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ddd;
  background: #f1f1f1;
  padding: 14px;
  justify-content: space-between;
  align-items: center;
}

.projectMembersContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ddd;
  padding: 14px;
  justify-content: space-between;
}

.projectCardButton {
  margin-right: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none !important;
  border: none;
  gap: 12px;
}

.projectCardButton:hover {
  background: #d1d1d1 !important;
}

.projectCardButtonTitle {
  font-size: 13px;
  color: #333;
  font-weight: bold;
  margin-bottom: 7px;
}

.disabledColor {
  color: #999 !important;
}

.disabledBg {
  background: #999 !important;
}

.projectCardIcon {
  font-size: 17px;
  color: #333;
}

.projectSection {
  border-bottom: 1px solid #ccc;
  margin-top: 40px;
  padding-bottom: 50px;
}

.statusContainer {
  color: #fff;
  font-size: 12px;
  padding: 4px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  width: 100px;
}

.buttonGroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.projectTab {
  border-radius: 6px 6px 0px 0px;
  border: 1px solid #ddd;
  border-bottom: 0px;
  padding: 12px 20px;
  color: #333;
  font-weight: bold;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 8px;
  font-size: 15px;
}

.projectTab:hover {
  background: #f1f1f1;
  color: #333;
}

@media screen and (max-width: 1200px) {
  .projectTab {
    margin-top: 20px;
    padding: 8px 20px;
    border: 1px solid #ddd;
    gap: 8px;
    border-bottom: 1px solid #ddd;
    font-size: 13px;
  }
  .borderBottom {
    border: 0px;
    width: 100%;
    margin-bottom: 40px;
  }
  .buttonGroupIcon {
    font-size: 16px;
  }
}

.buttonGroupIcon {
  font-size: 18px;
}

.projectTabSelected {
  background: #18b0e9;
  color: #fff;
}

.page {
  width: 100%;
  min-height: calc(100vh - 183px);
}

.pageContainer {
  border-radius: 0px 6px 6px 6px;
  border-color: #ddd;
}

.pageContainerFull {
  border-radius: 6px;
  border-color: #ddd;
}

.form-control {
  box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.15);
  background: #f9f9f9;
}

.formContainer {
  width: 100%;
  max-width: 1000px;
}

label {
  margin-bottom: 4px !important;
}

.spacer {
  clear: both;
}

.divider {
  clear: both;
  height: 1px;
  display: flex;
  width: 100%;
  border-top: 1px solid #ccc;
  margin-top: 40px;
  margin-bottom: 40px;
}

.cell {
  white-space: normal !important;
  text-wrap: wrap !important;
}

.sideText {
  color: #444;
  font-size: 14px;
}

.sideSection {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  min-height: 80px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  border: 1px solid #f1f1f1;
  justify-content: start;
  color: #444;
  padding: 20px;
  overflow: auto;
}

.sideSectionItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  color: #444;
  margin-bottom: 12px;
  justify-content: start;
}

.sideSectionCol {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 1px;
}

.sideSectionIcon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #18b0e9;
  color: #fff;
  border-radius: 4px;
  margin-left: 14px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
}

.sideSectionNumber {
  font-weight: bold;
  color: #18b0e9 !important;
}

.sideSectionTitle {
  color: #18b0e9;
  font-weight: bold;
  font-size: 18px;
  margin-top: 3px;
  text-align: left;
}

.taskIcon {
  font-weight: bold;
  font-size: 16px;
  padding-left: 5px;
  padding-right: 5px;
}

.taskIcon2 {
  font-size: 14px;
}

.taskIcon3 {
  font-size: 17px;
}

.taskButton {
  display: flex;
  align-items: center;
  height: 32px;
}

.taskButtonDisabled {
  display: flex;
  align-items: center;
  height: 32px;
  background: #e1e1e1 !important;
  color: #777 !important;
  border-color: #e1e1e1 !important;
}

.taskButtonDisabled:hover {
  background: #d1d1d1 !important;
  border-color: #d1d1d1 !important;
}

.projectDetailTitle {
  font-size: 22px;
  font-weight: bold;
}

.projectDetailData {
  display: flex;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-top: 50px;
  padding-top: 40px;
  padding-bottom: 30px;
  gap: 70px;
  flex-wrap: wrap;
}

.projectDetailColumn {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.projectDetailMidTitle {
  font-weight: bold;
  padding-bottom: 10px !important;
}

.taskSection {
  margin-top: 50px;
  padding-bottom: 60px;
}

.borderBottom {
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin-bottom: 40px;
}

.cutText {
  text-decoration: line-through;
  color: #777 !important;
  text-decoration-thickness: 0.07em;
  text-decoration-color: #999;
  text-decoration-skip-ink: auto;
}

.activityName {
  color: #333;
  font-size: 15px !important;
}

.activityCheckbox {
  width: 22px;
  height: 22px;
}

.activityButton {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.activityButtonEdit {
  font-size: 19px;
  color: #18b0e9;
}

.activityButtonDelete {
  font-size: 16px;
  color: #ff0000;
}

.screenshotCardContainer {
  display: flex;
  width: 100%;
}

.screenshotCard {
  border: 1px solid #cccccc !important;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.screenshotCardWrapper {
  padding: 12px;
}

.screenshotCardHeader {
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  gap: 6px;
  justify-content: space-between;
  font-size: 14px;
  padding: 6px;
}

.screenshotImg {
  width: 100%;
  height: auto;
  transition: filter 0.3s;
}

.screenshotImg:hover {
  filter: brightness(0.8);
}

.photoModal {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  display: flex;
}

.photoModalHeader {
  width: 100%;
}

.photoModalImg {
  width: 100%;
  max-width: 1000px;
}

.imgThumb {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ddd;
}

.imgThumb img {
  width: 44px;
}

.imgThumbContainer {
  display: flex;
  gap: 8px;
  font-weight: bold;
  font-size: 14px;
  align-items: center;
}

.dataContainer {
  display: flex;
  gap: 8px;
  color: #777;
  font-size: 14px;
  align-items: center;
  font-style: italic;
}

.dataIcon {
  font-size: 25px;
}

.dataLastColumn {
  display: flex;
  gap: 40px;
}

.AllButton {
  display: flex;
  background: #f1f1f1;
  border-radius: 6px;
  width: fit-content;
  align-items: center;
  padding: 8px 20px;
  gap: 14px;
  cursor: pointer;
}

.AllButton:hover {
  background: #e1e1e1;
}

.membersRow {
  gap: 6px;
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.membersButtonDeleteIcon {
  color: #fff;
  font-size: 12px;
}

.membersButtonDelete {
  background: red;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.membersButtonDelete:hover {
  background: #a70c0c;
}

.membersButtonAddIcon {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

.membersButtonAdd {
  background: #1fd51f;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.membersButtonAdd:hover {
  background: #15b715;
}

.memberSide {
  float: left !important;
  width: 220px;
  margin-bottom: 6px;
  overflow: hidden;
}

.saveButton {
  display: flex;
  flex-direction: row;
  background: #25a50b;
  color: #fff;
  gap: 10px;
  align-items: center;
  width: fit-content;
  justify-content: start;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 40px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.saveButton:hover {
  background: #238a0f;
  color: #f1f1f1;
}

.saveButtonIcon {
  font-size: 18px;
}

.commentContainer {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.commentRow {
  white-space: wrap !important;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.modalHeader {
  background: #222;
}

.modalClose {
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}

.modalSend {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.sendButton {
  gap: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  width: fit-content;
  justify-content: center;
}

.modal-dialog {
  max-width: 800px !important;
}

.fileContainer {
  display: flex;
  gap: 6px;
  align-items: center;
  color: #18b0e9 !important;
  font-size: 13px;
  font-weight: bold;
}

.reminderIcon {
  color: #fff;
  font-size: 20px;
}
/*# sourceMappingURL=style.css.map */

/* end  dashboard css */
